// Global
export const ONBOARDING_API = `${process.env.REACT_APP_ONBOARDING_URL}/dynamic/api`;
export const ONBOARDING_PUB = `${process.env.REACT_APP_ONBOARDING_URL}/dynamic/public`;

export const HARDCODED_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIrMjEzNTU3NDI0NTE4IiwiaWF0IjoxNjIzODM2NjcwLCJleHAiOjE2MzE2MTI2NzAsInR5cGUiOiJZQV9HT19DT01QQU5ZIn0.rp_ewFtZX0-KlRnEIaqiNeykBes11E7WjU9kv06HqhE';

export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum FIELD_TYPE {
  SELECT = 'SELECT',
  CUSTOM = 'CUSTOM',
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  TEL = 'TEL',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CHECKBOX = 'CHECKBOX',
  DOCUMENT = 'DOCUMENT',
  CUSTOM_LEVEL_PATH = 'CUSTOM_LEVEL_PATH',
  CUSTOM_VEHICLE_BRAND_MODEL = 'CUSTOM_VEHICLE_BRAND_MODEL',
  CUSTOM_VEHICLE_COLOR = 'CUSTOM_VEHICLE_COLOR',
  YEAR = 'YEAR',
  BOOLEAN = 'BOOLEAN',
  CUSTOM_ENTITY_TYPE = 'CUSTOM_ENTITY_TYPE',
}

export enum STEP_TYPE {
  MULTI_FORM = 'MULTI_FORM',
  FORM = 'FORM',
  CAR = 'CAR',
  DRIVER = 'DRIVER',
  TRAINING = 'TRAINING',
}

export const PATH_LEVEL = {
  firstLevel: 'https://615c102bc298130017735ec5.mockapi.io/api/v1/countries',
  secondLevel: 'https://615c102bc298130017735ec5.mockapi.io/api/v1/regions',
  thirdLevel: 'https://615c102bc298130017735ec5.mockapi.io/api/v1/countries',
};

export const PHONE_MASK = {
  CANADA: [/\d/, /\d/, /\d/, /\s/, /\d/, /\d/, /\d/, /\s/, /\d/, /\d/, /\d/, /\d/,],
  ALGERIA: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
  TUNIS: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
  MAROC: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/],
  SNEGALE: [/\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  BENIN: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  TOGO: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
};

export const countriesMaghreb = ['DZA','TUN','MAR'];
export const countriesAfric = ['SEN','BEN','TGO','GIN'];
export const codes = ['DZA','MAR','TUN','SEN','BEN','TGO']

export const listLang = 
  {
    'fr':'français',
    'en':'english',
    'ar':'العربية'
  }

export const listExtAutorized = ['jpeg','png','jpg','pdf'];

export const appStore = 'App Store';
export const googlePlay = 'Google Play';