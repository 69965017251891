import React, { ReactElement } from 'react';
import AxiosProvider from './axiosContext';
import { IntlContextProvider } from './intlContext';
import { UserProvider } from './userContext';
import { AuthProvider } from './authContext';
import { ConfigProvider } from './configContext';
import { HeaderProvider } from './headerContext';
import { ValudProvider } from './valudContext';
import { ValidatePhoneProvider } from './validatePhoneContext';
import { ScreenProvider } from './screenDataContext';
import { FooterProvider } from './footerContext';
import { CompProvider } from './compComtext';

interface Props {
  components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
  children: React.ReactNode;
}

export const Compose: React.FC<Props> = ({ components, children }): any => (
  <>
    {components.reduceRight(
      (acc, Comp) => (
        <Comp>{acc}</Comp>
      ),
      children,
    )}
  </>
);

const ProviderWrapper = (props: { children: ReactElement }): ReactElement => {
  const { children } = props;
  return (
    <Compose
      components={[ 
        AxiosProvider,
        IntlContextProvider,
        UserProvider,
        AuthProvider,
        ConfigProvider,
        HeaderProvider, 
        ValudProvider,
        ValidatePhoneProvider,
        ScreenProvider,
        FooterProvider,
        CompProvider,
      ]}
    >
      {children}
    </Compose>
  );
};

export { ProviderWrapper };
