import React, { ReactElement } from 'react';
import { useStyles } from './styles';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
// import { useConfigData } from '../../contexts/configContext';
import GroupSteps from '../../components/GroupSteps/GroupSteps';

const Training: React.FC = (): ReactElement => {
  const classes = useStyles();
  // const { data } = useConfigData();

  return (
    <div>
      <div>
        <Header />
        <Breadcrumb
          icon='file'
          title='Dossier en cours de traitement..'
          subtitle={` Nous vous remercions d’avoir choisi de devenir l’un des partenaires de
           YASSIR et vous confirmons la réception de votre candidature. 
           Votre demande est désormais en cours de traitement. 
           Nous vous contacterons par e-mail dès la validation des informations et documents fournis. `}
          isMultiSteps={false}
        />
      </div>
      <div className={classes.content}>
        <GroupSteps
          groupSteps={[
            {
              title: 'Complétez votre profil',
              icon: 'training',
              label: { en: 'Passer la formation', fr: 'Passer la formation' },
              steps: [
                {
                  description: {
                    en: 'Passer la formation pour devenir officiellement partenaire',
                    fr: 'Passer la formation pour devenir officiellement partenaire',
                  },
                  name: '',
                  label: { en: 'Traning', fr: 'Formation' },
                  icon: '',
                  status: 'disabled',
                  groupFields: [],
                },
              ],
              status: '',
              description: '',
              isActive: true,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Training;
