import { StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    // display: 'flex',
  },
  dialogContainer: {
    padding: '42px',
    textAlign: 'center',
  },
  container: {
    padding: '20px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  image: {
    width: '100',
    padding: '25px 0px 10px',
  },
  actions: {
    display: 'felx',
    flexDirection: 'column',

    // width: '60%',

    background: theme.palette.background.paper,
  },
  content: {
    margin: '0 auto',
    fontWeight: 'normal',
    fontSize: '14px',
    width: '432px',
    boxSizing: 'border-box',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    textAlign: 'center',
    '& > *': {
      margin: '12px 0',
      width: '100%',
    },
  },
  link: {
    color: '#545454',
    textDecoration: 'none',
    opacity: '0.64',
    transition: '.2s',
    '&:hover': {
      opacity: '1',
    },
  },
  form: {
    display: 'contents',
  },
  disabledButton: {
    // backgroundColor: '#F2F2F2',
    background: '#f00',
  },
  button: {
    background: '#000',
    padding: '14px',
    '&$disabled': {
      background: '#F2F2F2',
      color: '#B6B6B6',
    },
  },
  disabled: {},
  bgHeader: {
    // background:'#F7F7F7'
  },
  maxWidth: {
    width: '100%',
  },
  innerWrapper: {
    width: `calc(100% - 40px)`,
    background: theme.palette.background.paper,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textSecondary: {
    margin: '16px 0',
    color: theme.palette.text.secondary,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    width: '45%',
    margin: '0 auto',
  },
}));

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      borderColor: '#ff7d00',
    },
  },
  line: {
    borderTopStyle: 'dashed',
    borderTopWidth: '2px',
  },
})(StepConnector);
