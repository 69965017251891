import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { FormControl, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller, useController, useFormContext } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import { TextContent } from './TextContent';
import { PreviewImage } from './PreviewImage';
import PDFPreview from '../PDFPreview';
import DatePickerCustom from '../DateFieldCustom';
import { DocumentStatus } from '../../utils/enum';
import { IntlContext } from '../../contexts/intlContext';
import { listExtAutorized } from '../../utils/constants';
import Translate from '../../utils/Translate';

interface DropzoneProps {
  name: string;
  label: MultiLangType;
  required: boolean;
  startDate: boolean | undefined;
  endDate: boolean | undefined;
}

const DropzoneCustom: React.FC<DropzoneProps> = ({
  name,
  label,
  required,
  startDate,
  endDate,
}): ReactElement => {
  const classes = useStyles();
  const { field, fieldState } = useController({ name });
  // console.log('🚀 ~DropzoneCustom  field', field);
  const { setFocus, control, getValues, setValue } = useFormContext();
  const [loadingState, setLoadingState] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(null);
  const { messageLang } = React.useContext(IntlContext)?.locales;

  useEffect(() => {
    if (getValues(name)) setValue(name, { ...getValues(name), ...(selectedStartDate && { start: selectedStartDate }), ...(selectedEndDate && { end: selectedEndDate }) })
  }, [getValues, name, selectedEndDate, selectedStartDate, setValue])

  const handleChangeStatus = (): any => {
    setLoadingState(true);
    setTimeout(() => {
      setLoadingState(false);
    }, 2000);
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        // eslint-disable-next-line no-unused-expressions
        reader.result
          ? setValue(name, {
            file,
            type: `${name}`.toUpperCase(),
            subType: `${name}`.toLowerCase(),
            ext: file.type.split('/')[1],
            image: `${reader.result}`.split(',')[1],
            ...(selectedStartDate && { start: selectedStartDate }),
            ...(selectedEndDate && { end: selectedEndDate })
          })
          : setValue(name, { file });
      };
      reader.readAsDataURL(file);
    });
  }, [name, selectedEndDate, selectedStartDate, setValue]);

  const test = /chrome/i.test(navigator.userAgent) ? "Downloads/*" : "pdf,jpeg,jpg,png"

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onBlur, value } }) => (
          <FormControl variant='outlined' className={classes.formControl}>
            <Dropzone
              onDrop={(e) => {
                onDrop(e);
                setLoadingState(true);
                // onChange(e);
                handleChangeStatus();
              }}
              maxFiles={1}
              disabled={getValues(name) && getValues(name)?.fileName && ![DocumentStatus.pending, DocumentStatus.rejected].includes(getValues(name)?.status)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className={classes.inputContainer} {...getRootProps()}>
                  <div
                    className={classes.inputFile}
                    style={{
                      borderColor: `${fieldState.invalid ? 'tomato' : 'lightgrey'
                        }`,
                    }}
                  >
                    {!loadingState ? (
                      <div className={classes.inputPreview}>
                        {localStorage.getItem('inApp') ?
                          <input
                            {...getInputProps()}
                            name={name}
                            onBlur={onBlur}
                          /> :
                          <input
                            {...getInputProps()}
                            accept={navigator.userAgent.indexOf("Mi") !== -1 ? "*" : test}
                            name={name}
                            onBlur={onBlur}
                          />}

                        {
                          value?.ext === 'pdf' ?
                            < PDFPreview />
                            :
                            <PreviewImage
                              value={value?.fileName ? value?.fileName : value?.file}
                            />
                        }

                      </div>
                    ) : (
                      <CircularProgress size={35} />
                    )}

                    <TextContent
                      value={value && value.file ? value.file : value}
                      label={label}
                    />
                  </div>
                </div>
              )}
            </Dropzone>
            {fieldState.invalid && (
              <Typography className={classes.error}>
                {fieldState.error?.message}
              </Typography>
            )}
          </FormControl>
        )}
        defaultValue={field.value || ''}
        rules={(field.value && field.value.file) || required ? {
          required: {
            value: true,
            message: `${Translate(label)} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`
          },
          validate: () => {
            if (!listExtAutorized.includes(field.value?.ext.toString().toLowerCase().trim())) {
              return JSON.parse(JSON.stringify(messageLang))['valide.ext']
            }
            return null
          }
        } : {}}
      />
      {startDate ? (
        <DatePickerCustom
          name={`${name}_startDate`}
          label={{ en: 'start date', fr: 'date debut' }}
          required={required}
          setSelectedStartDate={setSelectedStartDate}
          disabled={getValues(name) && getValues(name)?.fileName && ![DocumentStatus.pending, DocumentStatus.rejected].includes(getValues(name)?.status)}
        />
      ) : (
        ''
      )}
      {endDate ? (
        <DatePickerCustom
          name={`${name}_endDate`}
          label={{ en: 'expiration date ', fr: "Date d'expiration" }}
          required={required}
          setSelectedEndDate={setSelectedEndDate}
          disabled={getValues(name) && getValues(name)?.fileName && ![DocumentStatus.pending, DocumentStatus.rejected].includes(getValues(name)?.status)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DropzoneCustom;
