import React, { useRef, ReactElement, useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Button } from '@mui/material';
import { MenuItem, Select } from '@material-ui/core';
import { IntlContext } from '../../contexts/intlContext';
import { useHeaderData } from '../../contexts/headerContext';
import { useAuthData } from "../../contexts/authContext";
import { useLangage } from "../../hooks/useLangage";
import LogoChauffeurYassir from '../../assets/logoBrand/logo_brand_web.svg';
import logoMobile from '../../assets/logoBrand/logo_brand_mobile.svg';
import useStyles from './styles';
import { ONBOARDING_API, countriesMaghreb, listLang } from "../../utils/constants";

interface IFormProps {
  isInViewport: any,
}

const Header: React.FC = (): ReactElement => {
  const classes = useStyles();
  const navigate = useHistory();
  const { selectedCountry } = useAuthData();
  const { locales, swtichLang } = React.useContext(IntlContext);
  const { locale, messageLang } = React.useContext(IntlContext).locales;
  const defaultLang = locales.locale;
  const [txtLang, setTxtLang] = useState('');
  const [selectedLang, setSelectedLang] = useState(defaultLang);

  const {
    isLoading: loadingData,
    isSuccess: successData,
    isError: errorData,
    mutate: mutateData,
    data: listLangages,
    error: errorLanagesData,
  } = useLangage({
    path: `${ONBOARDING_API}/basic-country-infos/${selectedCountry?.code}`,
    method: 'GET'
  });

  React.useEffect(() => {
    if (locale === 'en') {
      setSelectedLang('fr');
      swtichLang('fr');
    }
  }, [locale, navigate])

  useEffect(() => {
    document.title = JSON.parse(JSON.stringify(messageLang))['title.document'];
    if (selectedCountry?.code) mutateData({});
  }, [selectedCountry?.code]);

  const [languages, setLanguages] = useState<any[]>(["fr", "ar"]);

  useEffect(() => {
    if (listLang[locale]) setTxtLang(listLang[locale]); else setTxtLang(locale)
  }, [locale])

  useEffect(() => {
    if (successData) {
      const datas = listLangages?.data?.config?.languages.filter((value: any) => ["fr", "ar"].includes(value));
      setLanguages(datas);
    }
  }, [successData, selectedCountry?.code]);

  const ref = useRef(document.createElement("div"))

  const { setHeader, header } = useHeaderData();

  const handleLangChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    const selectedValue = event.target.value as string;
    setSelectedLang(selectedValue);
    swtichLang(selectedValue);
  };

  useEffect(() => {
    setHeader({
      ...header,
      height: ref?.current?.clientHeight,
    })
  }, [ref?.current?.clientHeight])

  useEffect(() => {
    if (successData)
      setHeader({
        ...header,
        phoneService: listLangages?.data?.config?.yassir_contact?.phones,
      });
  }, [successData, listLangages])

  useEffect(() => {
    if (header.ref)
      setHeader({ ...header, ref: ref?.current })
    else
      // because the usehistory hook makes me lose the ref attribute of the header object when I exit the signup view
      header.ref = ref?.current
  }, [ref])

  useEffect(() => {
    // because the usehistory hook makes me lose the ref attribute of the header object when I exit the signup view     
    if (window.location.pathname !== `/${locale}`) delete (header?.ref)
  }, [ref])

  const navigation = (): any => {
    navigate.push(window.location.pathname === `/${locale}/signin` ? `/${locale}` : `/${locale}/signin`)
  }

  return (
    <Box ref={ref} sx={{ flexGrow: 1 }} className={classes.container}>
      <Grid container columns={{ md: 12 }} direction="row" justifyContent="center" alignItems="center" className={classes.ctnHeader}>
        <Grid item xs={4} sm={6} md={6} className={classes.styleColHeader}>
          {header?.height !== 0 && <><div className={classes.centerLeft}>
            <img style={{ cursor: 'pointer', transform: window.innerWidth < 500 && locale === 'ar' ? 'scaleX(-1)' : 'scaleX(1)', }} role="presentation" onClick={() => navigate.push(`/${locale}`)}
              className={window.innerWidth > 500 ? classes.img : classes.logoMob} src={window.innerWidth > 500 ? LogoChauffeurYassir : logoMobile} alt="Logo chauffeur Yassir" />
          </div>
            <div className={classes.centerLeftBlack}>
              <img style={{ cursor: 'pointer', transform: window.innerWidth < 500 && locale === 'ar' ? 'scaleX(-1)' : 'scaleX(1)', }} role="presentation" onClick={() => navigate.push(`/${locale}`)}
                className={window.innerWidth > 500 ? classes.img : classes.logoMob} src={window.innerWidth > 500 ? LogoChauffeurYassir : logoMobile} alt="Logo chauffeur Yassir" />
            </div></>}
        </Grid>
        <Grid item xs={8} sm={6} md={6} className={classes.styleColHeader}>
          <div className={classes.container2}>
            {header?.height !== 0 && <div className={classes.item}>
              <div className={classes.centerRight}>
                {locale === 'ar' ? <Button variant="contained" className={classes.btn} style={{ fontWeight: 700, backgroundColor: '#6316DB' }}
                  onClick={navigation}>
                  {window.location.pathname === `/${locale}/signin` ?
                    (<><span style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['you.must.register.first2']}</span></>) :
                    (<><span style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['click.here.to.login2']}</span></>)}
                </Button> :
                  <Button variant="contained" className={classes.btn} style={{ fontWeight: 700, backgroundColor: '#6316DB' }}
                    onClick={navigation}>
                    {window.location.pathname === `/${locale}/signin` ?
                      (<><span style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['you.must.register.first2'].substring(0, 1)}</span><span style={{ textTransform: "lowercase" }}>{JSON.parse(JSON.stringify(messageLang))['you.must.register.first2'].substring(1, JSON.parse(JSON.stringify(messageLang))['you.must.register.first2'].length)}</span></>) :
                      (<><span style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['click.here.to.login2'].substring(0, 1)}</span><span style={{ textTransform: "lowercase" }}>{JSON.parse(JSON.stringify(messageLang))['click.here.to.login2'].substring(1, JSON.parse(JSON.stringify(messageLang))['click.here.to.login2'].length)}</span></>)}
                  </Button>}
              </div>
            </div>}
            {countriesMaghreb.includes(selectedCountry?.code) && listLang[locale] && <div className={classes.txtLang}
              style={locale === 'ar' ?
                { margin: window.innerWidth < 500 ? '12px 0px 0px -30px' : '17px 0px 0px -30px' } :
                { margin: window.innerWidth < 500 ? '12px -30px 0px 0px' : '17px -30px 0px 0px', }
              }>{
                window.innerWidth < 620 ? (<div style={{ margin: '6px 6px 6px 6px', textTransform: 'uppercase', }}>{locale}</div>) : txtLang
              }</div>}
            {!countriesMaghreb.includes(selectedCountry?.code) || process.env.TARGET_ENV !== 'production' && header?.height !== 0 && (<div className={classes.item}>
              {window.innerWidth < 500 ?
                (<Button variant="contained" style={{ backgroundColor: 'transparent', borderColor: 'transparent', padding: '2px', boxShadow: 'none' }}>
                  {languages?.length > 1 ? (
                    <Select
                      className={classes.styleSelect}
                      labelId='label'
                      id='select'
                      onChange={handleLangChange}
                      value=''
                      disableUnderline
                    >
                      {' '}
                      {React.Children.toArray(
                        languages.map((item: any) => (
                          <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
                        )),
                      )}
                    </Select>
                  ) : (
                    ''
                  )}
                </Button>) :
                (<div>
                  {languages?.length > 1 ? (
                    <Select
                      className={classes.styleSelect}
                      labelId='label'
                      id='select'
                      onChange={handleLangChange}
                      value=''
                      disableUnderline
                    >
                      {' '}
                      {React.Children.toArray(
                        languages.map((item: any) => (
                          <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
                        )),
                      )}
                    </Select>
                  ) : (
                    ''
                  )}
                </div>)}
            </div>)}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;