import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useStyles } from '../AlreadyRegistredPage/styles';
import Header from '../../components/Header/Header';
import { useFooterData } from '../../contexts/footerContext';
import Loader from '../../components/YassirLoader';
import FooterForScreen from '../../components/FooterForScreen';
import { IntlContext } from '../../contexts/intlContext';

const NotSupportedPage: React.FC = (): ReactElement => {
  const classes = useStyles();
  const { messageLang } = React.useContext(IntlContext).locales;
  const refBody = useRef(document.createElement("div"))
  const refFooter = useRef(document.createElement("div"))
  const [fixe, setFixe] = useState(true)
  const [loading, setLoading] = useState(true)
  const refHeader = useRef(document.createElement("div"))
  const { footer } = useFooterData();
  const [sizeContent, setSizeContent] = useState(0);

  useEffect(() => {
    if (refBody?.current?.clientHeight !== 0 && refFooter?.current?.clientHeight !== 0 &&
      refBody?.current?.clientHeight + refFooter?.current?.clientHeight < window.innerHeight) setFixe(true)
  }, [refBody?.current?.clientHeight, refFooter?.current?.clientHeight, window.innerHeight])

  useEffect(() => {
    if (fixe) setLoading(false)
  }, [fixe])

  useEffect(() => {
    const calcul = window.innerHeight - (footer?.height + refHeader?.current?.clientHeight)
    setSizeContent(calcul)
  }, [refHeader, footer]);

  return (<>
    <div>
      <div style={{ border: '0px solid transparent' }} ref={refBody}>
        <div ref={refHeader} className={classes.bgHeader}>
          <Header />
        </div>
        <div style={{ display: 'flex', minHeight: sizeContent }}>
          <div className={classes.content}>
            {loading ? <Loader /> : <div style={{ width: '100%' }}>
              {window.innerWidth > 500 ? <h1 className={classes.styleScreenTitle}>{JSON.parse(JSON.stringify(messageLang))['not.supported.title']}</h1> :
                <h2 className={classes.styleScreenTitle}>{JSON.parse(JSON.stringify(messageLang))['not.supported.title']}</h2>}
              <h4 className={classes.body}>{JSON.parse(JSON.stringify(messageLang))['not.supported.body']}</h4>
            </div>}
          </div>
        </div>
      </div>
      <div style={{
        bottom: '0px',
        width: '100%',
        position: 'fixed',
        border: '0px solid transparent',
      }}>
        {fixe ? <div style={{
          bottom: 0,
          width: '100%',
          position: 'fixed',
          border: '0px solid transparent'
        }} ref={refFooter}>
          <FooterForScreen />
        </div> :
          <div style={{
            bottom: 0,
            width: '100%',
            position: 'relative',
            border: '0px solid transparent'
          }} ref={refFooter}>
            <FooterForScreen />
          </div>}
      </div>
    </div>
  </>);
};

export default NotSupportedPage;
