import { createTheme } from '@material-ui/core';
import { theme } from '../../theme';

const customDatePickerTheme: any = createTheme({
  ...theme,
  overrides: {
    // MuiSvgIcon: {
    //   root: {
    //     fill: '#FF7D00',
    //   },
    // },
    // MuiOutlinedInput: {
    //   root: {
    //     '&:hover': {
    //       border: '10px solid red !important', // i'm struggling with this :/
    //     },
    //   },
    // },
  },
});
// A custom rtl theme for this app
export const rtlTheme = createTheme({
  ...customDatePickerTheme,
  direction: "rtl",
});

// A custom ltr theme for this app
export const ltrTheme = createTheme({
  ...customDatePickerTheme,
  direction: "ltr",
});
