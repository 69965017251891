import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';

interface IGroupStepLink {
  id?: string;
  groupId?: string;
  disabled: boolean;
  path?: string;
  children: ReactElement;
}

const GroupStepLink: React.FC<IGroupStepLink> = ({
  id,
  groupId,
  disabled,
  path,
  children,
}): ReactElement => {
  const classes = useStyles();

  return !disabled ? (
    <Link to={`/${path}/${groupId}`} className={classes.withoutDeco} key={id}>
      <>{children}</>
    </Link>
  ) : (
    <>{children}</>
  );
};

GroupStepLink.defaultProps = {
  id: undefined,
  groupId: undefined,
  path: undefined,
};

export default GroupStepLink;
