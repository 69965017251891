import React, { ReactElement, useEffect, useRef } from 'react';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { ar, fr, enGB } from 'date-fns/locale'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core';
import { create } from "jss";
import rtl from "jss-rtl";
import { rtlTheme, ltrTheme } from './theme';
import { MultiLangType } from '../../utils/types';
import { getDateRanges } from '../../utils/date.utils';
import Translate from '../../utils/Translate';
import { IntlContext, useIntlData } from '../../contexts/intlContext';

interface OptionalProps {
  maxUnit?: string;
  minUnit?: string;
  max?: string;
  min?: string;
  setSelectedStartDate?: any;
  setSelectedEndDate?: any;
  disabled?: boolean;
}
interface DatePickerProps extends OptionalProps {
  name: string;
  label: MultiLangType;
  required: boolean;
}

// const DatePickerCustom: React.SFC<DatePickerProps> = ({
const DatePickerCustom = ({
  name,
  label,
  required,
  maxUnit,
  max,
  minUnit,
  min,
  setSelectedStartDate,
  setSelectedEndDate,
  disabled,
}: DatePickerProps): ReactElement => {

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const { isRtl } = useIntlData();

  const { field, fieldState } = useController({ name });
  console.log('🚀 DatePickerCustom  ~ field', field);
  const { control, getValues, setValue } = useFormContext();

  const [langage, setLangage] = React.useState<any>()

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const { messageLang, locale } = React.useContext(IntlContext)?.locales;

  useEffect(() => {
    if (setSelectedStartDate) setSelectedStartDate(selectedDate)
    if (setSelectedEndDate) setSelectedEndDate(selectedDate)
  })

  useEffect(() => {
    const regexStart = /_startDate$/;
    const regexEnd = /_endDate$/;
    if (regexStart.exec(name)) {
      if (getValues(`${name}`.replace('_startDate', ''))?.start) setValue(`${name}`, getValues(`${name}`.replace('_startDate', ''))?.start)
    } else if (regexEnd.exec(name)) {
      if (getValues(`${name}`.replace('_endDate', ''))?.end) setValue(`${name}`, getValues(`${name}`.replace('_endDate', ''))?.end)
    }
  })

  const handleDateChange = (date: Date | null): void => {
    setSelectedDate(date);
  };

  const { maxDate, minDate } = getDateRanges({ maxUnit, max, minUnit, min });

  // const someInternalRef = useRef('someValue').current;
  const inputEl = useRef(null);
  console.log('🚀 ~ inputEl', inputEl);

  React.useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  }, [isRtl]);
  // const shrink =
  //   type === 'date'
  //     ? {
  //         shrink: true,
  //       }
  //     : {};

  useEffect(() => {
    switch (locale) {
      case 'en':
        setLangage(enGB)
        break;
      case 'ar':
        setLangage(ar)
        break;
      default:
        setLangage(fr)
        break;
    }
  }, [locale, enGB, fr, ar])
  return (
    <div ref={inputEl}>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={langage}>
            <Controller
              name={name}
              control={control}
              render={({ field: { onChange, onBlur, value, ref: theRef } }) => (
                <div ref={theRef}>
                  <KeyboardDatePicker
                    // disableToolbar
                    name={name}
                    variant='dialog'
                    label={label ? Translate(label, name) : name}
                    format='dd/MM/yyyy' // TODO: date format should be from config file !
                    margin='normal'
                    inputVariant='outlined'
                    minDate={minDate || undefined}
                    maxDate={maxDate || undefined}
                    fullWidth
                    id='date-picker-dialog'
                    disabled={disabled}
                    // label='Date picker outlined'
                    value={value || selectedDate}
                    onChange={(e) => {
                      handleDateChange(e);
                      onChange(e);
                    }}
                    // KeyboardButtonProps={{
                    //   'aria-label': 'change date',
                    // }}
                    onBlur={onBlur}
                    cancelLabel={JSON.parse(JSON.stringify(messageLang))['cancel.text']}
                    okLabel={JSON.parse(JSON.stringify(messageLang))['apply.text']}
                    // onChange={onChange}
                    // ref={theRef}
                    // inputRef={theRef}
                    // defaultValue={value || selectedDate}
                    helperText={fieldState.invalid && `${label ? Translate(label, name) : name} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
                    error={fieldState.invalid}
                  />
                </div>
              )}
              rules={{ required }}
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StylesProvider>
    </div>
  );
};

const defaultProps = {
  maxUnit: undefined,
  minUnit: undefined,
  max: undefined,
  min: undefined,
  setSelectedStartDate: undefined,
  setSelectedEndDate: undefined,
  disabled: undefined,
};

DatePickerCustom.defaultProps = defaultProps;

export default DatePickerCustom;
