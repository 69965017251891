import { STEP_TYPE } from './constants';

export function getPathByType(type: STEP_TYPE): string {
  let path = `${localStorage.getItem('locale')}/form`;
  switch (type) {
    case STEP_TYPE.MULTI_FORM:
      path = `${localStorage.getItem('locale')}/form-multi`;
      break;
    case STEP_TYPE.FORM:
      path = `${localStorage.getItem('locale')}/form`;
      break;
    case STEP_TYPE.DRIVER:
      path = `${localStorage.getItem('locale')}/form-drivers`;
      break;
    case STEP_TYPE.CAR:
      path = `${localStorage.getItem('locale')}/form-cars`;
      break;
    default:
      break;
  }
  return path;
}

export function getPathBySteps(groupSteps: {
  steps: Record<string, any>[];
}): string {
  let path = `${localStorage.getItem('locale')}/form`;
  if (groupSteps.steps.length > 1) {
    path = `${localStorage.getItem('locale')}/form-multi`;
  }
  return path;
}
