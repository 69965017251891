import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BsHouseDoor } from 'react-icons/bs';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from '../Done/styles';
import Header from '../../components/Header/Header';
import { useFooterData } from '../../contexts/footerContext';
import Loader from '../../components/YassirLoader';
import Device from '../../assets/device.png';
import DeviceMob from '../../assets/deviceMob.png';
import { useConfigData } from '../../contexts/configContext';
import FooterForScreen from '../../components/FooterForScreen';
import { IntlContext } from '../../contexts/intlContext';
import check from '../../assets/icons/Check.png';
import AppStoreFr from '../../assets/btnApp/appStoreFr.png';
import AppStoreAr from '../../assets/btnApp/appStoreAr.png';
import PlayStoreFr from '../../assets/btnApp/playStoreFr.png';
import PlayStoreAr from '../../assets/btnApp/playStoreAr.png';


const AlreadyRegistredPage: React.FC = (): ReactElement => {
  const classes = useStyles();
  const { config } = useConfigData();
  const history = useHistory();
  const { locale,messageLang } = React.useContext(IntlContext).locales;
  const refBody = useRef(document.createElement("div"))
  const refHeader = useRef(document.createElement("div"))
  const {footer} = useFooterData();
  const [fixe, setFixe] = useState(false)
  const [sizeContent, setSizeContent] = useState(0);
  const [loading, setLoading] = useState(true);
  const [somme, setSomme] = useState(0);
  const scope = "done";

  useEffect(() => {
    if(!localStorage.getItem('token'))
      history.push(`/${locale}`)
  },[localStorage.getItem('token')])

  useEffect(() => {
    if(config){
      if(config?.groupSteps)
        history.push(`/${locale}/register`);
      else
        switch(config?.successMessageKey){
          case 'DISPLAY_ONBOARDING_SCREEN':
            history.push(`/${locale}/screen`);
          break;
          case 'NEED_TERMINATE_REGISTRATION':
            history.push(`/${locale}/register`);
          break;
          case 'DRIVER_EXISTS':
            setLoading(false);
          break;
          default:
            history.push(`/${locale}`);
          break;
        }
      }
  },[config])

  const changeAppStoreBtnByLang = (lang: string): any => {
    switch(lang){
        case 'ar':
            return AppStoreAr;
        break;
        default:
            return AppStoreFr;
        break;
    }
  }

  const changePlayStoreBtnByLang = (lang: string): any => {
    switch(lang){
        case 'ar':
            return PlayStoreAr;
        break;
        default:
            return PlayStoreFr;
        break;
    }
  }

  const returnStylePhone = (lang: string):any => {
    if(window.innerWidth > 300)
        return {left: 25}
    if(locale !== 'ar' && window.innerWidth < 300)
        return {right: 12}
    return {left: 0}
  }

  useEffect(() => {
    const glob = refBody?.current?.clientHeight + footer?.height
    setSomme(glob)
  },[refBody,footer])

  useEffect(() => {
    if(somme < window.innerHeight) setFixe(true); else setFixe(false)
  },[somme,footer])

  useEffect(() => {       
    let calcul = 0;    
    calcul = window.innerHeight - (footer?.height + refHeader?.current?.clientHeight)   
    setSizeContent(calcul)   
  },[refHeader,footer,refBody])

  return (<>
      <div>
          <div ref={refBody} style={{ border: '0px solid transparent'}}>
            <div className={classes.bgHeader} ref={refHeader}>
                <Header />
            </div>
            <div style={{marginTop: localStorage.getItem('inApp') && window.innerWidth > 500 ? 0 : 20, display: 'flex', minHeight: sizeContent}}>
              <div className={classes.content} style={{display: 'flex'}}>
                <div className={classes.ctnContent} style={{paddingBottom: '0px'}}>
                  <div className={classes.contentBody}>
                    <div>
                        <img src={check} alt=''/>
                    </div>
                   
                      {loading ? <Loader/> : 
                        <div style={{ padding: '0px 20px 10px 20px' }}>
                          <>{window.innerWidth > 500 ? 
                            <h1 className={classes.styleScreenTitle}>{JSON.parse(JSON.stringify(messageLang))['are.you.already.registered']}</h1> : 
                            <h2 className={classes.styleScreenTitle}>{JSON.parse(JSON.stringify(messageLang))['are.you.already.registered']}</h2>}
                            <h4 className={classes.styleBody} style={{padding: '10px 0px 0px 0px',textAlign:'justify' }}>
                              <p style={{fontWeight: 'normal'}}>{JSON.parse(JSON.stringify(messageLang))['text.congrat.one']}</p>
                              <p style={{fontWeight: 'normal'}}>{JSON.parse(JSON.stringify(messageLang))['text.congrat.two']}</p>
                              {!localStorage.getItem('inApp') &&  <p style={{fontWeight: 'normal'}}>{JSON.parse(JSON.stringify(messageLang))['text.congrat.three']}</p>}
                              <p style={{fontWeight: 'normal'}}>{JSON.parse(JSON.stringify(messageLang))['text.congrat.four']}</p>
                            </h4>
                          </>
                        </div>}               
                        </div>
                        {!localStorage.getItem('inApp') && <div className={classes.ctnBody} style={{position: 'relative',direction: 'ltr',}}>
                            <div className={classes.body}>
                                <Typography className={classes.text} style={{textAlign: 'left', marginLeft: '5px'}}>
                                    <FormattedMessage id={`${scope}.download-yassir-driver-app`} />
                                </Typography>

                                <div className={classes.bodyImg}>
                                    <a href="https://apps.apple.com/dz/app/yassir-chauffeur/id1240841967">
                                        <img src={changeAppStoreBtnByLang(locale)} alt="app store" className={classes.styleImg} />
                                    </a>
                                    <a href="https://play.google.com/store/apps/details?id=com.yatechnologies.yassir_driver" className={classes.ctnStyleImg}
                                        style={{marginLeft:0}}>
                                        <img src={changePlayStoreBtnByLang(locale)} alt="google play" className={classes.styleImg} />
                                    </a>
                                </div>
                            </div>
                            <div className={classes.bgImg}>
                                <img style={returnStylePhone(locale)} src={window.innerWidth < 500 ? DeviceMob : Device} alt="" 
                                    className={classes.ctnBgImg} />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
          </div>
          {fixe ? <div style={{
              bottom: 0, 
              width: '100%',
              position: 'fixed',
              border: '0px solid transparent'}}>
              <FooterForScreen/>
          </div> :
          <div style={{
            bottom: 0, 
            width: '100%',
            position: 'relative',
            border: '0px solid transparent'}}>
            <FooterForScreen/>
          </div>}
      </div>
  </>);
};

export default AlreadyRegistredPage;