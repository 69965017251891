import React, { ReactElement } from 'react';
import Upload from '../../assets/upload.svg';

// TODO: add value type
export const PreviewImage: React.FC<{ value: any }> = ({
  value,
}): ReactElement => {
  if (value) {
    return (
      <img
        src={
          typeof value === 'object' && value !== undefined
            ? URL.createObjectURL(value)
            : value
        }
        alt={value.name}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
  if (value && value.fileName) {
    // TODO: get image from backend to display it
    return <></>;
  }
  return (
    <img
      src={Upload}
      style={{
        fontSize: 22,
        color: 'white',
        marginTop: 10,
      }}
      alt='upload'
    />
  );
};
