import React, { ReactElement } from 'react';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import carsImage from '../../assets/cars_plus5.svg';
import CustomDialog from '../Dialog/CustomDialog';
import Translate from '../../utils/Translate';

interface DialogProps {
  openDialog: boolean;
  closeDialog: () => void;
}

const CheckBoxOption = {
  value: 'plus_five_cars',
  viewValue: {
    en: 'Je possède une flotte de plus de 5 véhicules.',
    fr: 'Je possède une flotte de plus de 5 véhicules.',
  },
};

const CarDialog: React.FC<DialogProps> = ({
  openDialog,
  closeDialog,
}): ReactElement => (
  <>
    <CustomDialog
      openDialog={openDialog}
      closeDialog={closeDialog}
      image={carsImage}
      title={<FormattedMessage id='registration.more-than-5' />}
      content={<FormattedMessage id='registration.more-than-5-text' />}
      actions={
        <>
          <FormControlLabel
            value='plus_five_cars'
            label={Translate(CheckBoxOption.viewValue)}
            // className={classes.field}
            control={<Checkbox color='primary' name='plus_five_cars' />}
          />

          <Button onClick={closeDialog} variant='contained' color='secondary'>
            <b> Ajouter mes véhicules par moi même </b>
          </Button>
        </>
      }
    />
  </>
);

export default CarDialog;
