import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import Header from '../../components/Header/Header';
import Loader from '../../components/YassirLoader';
import { IntlContext } from '../../contexts/intlContext';

const Logout: React.FC = (): ReactElement => {
  const classes = useStyles();

  const refBody = useRef(document.createElement("div"));

  // const navigate = useHistory();

  // useEffect(() => {
  //   localStorage.removeItem('token')
  //   const langNav = ['en', 'ar', 'fr'].includes(String(navigator.language.split('-')[0].toLowerCase())) ? String(navigator.language.split('-')[0].toLowerCase()) : 'fr';
  //   if(localStorage.getItem('inApp')){
  //     // navigate.push(`/${langNav}`);
  //     localStorage.removeItem('inApp');
  //   }
  // }, []);

  return (<>
    <div>
      <div style={{ border: '0px solid transparent' }} ref={refBody}>
        <div className={classes.bgHeader}>
          <Header />
        </div>
        <div className={classes.content}>
          <Loader />
        </div>
      </div>
    </div>
  </>);
};

export default Logout;
