import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import messages from '../translations';
import { ChildrenType } from '../utils/types';

interface SomeProps {
  locales: { locale: string; messageLang: unknown };
  swtichLang: (lang: string) => void;
  isRtl: boolean,
  setIsRtl
}

const Context = React.createContext<SomeProps>(undefined!);

export const useIntlData = (): any => React.useContext(Context);

const IntlContextProvider: React.FC<ChildrenType> = ({
  children,
}): ReactElement => {
  const [locales, setLocales] = useState({
    locale: 'fr',
    messageLang: messages.fr,
  });

  const navigate = useHistory();

  // navigator.language.split('-')[0]
  const [isRtl, setIsRtl] = useState(`${localStorage.getItem('locale')}`.toLowerCase() === 'ar');

  const currentLocale = localStorage.getItem('locale');

  useEffect(() => {

    const langNav = ['en', 'ar', 'fr'].includes(String(navigator.language.split('-')[0].toLowerCase())) ? String(navigator.language.split('-')[0].toLowerCase()) : 'fr';

    const result = navigator.userAgent.match(
      /(iphone)|(ipod)|(ipad)|(android)|(blackberry)|(windows phone)|(symbian)/i
    );

    if (window.location.pathname.includes('/logout')) {
      localStorage.removeItem('token');
      if (!localStorage.getItem('inApp') || result === null) {
        navigate.push(`/${localStorage.getItem('locale')}`);
      }
    } else if (window.location.href.includes('login') || window.location.href.includes('token')) {
      setLocales({ locale: navigator.language.split('-')[0].toLowerCase(), messageLang: messages[String(navigator.language.split('-')[0].toLowerCase())] });
      localStorage.setItem('locale', navigator.language.split('-')[0].toLowerCase());
      const str = window.location.href.split('login')[1];
      navigate.push(`/${String(navigator.language.split('-')[0].toLowerCase())}/login${str}`);
    } else if (!localStorage.getItem('token')) {
      if (!['ar', 'fr'].includes(window.location.pathname.substring(1, 3))) {
        if (window.location.pathname.substring(3, 4).trim() === '') {
          navigate.push(`/${langNav}`);
        } else
          navigate.push(`/${langNav}/signin`);
        setLocales({ locale: langNav, messageLang: messages[langNav] });
        localStorage.setItem('locale', langNav);
      } else if (['en', 'ar', 'fr'].includes(window.location.pathname.substring(1, 3)) && window.location.pathname.substring(3, 4).trim() !== '/') {
        setLocales({ locale: window.location.pathname.substring(1, 3), messageLang: messages[window.location.pathname.substring(1, 3)] });
        localStorage.setItem('locale', window.location.pathname.substring(1, 3));
        navigate.push(`/${window.location.pathname.substring(1, 3)}`);
      } else {
        setLocales({ locale: window.location.pathname.substring(1, 3), messageLang: messages[window.location.pathname.substring(1, 3)] });
        localStorage.setItem('locale', window.location.pathname.substring(1, 3));
        navigate.push(`/${window.location.pathname.substring(1, 3)}${window.location.pathname.substring(3, window.location.pathname.length)}`);
      }
    } else if (localStorage.getItem('token')) {
      if (!['en', 'ar', 'fr'].includes(window.location.pathname.substring(1, 3)) || window.location.pathname.substring(3, 4).trim() !== '/') {
        setLocales({ locale: langNav, messageLang: messages[langNav] });
        localStorage.setItem('locale', langNav);
        navigate.push(`/${langNav}`);
      } else {
        setLocales({ locale: window.location.pathname.substring(1, 3), messageLang: messages[window.location.pathname.substring(1, 3)] });
        localStorage.setItem('locale', window.location.pathname.substring(1, 3));
        navigate.push(`/${window.location.pathname.substring(1, 3)}${window.location.pathname.substring(3, window.location.pathname.length)}`);
      }
    }
  }, [navigator]);

  useEffect(() => {
    setIsRtl(locales?.locale === 'ar');
  }, [locales])

  useEffect(() => {
    if (currentLocale) {
      setLocales({ messageLang: messages[currentLocale], locale: currentLocale });
    }
  }, [currentLocale])

  const swtichLang = (lang: string): void => {
    setLocales({ locale: lang, messageLang: messages[lang] });
    localStorage.setItem('locale', lang);
    if (window.location.pathname.substring(4, window.location.pathname.trim().length).trim())
      navigate.push(`/${lang}/${window.location.pathname.substring(4, window.location.pathname.trim().length).trim()}`);
    else navigate.push(`/${lang}`);

    setIsRtl(lang === 'ar');
  };

  const { locale, messageLang } = locales;

  return (
    <Context.Provider value={{ isRtl, setIsRtl, locales, swtichLang }}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messageLang}
        defaultLocale='fr'
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlContextProvider, Context as IntlContext };
