import React, { ReactElement } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography, CircularProgress } from '@material-ui/core';
import { Controller, useFormContext, useController } from 'react-hook-form';
import Translate from '../../utils/Translate';
import { useStyles } from './styles';
import { IntlContext } from '../../contexts/intlContext';

interface FirstLevel {
  name: string;
  label?: string | undefined;
  isSuccess: boolean;
  selectedLevel: string;
  data: Record<string, any>[];
  handleLevel: (event: any) => void;
  required: boolean | undefined;
  disabled: boolean | undefined;
}

const SelectLevel: React.FC<FirstLevel> = ({
  name,
  label,
  isSuccess,
  selectedLevel,
  data,
  handleLevel,
  required,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fieldState } = useController({ name });
  const { messageLang } = React.useContext(IntlContext)?.locales;
  return (
    <>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='custom-select-outlined-label'>{label || name}</InputLabel>
        <Controller
          name={name}
          control={control}
          render={() => (
            <Select
              labelId='custom-select-outlined-label'
              id='custom-select-outlined'
              onChange={(e) => {
                handleLevel(e);
              }}
              label={label || name}
              value={selectedLevel || ''}
              name={name}
              // disabled={disabled || !isSuccess}
            >
              {/* <MenuItem value=''>
                <em>None</em>
              </MenuItem> */}
              {isSuccess && data.length
                ? React.Children.toArray(
                  data.map((item: any) => (
                    <MenuItem value={item.path}>
                      {item.label ? Translate(item.label, item.name) : item.name}
                    </MenuItem>
                  )),
                )
                : <div style={{textAlign: 'center'}}>
                    <CircularProgress size={35} />
                  </div>}
            </Select>
          )}
          rules={{ required }}
        />
        {fieldState?.invalid && (
          <Typography className={classes.error}>
            {fieldState?.invalid && !selectedLevel && `${label} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

SelectLevel.defaultProps = { label: undefined };

export default SelectLevel;
