import React, { ReactElement, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { useConfigData } from '../../contexts/configContext';
import { IntlContext } from '../../contexts/intlContext';

interface SelectProps {
  name: string;
  label: MultiLangType;
  options: {
    id?: string;
    value: string;
    viewValue: MultiLangType;
  }[];
  disabled: boolean | undefined;
}

const SelectVehicleTypeCustom: React.FC<SelectProps> = ({
  name,
  label,
  options,
  disabled,
}): ReactElement => {
  const classes = useStyles();

  const { setSelectedVehicleType } = useConfigData();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event: any): void => {
    setSelectedValue((event.target as HTMLInputElement).value);
    setSelectedVehicleType((event.target as HTMLInputElement).value);
  };

  const { fieldState } = useController({ name });
  const { control, getValues } = useFormContext();

  useEffect(() => {
    setSelectedVehicleType(getValues(name) || options[0]?.value)
  })

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='custom-select-outlined-label'>
              {Translate(label)}
            </InputLabel>
            <Select
              labelId='custom-select-outlined-label'
              id='custom-select-outlined'
              value={value || selectedValue}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              onBlur={onBlur}
              label={Translate(label)}
              name={name}
              ref={ref}
              disabled={disabled}
            >
              {React.Children.toArray(
                options.map((item) => (
                  <MenuItem value={item.value}>
                    {item.viewValue ? Translate(item.viewValue) : item.value}
                  </MenuItem>
                )),
              )}
            </Select>
            {fieldState.invalid && (
              <Typography className={classes.error}>
                {fieldState.invalid && `${name} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
              </Typography>
            )}
          </FormControl>
        )}
        rules={{ required: true }}
      />
    </>
  );
};

export default SelectVehicleTypeCustom;
