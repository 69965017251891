import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import CustomMenu from '../CustomMenu/CustomMenu';
import CustomIcon from '../CustomIcon';
import { ICard } from '../../utils/types';

interface CarProps extends ICard {
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: null | HTMLElement;
  selectedItem: string | undefined;
  handleDelete: (id: any) => void;
}

const CardItem: React.FC<CarProps> = ({
  id,
  title,
  subtitle,
  image,
  handleClose,
  handleClick,
  anchorEl,
  handleDelete,
  selectedItem,
}): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.flexRow}>
        <div className={classes.infoIcon}>
          <CustomIcon
            iconName={image}
            className={classes.image}
            width={undefined}
          />
        </div>
        <div className={classes.content}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.subtitle} color='secondary'>
            {subtitle}
          </Typography>
        </div>
      </div>
      <div className={classes.rightIcon}>
        <CustomMenu
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          itemId={id}
          handleDelete={() => handleDelete(selectedItem)}
        />
      </div>
    </div>
  );
};

export default CardItem;
