export const toCamel = (s: string): any =>
  s.replace(/([-_][a-z])/gi, ($1: string) =>
    $1.toUpperCase().replace('-', '').replace('_', ''),
  );

const isArray = (a: any): boolean => Array.isArray(a);

const isObject = (o: any): boolean =>
  o === Object(o) && !isArray(o) && typeof o !== 'function';

export const keysToCamel = (o: any[]): Record<any, any> => {
  if (isObject(o)) {
    const n: any = {};

    Object.keys(o).forEach((k: any) => {
      if (k === '_id') {
        n.id = keysToCamel(o[k]);
      } else {
        n[toCamel(k)] = keysToCamel(o[k]);
      }
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i));
  }

  return o;
};
