/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import Switch from "react-switch";
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { useStyles } from './styles';

interface SwitchCustomProps {
  name: string;
  type: string;
  label: MultiLangType;
  required: boolean | undefined;
  disabled: boolean | undefined;
}

const SwitchCustom: React.FC<SwitchCustomProps> = ({
  name,
  type,
  label,
  required,
  disabled,
  
}): ReactElement => {
  const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <div className={classes.row}>
          <Typography style={{ 'color': disabled ? '#989898' : '' }}>{Translate(label)}</Typography>
          <div className={classes.row}>
            <Typography style={{ 'color': disabled ? '#989898' : '', margin: '10px' }}><FormattedMessage id="general-info.no" /></Typography>
            <Switch
              onChange={onChange}
              checked={value || false}
              onColor="#fe9104"
              offColor="#efeff5"
              width={33}
              height={20}
              disabled={disabled}
            />
            <Typography style={{ 'color': disabled ? '#989898' : '', margin: '10px' }}><FormattedMessage id="general-info.yes" /></Typography>
          </div>
        </div>
      )}
    />
  );
};

export default SwitchCustom;
