import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: '24px 0 72px 0',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'stretch',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: 400,
    margin: '5px 0 0 0',
    maxWidth: '600px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  icon: {
    margin: '4px 0 27px',
  },
  previousBtn: {
    position: 'absolute',
    left: '5%',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#000',
    '&:focus': {
      color: '#333',
      boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.1)',
    },
  },
}));
