import React, { ReactElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MdMoreVert } from 'react-icons/md';
import { IconButton, ListItemIcon, Typography } from '@material-ui/core';
import { FaPen, FaTimes } from 'react-icons/fa';
import useStyles from './styles';

interface MenuProps {
  itemId: string;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: any;
  handleDelete: (id: any) => void;
}

const CustomMenu: React.FC<MenuProps> = ({
  itemId,
  handleClose,
  handleClick,
  anchorEl,
  handleDelete,
}): ReactElement => {
  const classes = useStyles();

  return (
    <div>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        data-id={itemId}
      >
        <MdMoreVert />
      </IconButton>
      <Menu
        id='item-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon className={classes.listItemIcon}>
            <FaPen fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>Modifier</Typography>
        </MenuItem>
        {/* <MenuItem onClick={handleDelete}> */}
        <MenuItem onClick={handleDelete}>
          <ListItemIcon className={classes.listItemIcon}>
            <FaTimes fontSize='small' />
          </ListItemIcon>
          <Typography variant='inherit'>Supprimer</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CustomMenu;
