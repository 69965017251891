import fr from './fr';
import en from './en';
import ar from './ar';

const messages: Record<string, any> = {
  fr,
  en,
  ar,
};

export default messages;
