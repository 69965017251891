import React, { ReactElement } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { IntlContext } from '../../contexts/intlContext';

interface SelectProps {
  name: string;
  label: MultiLangType;
  options: {
    id?: string;
    value: string;
    viewValue: MultiLangType;
  }[];
  disabled: boolean | undefined;
}

const SelectCustom: React.FC<SelectProps> = ({
  name,
  label,
  options,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const [selectedValue, setSelectedValue] = React.useState('');

  const { fieldState } = useController({ name });
  const { control, setValue, getValues } = useFormContext();

  const [opcions, setOpcions] = React.useState<any>();

  React.useEffect(() => {
    const obj = {
      "value": "",
      "viewValue": {
        "en": "None",
        "fr": "None",
        "ar": "None"
      }
    }
    setOpcions([obj, ...options])
  }, [options])

  const handleChange = (event: any): void => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    if (String(getValues(name)).toLowerCase() === 'undefined' && !selectedValue && getValues('gender') === '/') {
      setValue(name, '')
    }

  }, [selectedValue, getValues, name, fieldState])

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='custom-select-outlined-label'>
              {Translate(label)}
            </InputLabel>
            <Select
              labelId='custom-select-outlined-label'
              id='custom-select-outlined'
              value={value || selectedValue}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              onBlur={onBlur}
              label={Translate(label)}
              name={name}
              ref={ref}
              disabled={disabled}
            >
              {/* <MenuItem value=''>
                <em>None</em>
              </MenuItem> */}
              {opcions && opcions.length > 0 && React.Children.toArray(
                opcions.map((item) => (
                  <MenuItem value={item.value}>
                    {item.viewValue ? Translate(item.viewValue) : item.value}
                  </MenuItem>
                )),
              )}
            </Select>

            {fieldState.invalid && (
              <Typography className={classes.error}>
                {fieldState.invalid && `${Translate(label)} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`} {value}
              </Typography>
            )}
          </FormControl>
        )}
        rules={{ required: true }}
      />
    </>
  );
};

export default SelectCustom;
