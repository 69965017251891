import React, { ReactElement, useRef, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type Header = {
  height: number;
  phone: string;
  phoneService: string;
  ref: any;
};
type AppDataType = {
  header: Header | undefined;
  setHeader: (header: Header) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useHeaderData = (): any => React.useContext(Context);

const HeaderProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [header, setHeader] = useState({  
    height: 0,
    phone: "",
    phoneService: "",
    ref: useRef(null)
  });

  const value = React.useMemo(
    () => ({
      setHeader,
      header
    }),
    [
      setHeader,
      header
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { HeaderProvider, Context as HeaderContext };
