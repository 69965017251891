import React, { ReactElement, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type ValidPhone = {
  good: boolean;
  phone: string;
};
type AppDataType = {
  validPhone: ValidPhone | undefined;
  setValidPhone: (validPhone: ValidPhone) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useValidatePhoneData = (): any => React.useContext(Context);

const ValidatePhoneProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [validPhone, setValidPhone] = useState({
    good: false,
    phone: ''
  });

  const value = React.useMemo(
    () => ({
      setValidPhone,
      validPhone
    }),
    [
      setValidPhone,
      validPhone
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { ValidatePhoneProvider, Context as ValidatePhoneContext };
