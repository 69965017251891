import React, { ReactElement } from "react";
import useStyles from './styles';
import yassirLogo from '../../assets/logoBrand/logo_brand_web.svg';
import loading from '../../assets/images/loading.gif';

const YassirLoader: React.FC = (): ReactElement => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.loadingDiv}>
                <img className={classes.yassirLogo} src={yassirLogo} alt=""/>
                {/* <img className={classes.loadingGif} src={loading} alt=""/> */}
            </div>
        </>
    )
}

export default YassirLoader;