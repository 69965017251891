import axios from 'axios';
import { ONBOARDING_API } from "../utils/constants";

export const axiosInstance = (): any => {
  // const hardCodedToken =
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIrMjEzNzc1OTg2MTAzIiwiaWF0IjoxNjM4NDU0OTUxLCJleHAiOjE2NDYyMzA5NTEsInR5cGUiOiJZQV9HT19DT01QQU5ZIn0.8tSon9W3sqHkawP1-9Xh7ZsX6hOFnxQSZBuEEvX_ivk';

  const clientAxios: any = axios.create({
    baseURL: ONBOARDING_API,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  clientAxios.interceptors.request.use((config: { headers: any }) => {
    // const token = hardCodedToken;
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  });

  return clientAxios;
};
