import React, { ReactElement, useEffect, useState } from 'react';

interface IconProps {
  iconName: string;
  width?: number;
  height?: number;
  [x: string]: any;
}

const CustomIcon: React.FC<IconProps> = ({
  iconName,
  ...rest
}): ReactElement => {
  const [icon, setIcon] = useState<null | string>(iconName);
  const { className, width } = rest;
  useEffect(() => {
    if (!iconName.includes('http')) {
      const callPromise = async (): Promise<void> => {
        const TheIcon: { default: string } = await import(
          `../assets/icons/${iconName}.svg`
        );
        setIcon(TheIcon.default);
      };
      callPromise();
    }
  }, [iconName]);

  return (
    <>
      {icon ? (
        <img src={icon} alt={icon} width={width} className={className} />
      ) : (
        ''
      )}
    </>
  );
};

CustomIcon.defaultProps = { width: undefined, height: undefined };

export default CustomIcon;
