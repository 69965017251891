import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: '12px 16px',
    margin: '12px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    alignItems: 'flex-start',
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    width: '100%',
  },
  listItemIcon: {
    margin: '0',
    minWidth: '25px',
  },
  defaultContent: {
    boxShadow: '0px 4px 16px 2px rgba(27, 27, 27, 0.16)',
    cursor: 'pointer',
  },
  successContent: {
    boxShadow: '0px 1px 4px rgba(27, 27, 27, 0.16)',
    cursor: 'pointer',
  },
  disabledContent: {
    boxShadow: 'none',
    background: '#F9F9F9',
    cursor: 'default',
    '& p': {
      color: '#6D6D6D !important',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoIcon: {
    display: 'flex',
    justifyItems: 'center',
    alignSelf: 'center',
    fontSize: '28px',
    margin: '0 23px 0 7px',
  },
  arrowIcon: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '28px',
    margin: '0px',
  },
  checkIcon: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '24px',
    margin: '0px',
    color: theme.palette.success.main,
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '14px',
    margin: '5px 0 0 0',
    color: theme.palette.text.secondary,
  },
  withoutDeco: {
    textDecoration: 'none',
    width: '100%',
  },
}));
