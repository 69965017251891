import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    alignItems: 'flex-start',
    margin: '0 auto',
    width: window.innerWidth * 0.77,
    '@media (max-width:500px)': {
      height: 'auto',
      width: '90%',
    }
  },
  txtLang:{
    textTransform: 'capitalize',
    textDecoration: 'underline',
    fontFamily: 'Gellix',
    fontSize: 17,
    fontWeight: 500,
    color: '#1A0244',
  },
  item: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: '0 20px',
    },
    [theme.breakpoints.down("xs")]: {
      '& > *': {
        margin: '6px',
      },
    },
  },
  logo: {
    display: 'block',
    [theme.breakpoints.down("xs")]: {
        width: '150px',
        height: 'auto',
    },
    '@media (max-width:600px)': {
      display: 'none'
    }
  },
  logoMob: {
    display: 'none',
    marginLeft: '0px',
    '@media (max-width:600px)': {
      width: 'auto',
      display: 'block',
      marginLeft: '-5px',
    }
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  ctnLink:{
    cursor: 'pointer',
    marginLeft: '0px',
    border: '1px solid',
    background: 'black',
    color: 'white',
    padding: '7px 6px 4px 15px',
    borderRadius: '25px',
    '@media (max-width:500px)': {
      padding: '4px 6px 2px 15px',
      background: 'black',
      border: '1px solid transparent',
    },
    '@media (max-width:350px)': {
      background: 'transparent',
      border: '1px solid transparent',
    }
  },
  txtlink: {
    fontFamily: 'Gellix',
    fontSize: 17,
    display: 'block',
    position: 'relative',
    left: '-5px',
    top: '-2px',
    color: 'white',
    fontWeight: 700,
    '@media (max-width:500px)': {
      fontWeight: 600,
      fontSize: 14,
      display: 'block',
    },
    '@media (max-width:350px)': {
      display: 'none',
    }
  },
  ctnLogout:{
    display: 'block',
    '@media (max-width:500px)': {
      display: 'block',
    },
    '@media (max-width:350px)': {
      display: 'none',
    }
  },
  iconlink: {
    display: 'none',
    color: 'black',
    '@media (max-width:350px)': {
      fontSize: 18,
      float: 'left',
      marginTop: '4px',
      display: 'block',
    }
  },
}));
