import React, { ReactElement, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type Valud = {
  good: boolean;
};
type AppDataType = {
  valud: Valud | undefined;
  setValud: (valud: Valud) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useValudData = (): any => React.useContext(Context);

const ValudProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [valud, setValud] = useState({
    good: false
  });

  const value = React.useMemo(
    () => ({
      setValud,
      valud
    }),
    [
      setValud,
      valud
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { ValudProvider, Context as ValudContext };
