import React, { ReactElement, useEffect, useRef } from "react";
import { BsTelephone, BsEnvelope } from "react-icons/bs";
import { useSubmit } from "../../hooks/useSubmit";
import { useFooterData } from '../../contexts/footerContext';
import { ONBOARDING_API } from "../../utils/constants";
import { IntlContext } from '../../contexts/intlContext';
import { useAuthData } from '../../contexts/authContext';
import useStyles from './styles';

const Footer: React.FC = (): ReactElement => {
    const classes = useStyles();
    const d = new Date();
    const { locale } = React.useContext(IntlContext).locales;
    const { isTokenValid } = useAuthData();
    const { setFooter, footer } = useFooterData();
    const ref = useRef(document.createElement("div"))

    const {
        isLoading: loadingRequestInfoCountry,
        isSuccess: successRequestInfoCountry,
        isError: errorRequestInfoCountry,
        data: codeInfoCountryData,
        error: codeInfoCountryError,
        mutate: mutateRequestInfoCountry,
    } = useSubmit({
        path: `${ONBOARDING_API}/country-infos`,
        method: 'get'
    });

    useEffect(() => {
        if (successRequestInfoCountry) {
            if (window.innerWidth < 500)
                setFooter({ ...footer, height: ref?.current?.innerText.trim().length + 40 })
            else setFooter({ ...footer, height: ref?.current?.innerText.trim().length - 20 })
        }
    }, [successRequestInfoCountry, ref?.current?.innerText])

    useEffect(() => {
        setFooter({ ...footer, isFetching: loadingRequestInfoCountry })
    }, [loadingRequestInfoCountry])

    useEffect(() => {
        if (isTokenValid) mutateRequestInfoCountry({});
    }, [isTokenValid]);

    return (
        <>
            {successRequestInfoCountry && <div ref={ref} className={classes.content} style={{ direction: 'ltr' }}>
                <div className={classes.ctnWeb}>
                    <div className={classes.leftPart}>
                        <p className={classes.law}>
                            <table>
                                <tr>
                                    {/* eslint-disable-next-line */}
                                    <td>
                                        <BsEnvelope style={{ marginTop: '-8px' }} className={classes.styleIcon} />
                                    </td>
                                    <td>
                                        <span className={classes.styleEmail} style={{ marginLeft: '25px' }}>
                                            {codeInfoCountryData?.data?.config?.yassir_contact?.email}
                                        </span>
                                    </td>
                                    <td>
                                        <span style={{ fontSize: '17px', fontFamily: 'Gellix', fontWeight: 600, margin: '0px 10px 0px 10px', }}>/</span>
                                    </td>
                                    {/* eslint-disable-next-line */}
                                    <td>
                                        <BsTelephone style={{ marginTop: '-8px' }} className={classes.styleIcon} />
                                    </td>
                                    <td>
                                        <span className={classes.styleNumPhone} style={{ marginLeft: '20px' }}>
                                            {locale === 'ar' ?
                                                codeInfoCountryData?.data?.config?.yassir_contact?.phones.replaceAll(' ', '.') :
                                                codeInfoCountryData?.data?.config?.yassir_contact?.phones}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </p>
                    </div>
                    <div className={classes.rightPart}>
                        <p className={classes.law}>
                            © {d.getFullYear()} Yassir. All rights reserved
                        </p>
                    </div>
                </div>
                <div className={classes.ctnMobile}>
                    <div className={classes.ctnDataFooter}>
                        <p style={{ color: '#767676', fontWeight: '600' }}>
                            <p>
                                <BsEnvelope className={classes.styleIconMobVer} />
                                <span className={classes.styleEmailMobVer} style={{ marginLeft: '25px' }}>
                                    {codeInfoCountryData?.data?.config?.yassir_contact?.email}
                                </span>
                            </p>
                            <p>
                                <BsTelephone className={classes.styleIconMobVer} />
                                <span className={classes.styleNumPhoneMobVer} style={{ marginLeft: '20px' }}>
                                    {locale === 'ar' ?
                                        codeInfoCountryData?.data?.config?.yassir_contact?.phones.replaceAll(' ', '.') :
                                        codeInfoCountryData?.data?.config?.yassir_contact?.phones}
                                </span>
                            </p>
                        </p>
                    </div>
                    <div className={classes.ctnLaw}>
                        <p className={classes.law}>
                            © {d.getFullYear()} Yassir. All rights reserved
                        </p>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default Footer