import React, { ReactElement, useState, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Typography,
  Stepper,
  Step,
  Button,
  CircularProgress,
  StepLabel,
} from '@material-ui/core';
import Backdrop from "@mui/material/Backdrop";
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { IFormInput, ParamsTypes } from '../../utils/types';
import { useConfigData } from '../../contexts/configContext';
import { useStyles, ColorlibConnector } from './styles';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { StepContent } from '../../components/MultiSteps/StepContent';
import Translate from '../../utils/Translate';
import { useSubmit } from '../../hooks/useSubmit';
import { ONBOARDING_API } from '../../utils/constants';
import { difference } from '../../utils/objectsDifference';

const FormStepper = (): ReactElement => {
  const classes = useStyles();
  const history = useHistory();

  const { groupStepId, stepId } = useParams<ParamsTypes>();
  const { getStep, getGroupStepById, getCanEditFields, setConfig, config } = useConfigData();

  const selectedStep = getStep(groupStepId);
  const selectedGroup = getGroupStepById(groupStepId);
  const [values, setValues] = useState({});
  const [isDocument, setIsDocument] = useState(false);

  const {
    isLoading: createLoading,
    isSuccess: successCreateGroupStep,
    mutate: mutateCreate,
    data: createData,
  } = useSubmit({
    path: `${ONBOARDING_API}/group-steps/${groupStepId}`,
  });

  const {
    isLoading: getGroupStepDataLoading,
    isSuccess: successGetGroupStepData,
    isError: errorGetGroupStepData,
    data: groupStepData,
    error: groupStepDataError,
    mutate: mutateGetGroupStepData
  } = useSubmit({
    path: `${ONBOARDING_API}/group-steps/${groupStepId}`,
    method: 'get',
  });

  const {
    isLoading: getGroupStepDateLoading,
    isSuccess: successGetIsDocument,
    isError: errorGetIsDocument,
    data: isDocumentData,
    error: isDocumentError,
    mutate: mutateIsGroupStepContainsDocument
  } = useSubmit({
    path: `${ONBOARDING_API}/group-steps/${groupStepId}/has-document`,
    method: 'get',
  });

  const methods = useForm<IFormInput>({
    shouldUnregister: false
  });

  const { handleSubmit, reset } = methods;

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {},
  );
  // const steps = getSteps(data);
  const steps = new Array(selectedGroup.steps.length).fill('');

  const totalSteps = (): number => steps.length;

  const completedSteps = (): number => Object.keys(completed).length;

  const isLastStep = (): boolean => activeStep === totalSteps() - 1;

  const allStepsCompleted = (): boolean => completedSteps() === totalSteps();

  const handleNext = (submitedData: any): void => {
    let newActiveStep;
    if (isLastStep() && !allStepsCompleted()) {
      newActiveStep = steps.findIndex((step, i) => !(i in completed));
      setActiveStep(newActiveStep);
    } else if (isLastStep() && allStepsCompleted()) {
      if (!isDocument || !values) {
        mutateCreate(submitedData);
      }
      else {
        const newChanges = difference(values, submitedData)
        const regexEnd = /.*_endDate$/;
        const regexStart = /.*_startDate$/;
        const match: any[] = [];
        Object.keys(newChanges).forEach((element) => {
          if (regexEnd.exec(element)) match.push(...[regexEnd.exec(element)]);
          if (regexStart.exec(element)) match.push(...[regexStart.exec(element)]);
        });
        match?.forEach(element => {
          delete newChanges[element];
        })
        mutateCreate(newChanges);
      }
    } else {
      newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  const handleComplete = (submitedData: any): void => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext(submitedData);
  };

  const handleReset = (): void => {
    setActiveStep(0);
    setCompleted({});
  };

  const onSubmit: SubmitHandler<IFormInput> = (submitedData) => {
    handleComplete(submitedData);
  };

  let btnContent = <></>;
  if (createLoading) {
    btnContent = <CircularProgress size={24} />;
  } else if (activeStep === 1) {
    btnContent = <FormattedMessage id='cars.submit' />;
  } else {
    btnContent = <FormattedMessage id='cars.next' />;
  }

  React.useEffect(() => {
    // activeStep;
    // return () => {
    //   cleanup;
    // };
    console.log('🚀 ~===> activeStep', activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (groupStepId) {
      mutateGetGroupStepData({});
      mutateIsGroupStepContainsDocument({});
    }
  }, [groupStepId, mutateGetGroupStepData, mutateIsGroupStepContainsDocument, successCreateGroupStep])


  useEffect(() => {
    if (successGetGroupStepData) {
      if (groupStepData) {
        reset(groupStepData?.data?.data);
        setValues(groupStepData?.data?.data)
      }
    } else {
      console.log({ groupStepDataError })
    }
  }, [groupStepData, groupStepData?.data?.data, groupStepDataError, reset, successGetGroupStepData])

  useEffect(() => {
    if (successGetIsDocument) {
      if (isDocumentData) {
        setIsDocument(isDocumentData?.data?.data?.isGroupStepContainsDocument)
      }
    }
  }, [isDocumentData, mutateIsGroupStepContainsDocument, successGetIsDocument])

  useEffect(() => {
    if (successCreateGroupStep) {
      const { groupSteps, canSubmit } = createData?.data?.data;
      const stepStatus = groupSteps?.filter(step => step.name === selectedGroup.name)[0]?.status
      const groupStepsTemp = config?.groupSteps.map((step) => {
        if (step.id === groupStepId) {
          return { ...step, status: stepStatus }
        }
        return step
      })
      setConfig({ ...config, groupSteps: groupStepsTemp, canSubmit })
      history.push('/register')
    }
  }, [createData?.data?.data, groupStepId, history, selectedGroup.name, successCreateGroupStep, config, setConfig])

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={getGroupStepDataLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.bgHeader}>
        <Header />
        <Breadcrumb
          title={
            selectedStep.label
              ? Translate(selectedStep.label)
              : selectedStep.name
          }
          icon={selectedGroup.icon}
          isMultiSteps
          activeStep={activeStep}
          handleBack={handleBack}
        />
      </div>
      <div className={classes.content}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          className={classes.stepper}
          connector={<ColorlibConnector />}
        >
          {React.Children.toArray(
            steps.map((label, index) => (
              <Step completed={completed[index]}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.stepIcon,
                      active: classes.stepIconActive,
                      completed: classes.stepIconCompleted,
                    },
                  }}
                />
                {label}
              </Step>
            )),
          )}
        </Stepper>
        {/* {allStepsCompleted() ? (
          <>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </>
        ) : ( */}
        <FormProvider {...methods}>
        {StepContent(selectedGroup.steps[activeStep].groupFields, getCanEditFields)}
          {activeStep === 1 ? (
            /* eslint-disable-next-line */
            <button
              id='submitButtonId'
              className={`${classes.submitButton} ${
                createLoading
                  ? classes.disabledSubmitButton
                  : classes.enabledSubmitButton
              }`}
              onClick={handleSubmit(onSubmit)}
              type='submit'
            >
              <FormattedMessage id='cars.next' />
            </button>
          ) : (
            <button
              id='submitButtonIdStep1'
              className={`${classes.submitButton} ${
                createLoading
                  ? classes.disabledSubmitButton
                  : classes.enabledSubmitButton
              }`}
              onClick={handleSubmit(onSubmit)}
              type='submit'
            >
              {btnContent}
            </button>
          )}
        </FormProvider>
        {/* )} */}
      </div>
    </div>
  );
};

export default FormStepper;
