import { AxiosError, AxiosResponse, Method } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { IFormInput } from '../utils/types';
import { useAxios } from './useAxios';

export interface SubmitProps {
  path: string;
  method?:  Method;
}

export const useSubmit = (
  props?: SubmitProps,
): UseMutationResult<AxiosResponse, AxiosError, IFormInput, void> => {
  const axios = useAxios();
  
  const path =
    props && props.path
      ? props.path
      : ``;

  const method = props && props.method
    ? props.method : 'post';

  return useMutation(
    async (values: any) =>
      axios.request({ method, url: path, data: values, 
        headers: 
        localStorage.getItem('token') ?
        { 'content-type': 'application/json', 'Authorization': `${localStorage.getItem('token')}` } : 
        { 'content-type': 'application/json' }  
      }),
    {
      onMutate: (values) => {
        console.log('🚀 ~ onMutate  --- values', values);
      },
      onSuccess: (data, values) => {
        console.log(' OnSuccess, yep ', data, values);
      },
      onError: (err) => {
        console.error('🚀 ~ useSubmit ~ err', err);
      },
    },
  );
};
