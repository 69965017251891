import React, { ReactElement, useRef, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type Screen = {
  title: Map<string, string>;
  body: Map<string, string>;
};
type AppDataType = {
  screen: Screen | undefined;
  setScreen: (screen: Screen) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useScreenData = (): any => React.useContext(Context);

const ScreenProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const map1 = new Map();
  
  const map2 = new Map();
  
  const [screen, setScreen] = useState({  
    title: map1,
    body: map2,
  });

  const value = React.useMemo(
    () => ({
      setScreen,
      screen
    }),
    [
      setScreen,
      screen
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { ScreenProvider, Context as ScreenContext };

