import React, { ReactElement } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { getColors } from '../../api/customSelect';
import { IntlContext } from '../../contexts/intlContext';

interface SelectProps {
  name: string;
  label: MultiLangType;
  required: boolean | undefined;
  disabled: boolean | undefined;
}

const SelectColor: React.FC<SelectProps> = ({
  name,
  label,
  required,
  disabled
}): ReactElement => {
  const classes = useStyles();

  const { field, fieldState } = useController({ name });
  const { control } = useFormContext();
  const { messageLang } = React.useContext(IntlContext)?.locales;

  const [selectedColor, setSelectedColor] = React.useState(field.value || '');
  const { isFetched: isColorFetched, data: colors } = useQuery(
    ['colors'],
    getColors,
  );

  const handleColor = (event: any): void => {
    setSelectedColor((event.target as HTMLInputElement).value);
  };

  if (!isColorFetched || !colors || colors.length === 0) return <></>;
  return (
    <>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='custom-select-color-outlined-label'>
          {Translate(label)}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              labelId='custom-select-color-outlined-label'
              id='custom-select-color-outlined'
              onChange={(e) => {
                handleColor(e);
                onChange(e);
              }}
              onBlur={onBlur}
              label={Translate(label)}
              name={name}
              ref={ref}
              disabled={disabled || !isColorFetched}
              defaultValue={field.value || selectedColor}
              value={value || selectedColor}
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {isColorFetched && colors && colors.length
                ? React.Children.toArray(
                  colors.map((item: any) => (
                    <MenuItem value={item.code}>
                      <span
                        className={classes.spanColor}
                        style={{ backgroundColor: item.code }}
                      />
                      {item.label
                        ? Translate(item.label, item.name)
                        : item.name}
                    </MenuItem>
                  )),
                )
                : ''}
            </Select>
          )}
          rules={{ required }}
        />
        {fieldState.invalid && (
          <Typography className={classes.error}>
            {fieldState.invalid && `${Translate(label)} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

export default SelectColor;
