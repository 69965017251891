import React, { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { FaPlus } from 'react-icons/fa';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CardItem from '../../components/CardItem/CardItem';
import CarDialog from '../../components/CarDialog/CarDialog';
import DialogStepper from '../../components/MultiSteps/DialogMultiSteps';
import { useConfigData } from '../../contexts/configContext';
import { ICard, ParamsTypes } from '../../utils/types';

const carsData: ICard[] = [
  {
    id: '1',
    title: 'Dacia Sandero',
    subtitle: 'AA-001-AA',
    image:
      'https://www.leaselink.co.nz/themes/sl-bootstrap/dist/images/nophoto.png',
  },
  {
    id: '2',
    title: 'Dacia Sandero',
    subtitle: 'AA-002-BB',
    image:
      'https://www.leaselink.co.nz/themes/sl-bootstrap/dist/images/nophoto.png',
  },
  {
    id: '3',
    title: 'Dacia Sandero',
    subtitle: 'AA-002-CC',
    image:
      'https://www.leaselink.co.nz/themes/sl-bootstrap/dist/images/nophoto.png',
  },
];

const FormCars: React.FC = (): ReactElement => {
  const classes = useStyles();
  const [carsList, setCarsList] = useState(carsData);

  const [selectedItem, setSelectedItem] = useState<undefined | string>(
    undefined,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(event.currentTarget.dataset.id);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDelete = (itemId: string): void => {
    const data = carsList.filter((item) => item.id !== itemId);
    setCarsList(data);
    handleClose();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const closeDialog = (): void => {
    setOpenDialog(false);
  };

  const [openCarDialog, setOpenCarDialog] = useState(true);

  const closeCarDialog = (): void => {
    setOpenCarDialog(false);
  };
  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const { groupStepId, stepId } = useParams<ParamsTypes>();
  const { getStep } = useConfigData();
  const selectedStep = getStep(groupStepId, stepId);

  return (
    <>
      <div>
        <CarDialog closeDialog={closeCarDialog} openDialog={openCarDialog} />
        <DialogStepper
          title='New Driver'
          groupFields={selectedStep.groupFields}
          openDialog={openDialog}
          closeDialog={closeDialog}
        />
        <div className={classes.bgHeader}>
          <Header />
          <Breadcrumb
            title={<FormattedMessage id='app.vehicles-list' />}
            subtitle={<FormattedMessage id='app.add-manage-vehicles' />}
            icon='scan_car_doc'
            isMultiSteps
          />
        </div>
        <div className={classes.content}>
          <Button
            color='secondary'
            className={classes.button}
            startIcon={<FaPlus />}
            onClick={handleOpenDialog}
          >
            <FormattedMessage id='cars.add-vehicle' />
          </Button>
          <Typography className={classes.subtitle}>
            <FormattedMessage id='cars.add-vehicles-first' />
          </Typography>

          {React.Children.toArray(
            carsList.map(
              (item): ReactElement => (
                <CardItem
                  id={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  image={item.image}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  selectedItem={selectedItem}
                />
              ),
            ),
          )}

          <div className={classes.maxWidth}>
            <Button
              variant='contained'
              disabled
              classes={{
                root: classes.button,
                disabled: classes.disabled,
              }}
              color='secondary'
            >
              <FormattedMessage id='general-info.subscribe' />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCars;
