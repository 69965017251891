import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    // padding: '14px 16px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    textAlign: 'left',
  },
  card: {
    display: 'block',
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "8px",
    margin: '0px',
    alignItems: 'center',
    marginTop: 20,
    width: '90%',
  },
  form: {
    display: 'contents',
  },
  fieldset: {
    margin: '16px 0',
    width: '100%',
    '& > *': {
      margin: '16px 0',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    margin: '8px 0 0',
    color: theme.palette.text.primary,
  },
}));
