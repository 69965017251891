import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  field: {
    background: '#F5F5F5',
    borderRadius: theme.shape.borderRadius,
    padding: '4px 8px',
    margin: '4px 0',
    color: '#1B1B1B',
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    textAlign: 'left',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    lineHeight: '1.66',
  },
  root: {
    backgroundColor: '#eee',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#333',
    padding: '10px',
    marginTop: '20px',
  },
  icon: {
    marginTop: '16px',
    color: '#888888',
    fontSize: '42px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    margin: 0,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    margin: '8px 0 0',
    color: theme.palette.text.primary,
  },
  inputContainer: {
    background: '#f9f9f9',
    borderFadius: '10px',
    cursor: 'pointer',
    position: 'relative',
  },
  inputFile: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 20px',
    border: `0.5px solid lightgrey`,
    borderRadius: '10px',
  },
  inputPreview: {
    width: 50,
    height: 50,
    borderRadius: 30,
    background: '#8c8c8c',
    marginRight: 20,
    textAlign: 'center',
    overflow: 'hidden',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '0.5px solid lightgrey',
    padding: 10,
    fontSize: 12,
  },
  modify: {
    color: '#545454',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'right',
    flex: 1,
  },
}));
