import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    margin: '16px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    background: theme.palette.background.paper,
    width: '432px',
    boxSizing: 'border-box',
    textAlign: 'left',
  },
  disabledButton: {
    backgroundColor: theme.palette.background.paper,
  },
  bgHeader: {
    background: '#F7F7F7',
  },
  innerWrapper: {
    width: `calc(100% - 40px)`,
    background: theme.palette.background.paper,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textSecondary: {
    margin: '16px 0',
    color: theme.palette.text.secondary,
  },
}));
