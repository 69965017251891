import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import clsx from 'clsx';
import useStyles from './styles';
import Translate from '../../utils/Translate';
import { MultiLangType } from '../../utils/types';
import { GroupStepStatus } from '../../utils/enum';
import GroupStepLink from './GroupStepLink';
import { useConfigData } from '../../contexts/configContext';
import { useIntlData } from '../../contexts/intlContext';

interface GroupStepProps {
  id?: string;
  groupId?: string;
  label: MultiLangType;
  description: MultiLangType;
  status: string;
  icon: string;
  path?: string;
  isActive: boolean;
}

const GroupStepItem: React.FC<GroupStepProps> = ({
  id,
  groupId,
  label,
  description,
  status,
  icon,
  path,
  isActive,
}: GroupStepProps): ReactElement => {
  const classes = useStyles();
  const { getIcon } = useConfigData();
  const stepIcon = getIcon(icon)

  const { isRtl } = useIntlData();

  let containerClasses = clsx(classes.container);
  let actionIcon = <></>;

  let disabled = false;
  switch (status) {
    case GroupStepStatus.disabled:
      containerClasses = clsx(classes.container, classes.disabledContent);
      disabled = true;
      break;
    case GroupStepStatus.none:
      if (isActive) {
        actionIcon = (
          <div className={classes.arrowIcon}>
            {isRtl ? <FiChevronLeft /> : <FiChevronRight />}
          </div>
        );
        containerClasses = clsx(classes.container, classes.defaultContent);
      }
      else {
        containerClasses = clsx(classes.container, classes.disabledContent);
        disabled = !isActive;
      }
      break;
    case GroupStepStatus.pending:
      actionIcon = (
        <IoIosCheckmarkCircle style={{ fontSize: 25, color: '#27c780' }} />
      );
      containerClasses = clsx(classes.container, classes.defaultContent);
      break;
    case GroupStepStatus.valid:
      actionIcon = (
        <IoIosCheckmarkCircle style={{ fontSize: 25, color: '#27c780' }} />
      );
      containerClasses = clsx(classes.container, classes.successContent);
      break;
    case GroupStepStatus.invalid:
      actionIcon = (
        <FaTimesCircle style={{ fontSize: 25, color: 'red' }} />
      );
      containerClasses = clsx(classes.container, classes.invalidContent);
      break;
    default:
      containerClasses = clsx(classes.container, classes.disabledContent);
      break;
  }

  return (
    <>
      <GroupStepLink disabled={disabled} path={path} groupId={groupId} id={id}>
        <div className={containerClasses}>
          <div className={classes.flexRow}>
            <div className={classes.infoIcon}>
              {/* <CustomIcon iconName={icon} /> */}
              <img src={stepIcon} alt='ICO' />
            </div>
            <div className={classes.content}>
              <Typography className={classes.title}>
                {Translate(label)}
              </Typography>
              <Typography className={classes.subtitle} color='secondary'>
                {Translate(description)}
              </Typography>
            </div>
          </div>
          {actionIcon}
        </div>
      </GroupStepLink>
    </>
  );
};

GroupStepItem.defaultProps = {
  id: undefined,
  groupId: undefined,
  path: undefined,
};
export default GroupStepItem;
