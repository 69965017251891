import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { useConfig } from '../hooks/useConfig';
import { ChildrenType } from '../utils/types';
import User  from "../assets/icons/user.svg";
import ScanDoc from "../assets/icons/scan_doc.svg";
import ScanCarDoc  from "../assets/icons/scan_car_doc.svg";
import ScanPersDoc  from "../assets/icons/scan_perso_doc.svg";
import { GroupStepStatus } from '../utils/enum';

type AppDataType = {
  isTokenForConfig: boolean;
  config: any;
  status: string;
  error: any;
  isFetching: boolean;
  getStep: (groupId: string) => any;
  getStepsByGroupId: (groupId: string) => any;
  getGroupStepById: (groupId: string) => any;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useConfigData = (): any => React.useContext(Context);

const ConfigProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const { status, data, error, isFetching } = useConfig();

  const [config, setConfig] = useState(data);

  const [isTokenForConfig, setIsTokenForConfig] = useState(false);

  const [selectedVehicleType, setSelectedVehicleType] = React.useState('');

  const history = useHistory();

  useEffect(() => {
    setConfig(data)
  }, [data])

  useEffect(() => {
    if(localStorage.getItem('token')) setIsTokenForConfig(true);
  }, [localStorage.getItem('token')])

  const getStep = (groupId: string): any => {
    try {
      const selectedGroup = config.groupSteps.filter(
        (groupStep: any) => groupStep.id === groupId,
      );
      // const selectedStep = selectedGroup[0].steps.filter(
      //   (step: any) => step.id === id,
      // );
      // console.log('🚀 ~ getStep ~ selectedStep', selectedStep);
      // return selectedStep[0];
      return selectedGroup[0].steps[0];
    } catch (err) {
      history.push('/register');
      window.location.reload();
    }
    return false;
  };

  const getStepsByGroupId = (groupId: string): any => {
    try {
      const selectedGroup = config.groupSteps.filter(
        (groupStep: any) => groupStep.id === groupId,
      );
      return selectedGroup[0].steps;
    } catch (err) {
      history.push('/register');
      window.location.reload();
    }
    return false;
  };

  const getGroupStepById = (groupId: string): any => {
    try {
      const selectedGroup = config.groupSteps.filter(
        (groupStep: any) => groupStep.id === groupId,
      );
      return selectedGroup[0];
    } catch (err) {
      history.push('/register');
      window.location.reload();
    }
    return false;
  };

  const getCanEditFields = (): any => config?.canEditFields;

  const getIcon = (step: string): string => {
    switch (step) {
      case 'DRIVER_INFOS':
        return ScanPersDoc
      case 'VEHICLE_INFOS':
      case 'DRIVER_DOCUMENTS': 
        return ScanCarDoc
      case 'VEHICLE_DOCUMENTS':
        return ScanDoc
        break;
      default:
        return User
        break;
    }
  }

  const isPreviousStepCompleted = (groupId: string): any => {
    try {
      const selectedGroup = config.groupSteps.filter(
        (groupStep: any) => groupStep.id === groupId,
      )[0];
      const previousGroup = config.groupSteps.filter(
        (groupStep: any) => groupStep.order < selectedGroup.order,
      )[0];
      if (!previousGroup) return true;
      return previousGroup?.status !== GroupStepStatus.none;
    } catch (err) {
      history.push('/register');
      window.location.reload();
    }
    return false;
  };

  const value = React.useMemo(
    () => ({
      isTokenForConfig, setIsTokenForConfig,
      config,
      status,
      isFetching,
      error,
      getStep,
      getStepsByGroupId,
      getGroupStepById,
      getIcon,
      isPreviousStepCompleted,
      setConfig,
      getCanEditFields,
      selectedVehicleType,
      setSelectedVehicleType,
    }),
    [
      isTokenForConfig, setIsTokenForConfig,
      config,
      error,
      getGroupStepById,
      getStep,
      getStepsByGroupId,
      isFetching,
      status,
      isPreviousStepCompleted,
      setConfig,
      getCanEditFields,
      selectedVehicleType,
      setSelectedVehicleType,
    ],
  );

  // if (status !== 'success') {
  //   return (
  //     <>
  //       <Loader />
  //     </>
  //   );
  // }

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { ConfigProvider, Context as ConfigContext };
