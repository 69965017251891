/**
 * Country code converter from iso3 to iso2
 */
export const countryCodeConverterIso3ToIso2 = new Map();
countryCodeConverterIso3ToIso2.set('ABW', 'AW');
countryCodeConverterIso3ToIso2.set('AFG', 'AF');
countryCodeConverterIso3ToIso2.set('AGO', 'AO');
countryCodeConverterIso3ToIso2.set('AIA', 'AI');
countryCodeConverterIso3ToIso2.set('ALA', 'AX');
countryCodeConverterIso3ToIso2.set('ALB', 'AL');
countryCodeConverterIso3ToIso2.set('AND', 'AD');
countryCodeConverterIso3ToIso2.set('ARE', 'AE');
countryCodeConverterIso3ToIso2.set('ARG', 'AR');
countryCodeConverterIso3ToIso2.set('ARM', 'AM');
countryCodeConverterIso3ToIso2.set('ASM', 'AS');
countryCodeConverterIso3ToIso2.set('ATA', 'AQ');
countryCodeConverterIso3ToIso2.set('ATF', 'TF');
countryCodeConverterIso3ToIso2.set('ATG', 'AG');
countryCodeConverterIso3ToIso2.set('AUS', 'AU');
countryCodeConverterIso3ToIso2.set('AUT', 'AT');
countryCodeConverterIso3ToIso2.set('AZE', 'AZ');
countryCodeConverterIso3ToIso2.set('BDI', 'BI');
countryCodeConverterIso3ToIso2.set('BEL', 'BE');
countryCodeConverterIso3ToIso2.set('BEN', 'BJ');
countryCodeConverterIso3ToIso2.set('BFA', 'BF');
countryCodeConverterIso3ToIso2.set('BGD', 'BD');
countryCodeConverterIso3ToIso2.set('BGR', 'BG');
countryCodeConverterIso3ToIso2.set('BHR', 'BH');
countryCodeConverterIso3ToIso2.set('BHS', 'BS');
countryCodeConverterIso3ToIso2.set('BIH', 'BA');
countryCodeConverterIso3ToIso2.set('BLM', 'BL');
countryCodeConverterIso3ToIso2.set('BLR', 'BY');
countryCodeConverterIso3ToIso2.set('BLZ', 'BZ');
countryCodeConverterIso3ToIso2.set('BMU', 'BM');
countryCodeConverterIso3ToIso2.set('BOL', 'BO');
countryCodeConverterIso3ToIso2.set('BRA', 'BR');
countryCodeConverterIso3ToIso2.set('BRB', 'BB');
countryCodeConverterIso3ToIso2.set('BRN', 'BN');
countryCodeConverterIso3ToIso2.set('BTN', 'BT');
countryCodeConverterIso3ToIso2.set('BVT', 'BV');
countryCodeConverterIso3ToIso2.set('BWA', 'BW');
countryCodeConverterIso3ToIso2.set('CAF', 'CF');
countryCodeConverterIso3ToIso2.set('CAN', 'CA');
countryCodeConverterIso3ToIso2.set('CCK', 'CC');
countryCodeConverterIso3ToIso2.set('CHE', 'CH');
countryCodeConverterIso3ToIso2.set('CHL', 'CL');
countryCodeConverterIso3ToIso2.set('CHN', 'CN');
countryCodeConverterIso3ToIso2.set('CIV', 'CI');
countryCodeConverterIso3ToIso2.set('CMR', 'CM');
countryCodeConverterIso3ToIso2.set('COD', 'CD');
countryCodeConverterIso3ToIso2.set('COG', 'CG');
countryCodeConverterIso3ToIso2.set('COK', 'CK');
countryCodeConverterIso3ToIso2.set('COL', 'CO');
countryCodeConverterIso3ToIso2.set('COM', 'KM');
countryCodeConverterIso3ToIso2.set('CPV', 'CV');
countryCodeConverterIso3ToIso2.set('CRI', 'CR');
countryCodeConverterIso3ToIso2.set('CUB', 'CU');
countryCodeConverterIso3ToIso2.set('CUW', 'CW');
countryCodeConverterIso3ToIso2.set('CXR', 'CX');
countryCodeConverterIso3ToIso2.set('CYM', 'KY');
countryCodeConverterIso3ToIso2.set('CYP', 'CY');
countryCodeConverterIso3ToIso2.set('CZE', 'CZ');
countryCodeConverterIso3ToIso2.set('DEU', 'DE');
countryCodeConverterIso3ToIso2.set('DJI', 'DJ');
countryCodeConverterIso3ToIso2.set('DMA', 'DM');
countryCodeConverterIso3ToIso2.set('DNK', 'DK');
countryCodeConverterIso3ToIso2.set('DOM', 'DO');
countryCodeConverterIso3ToIso2.set('DZA', 'DZ');
countryCodeConverterIso3ToIso2.set('ECU', 'EC');
countryCodeConverterIso3ToIso2.set('EGY', 'EG');
countryCodeConverterIso3ToIso2.set('ERI', 'ER');
countryCodeConverterIso3ToIso2.set('ESH', 'EH');
countryCodeConverterIso3ToIso2.set('ESP', 'ES');
countryCodeConverterIso3ToIso2.set('EST', 'EE');
countryCodeConverterIso3ToIso2.set('ETH', 'ET');
countryCodeConverterIso3ToIso2.set('FIN', 'FI');
countryCodeConverterIso3ToIso2.set('FJI', 'FJ');
countryCodeConverterIso3ToIso2.set('FLK', 'FK');
countryCodeConverterIso3ToIso2.set('FRA', 'FR');
countryCodeConverterIso3ToIso2.set('FRO', 'FO');
countryCodeConverterIso3ToIso2.set('FSM', 'FM');
countryCodeConverterIso3ToIso2.set('GAB', 'GA');
countryCodeConverterIso3ToIso2.set('GBR', 'GB');
countryCodeConverterIso3ToIso2.set('GEO', 'GE');
countryCodeConverterIso3ToIso2.set('GGY', 'GG');
countryCodeConverterIso3ToIso2.set('GHA', 'GH');
countryCodeConverterIso3ToIso2.set('GIB', 'GI');
countryCodeConverterIso3ToIso2.set('GIN', 'GN');
countryCodeConverterIso3ToIso2.set('GLP', 'GP');
countryCodeConverterIso3ToIso2.set('GMB', 'GM');
countryCodeConverterIso3ToIso2.set('GNB', 'GW');
countryCodeConverterIso3ToIso2.set('GNQ', 'GQ');
countryCodeConverterIso3ToIso2.set('GRC', 'GR');
countryCodeConverterIso3ToIso2.set('GRD', 'GD');
countryCodeConverterIso3ToIso2.set('GRL', 'GL');
countryCodeConverterIso3ToIso2.set('GTM', 'GT');
countryCodeConverterIso3ToIso2.set('GUF', 'GF');
countryCodeConverterIso3ToIso2.set('GUM', 'GU');
countryCodeConverterIso3ToIso2.set('GUY', 'GY');
countryCodeConverterIso3ToIso2.set('HKG', 'HK');
countryCodeConverterIso3ToIso2.set('HMD', 'HM');
countryCodeConverterIso3ToIso2.set('HND', 'HN');
countryCodeConverterIso3ToIso2.set('HRV', 'HR');
countryCodeConverterIso3ToIso2.set('HTI', 'HT');
countryCodeConverterIso3ToIso2.set('HUN', 'HU');
countryCodeConverterIso3ToIso2.set('IDN', 'ID');
countryCodeConverterIso3ToIso2.set('IMN', 'IM');
countryCodeConverterIso3ToIso2.set('IND', 'IN');
countryCodeConverterIso3ToIso2.set('IOT', 'IO');
countryCodeConverterIso3ToIso2.set('IRL', 'IE');
countryCodeConverterIso3ToIso2.set('IRN', 'IR');
countryCodeConverterIso3ToIso2.set('IRQ', 'IQ');
countryCodeConverterIso3ToIso2.set('ISL', 'IS');
countryCodeConverterIso3ToIso2.set('ISR', 'IL');
countryCodeConverterIso3ToIso2.set('ITA', 'IT');
countryCodeConverterIso3ToIso2.set('JAM', 'JM');
countryCodeConverterIso3ToIso2.set('JEY', 'JE');
countryCodeConverterIso3ToIso2.set('JOR', 'JO');
countryCodeConverterIso3ToIso2.set('JPN', 'JP');
countryCodeConverterIso3ToIso2.set('KAZ', 'KZ');
countryCodeConverterIso3ToIso2.set('KEN', 'KE');
countryCodeConverterIso3ToIso2.set('KGZ', 'KG');
countryCodeConverterIso3ToIso2.set('KHM', 'KH');
countryCodeConverterIso3ToIso2.set('KIR', 'KI');
countryCodeConverterIso3ToIso2.set('KNA', 'KN');
countryCodeConverterIso3ToIso2.set('KOR', 'KR');
countryCodeConverterIso3ToIso2.set('UNK', 'XK');
countryCodeConverterIso3ToIso2.set('KWT', 'KW');
countryCodeConverterIso3ToIso2.set('LAO', 'LA');
countryCodeConverterIso3ToIso2.set('LBN', 'LB');
countryCodeConverterIso3ToIso2.set('LBR', 'LR');
countryCodeConverterIso3ToIso2.set('LBY', 'LY');
countryCodeConverterIso3ToIso2.set('LCA', 'LC');
countryCodeConverterIso3ToIso2.set('LIE', 'LI');
countryCodeConverterIso3ToIso2.set('LKA', 'LK');
countryCodeConverterIso3ToIso2.set('LSO', 'LS');
countryCodeConverterIso3ToIso2.set('LTU', 'LT');
countryCodeConverterIso3ToIso2.set('LUX', 'LU');
countryCodeConverterIso3ToIso2.set('LVA', 'LV');
countryCodeConverterIso3ToIso2.set('MAC', 'MO');
countryCodeConverterIso3ToIso2.set('MAF', 'MF');
countryCodeConverterIso3ToIso2.set('MAR', 'MA');
countryCodeConverterIso3ToIso2.set('MCO', 'MC');
countryCodeConverterIso3ToIso2.set('MDA', 'MD');
countryCodeConverterIso3ToIso2.set('MDG', 'MG');
countryCodeConverterIso3ToIso2.set('MDV', 'MV');
countryCodeConverterIso3ToIso2.set('MEX', 'MX');
countryCodeConverterIso3ToIso2.set('MHL', 'MH');
countryCodeConverterIso3ToIso2.set('MKD', 'MK');
countryCodeConverterIso3ToIso2.set('MLI', 'ML');
countryCodeConverterIso3ToIso2.set('MLT', 'MT');
countryCodeConverterIso3ToIso2.set('MMR', 'MM');
countryCodeConverterIso3ToIso2.set('MNE', 'ME');
countryCodeConverterIso3ToIso2.set('MNG', 'MN');
countryCodeConverterIso3ToIso2.set('MNP', 'MP');
countryCodeConverterIso3ToIso2.set('MOZ', 'MZ');
countryCodeConverterIso3ToIso2.set('MRT', 'MR');
countryCodeConverterIso3ToIso2.set('MSR', 'MS');
countryCodeConverterIso3ToIso2.set('MTQ', 'MQ');
countryCodeConverterIso3ToIso2.set('MUS', 'MU');
countryCodeConverterIso3ToIso2.set('MWI', 'MW');
countryCodeConverterIso3ToIso2.set('MYS', 'MY');
countryCodeConverterIso3ToIso2.set('MYT', 'YT');
countryCodeConverterIso3ToIso2.set('NAM', 'NA');
countryCodeConverterIso3ToIso2.set('NCL', 'NC');
countryCodeConverterIso3ToIso2.set('NER', 'NE');
countryCodeConverterIso3ToIso2.set('NFK', 'NF');
countryCodeConverterIso3ToIso2.set('NGA', 'NG');
countryCodeConverterIso3ToIso2.set('NIC', 'NI');
countryCodeConverterIso3ToIso2.set('NIU', 'NU');
countryCodeConverterIso3ToIso2.set('NLD', 'NL');
countryCodeConverterIso3ToIso2.set('NOR', 'NO');
countryCodeConverterIso3ToIso2.set('NPL', 'NP');
countryCodeConverterIso3ToIso2.set('NRU', 'NR');
countryCodeConverterIso3ToIso2.set('NZL', 'NZ');
countryCodeConverterIso3ToIso2.set('OMN', 'OM');
countryCodeConverterIso3ToIso2.set('PAK', 'PK');
countryCodeConverterIso3ToIso2.set('PAN', 'PA');
countryCodeConverterIso3ToIso2.set('PCN', 'PN');
countryCodeConverterIso3ToIso2.set('PER', 'PE');
countryCodeConverterIso3ToIso2.set('PHL', 'PH');
countryCodeConverterIso3ToIso2.set('PLW', 'PW');
countryCodeConverterIso3ToIso2.set('PNG', 'PG');
countryCodeConverterIso3ToIso2.set('POL', 'PL');
countryCodeConverterIso3ToIso2.set('PRI', 'PR');
countryCodeConverterIso3ToIso2.set('PRK', 'KP');
countryCodeConverterIso3ToIso2.set('PRT', 'PT');
countryCodeConverterIso3ToIso2.set('PRY', 'PY');
countryCodeConverterIso3ToIso2.set('PSE', 'PS');
countryCodeConverterIso3ToIso2.set('PYF', 'PF');
countryCodeConverterIso3ToIso2.set('QAT', 'QA');
countryCodeConverterIso3ToIso2.set('REU', 'RE');
countryCodeConverterIso3ToIso2.set('ROU', 'RO');
countryCodeConverterIso3ToIso2.set('RUS', 'RU');
countryCodeConverterIso3ToIso2.set('RWA', 'RW');
countryCodeConverterIso3ToIso2.set('SAU', 'SA');
countryCodeConverterIso3ToIso2.set('KSA', 'SA');
countryCodeConverterIso3ToIso2.set('SDN', 'SD');
countryCodeConverterIso3ToIso2.set('SEN', 'SN');
countryCodeConverterIso3ToIso2.set('SGP', 'SG');
countryCodeConverterIso3ToIso2.set('SGS', 'GS');
countryCodeConverterIso3ToIso2.set('SJM', 'SJ');
countryCodeConverterIso3ToIso2.set('SLB', 'SB');
countryCodeConverterIso3ToIso2.set('SLE', 'SL');
countryCodeConverterIso3ToIso2.set('SLV', 'SV');
countryCodeConverterIso3ToIso2.set('SMR', 'SM');
countryCodeConverterIso3ToIso2.set('SOM', 'SO');
countryCodeConverterIso3ToIso2.set('SPM', 'PM');
countryCodeConverterIso3ToIso2.set('SRB', 'RS');
countryCodeConverterIso3ToIso2.set('SSD', 'SS');
countryCodeConverterIso3ToIso2.set('STP', 'ST');
countryCodeConverterIso3ToIso2.set('SUR', 'SR');
countryCodeConverterIso3ToIso2.set('SVK', 'SK');
countryCodeConverterIso3ToIso2.set('SVN', 'SI');
countryCodeConverterIso3ToIso2.set('SWE', 'SE');
countryCodeConverterIso3ToIso2.set('SWZ', 'SZ');
countryCodeConverterIso3ToIso2.set('SXM', 'SX');
countryCodeConverterIso3ToIso2.set('SYC', 'SC');
countryCodeConverterIso3ToIso2.set('SYR', 'SY');
countryCodeConverterIso3ToIso2.set('TCA', 'TC');
countryCodeConverterIso3ToIso2.set('TCD', 'TD');
countryCodeConverterIso3ToIso2.set('TGO', 'TG');
countryCodeConverterIso3ToIso2.set('THA', 'TH');
countryCodeConverterIso3ToIso2.set('TJK', 'TJ');
countryCodeConverterIso3ToIso2.set('TKL', 'TK');
countryCodeConverterIso3ToIso2.set('TKM', 'TM');
countryCodeConverterIso3ToIso2.set('TLS', 'TL');
countryCodeConverterIso3ToIso2.set('TON', 'TO');
countryCodeConverterIso3ToIso2.set('TTO', 'TT');
countryCodeConverterIso3ToIso2.set('TUN', 'TN');
countryCodeConverterIso3ToIso2.set('TUR', 'TR');
countryCodeConverterIso3ToIso2.set('TUV', 'TV');
countryCodeConverterIso3ToIso2.set('TWN', 'TW');
countryCodeConverterIso3ToIso2.set('TZA', 'TZ');
countryCodeConverterIso3ToIso2.set('UGA', 'UG');
countryCodeConverterIso3ToIso2.set('UKR', 'UA');
countryCodeConverterIso3ToIso2.set('UMI', 'UM');
countryCodeConverterIso3ToIso2.set('URY', 'UY');
countryCodeConverterIso3ToIso2.set('USA', 'US');
countryCodeConverterIso3ToIso2.set('UZB', 'UZ');
countryCodeConverterIso3ToIso2.set('VAT', 'VA');
countryCodeConverterIso3ToIso2.set('VCT', 'VC');
countryCodeConverterIso3ToIso2.set('VEN', 'VE');
countryCodeConverterIso3ToIso2.set('VGB', 'VG');
countryCodeConverterIso3ToIso2.set('VIR', 'VI');
countryCodeConverterIso3ToIso2.set('VNM', 'VN');
countryCodeConverterIso3ToIso2.set('VUT', 'VU');
countryCodeConverterIso3ToIso2.set('WLF', 'WF');
countryCodeConverterIso3ToIso2.set('WSM', 'WS');
countryCodeConverterIso3ToIso2.set('YEM', 'YE');
countryCodeConverterIso3ToIso2.set('ZAF', 'ZA');
countryCodeConverterIso3ToIso2.set('ZMB', 'ZM');
countryCodeConverterIso3ToIso2.set('ZWE', 'ZW');

/**
 * Country code converter from iso2 to iso3
 */
export const countryCodeConverterIso2ToIso3 = new Map();
countryCodeConverterIso2ToIso3.set('AW', 'ABW');
countryCodeConverterIso2ToIso3.set('AF', 'AFG');
countryCodeConverterIso2ToIso3.set('AO', 'AGO');
countryCodeConverterIso2ToIso3.set('AI', 'AIA');
countryCodeConverterIso2ToIso3.set('AX', 'ALA');
countryCodeConverterIso2ToIso3.set('AL', 'ALB');
countryCodeConverterIso2ToIso3.set('AD', 'AND');
countryCodeConverterIso2ToIso3.set('AE', 'ARE');
countryCodeConverterIso2ToIso3.set('AR', 'ARG');
countryCodeConverterIso2ToIso3.set('AM', 'ARM');
countryCodeConverterIso2ToIso3.set('AS', 'ASM');
countryCodeConverterIso2ToIso3.set('AQ', 'ATA');
countryCodeConverterIso2ToIso3.set('TF', 'ATF');
countryCodeConverterIso2ToIso3.set('AG', 'ATG');
countryCodeConverterIso2ToIso3.set('AU', 'AUS');
countryCodeConverterIso2ToIso3.set('AT', 'AUT');
countryCodeConverterIso2ToIso3.set('AZ', 'AZE');
countryCodeConverterIso2ToIso3.set('BI', 'BDI');
countryCodeConverterIso2ToIso3.set('BE', 'BEL');
countryCodeConverterIso2ToIso3.set('BJ', 'BEN');
countryCodeConverterIso2ToIso3.set('BF', 'BFA');
countryCodeConverterIso2ToIso3.set('BD', 'BGD');
countryCodeConverterIso2ToIso3.set('BG', 'BGR');
countryCodeConverterIso2ToIso3.set('BH', 'BHR');
countryCodeConverterIso2ToIso3.set('BS', 'BHS');
countryCodeConverterIso2ToIso3.set('BA', 'BIH');
countryCodeConverterIso2ToIso3.set('BL', 'BLM');
countryCodeConverterIso2ToIso3.set('BY', 'BLR');
countryCodeConverterIso2ToIso3.set('BZ', 'BLZ');
countryCodeConverterIso2ToIso3.set('BM', 'BMU');
countryCodeConverterIso2ToIso3.set('BO', 'BOL');
countryCodeConverterIso2ToIso3.set('BR', 'BRA');
countryCodeConverterIso2ToIso3.set('BB', 'BRB');
countryCodeConverterIso2ToIso3.set('BN', 'BRN');
countryCodeConverterIso2ToIso3.set('BT', 'BTN');
countryCodeConverterIso2ToIso3.set('BV', 'BVT');
countryCodeConverterIso2ToIso3.set('BW', 'BWA');
countryCodeConverterIso2ToIso3.set('CF', 'CAF');
countryCodeConverterIso2ToIso3.set('CA', 'CAN');
countryCodeConverterIso2ToIso3.set('CC', 'CCK');
countryCodeConverterIso2ToIso3.set('CH', 'CHE');
countryCodeConverterIso2ToIso3.set('CL', 'CHL');
countryCodeConverterIso2ToIso3.set('CN', 'CHN');
countryCodeConverterIso2ToIso3.set('CI', 'CIV');
countryCodeConverterIso2ToIso3.set('CM', 'CMR');
countryCodeConverterIso2ToIso3.set('CD', 'COD');
countryCodeConverterIso2ToIso3.set('CG', 'COG');
countryCodeConverterIso2ToIso3.set('CK', 'COK');
countryCodeConverterIso2ToIso3.set('CO', 'COL');
countryCodeConverterIso2ToIso3.set('KM', 'COM');
countryCodeConverterIso2ToIso3.set('CV', 'CPV');
countryCodeConverterIso2ToIso3.set('CR', 'CRI');
countryCodeConverterIso2ToIso3.set('CU', 'CUB');
countryCodeConverterIso2ToIso3.set('CW', 'CUW');
countryCodeConverterIso2ToIso3.set('CX', 'CXR');
countryCodeConverterIso2ToIso3.set('KY', 'CYM');
countryCodeConverterIso2ToIso3.set('CY', 'CYP');
countryCodeConverterIso2ToIso3.set('CZ', 'CZE');
countryCodeConverterIso2ToIso3.set('DE', 'DEU');
countryCodeConverterIso2ToIso3.set('DJ', 'DJI');
countryCodeConverterIso2ToIso3.set('DM', 'DMA');
countryCodeConverterIso2ToIso3.set('DK', 'DNK');
countryCodeConverterIso2ToIso3.set('DO', 'DOM');
countryCodeConverterIso2ToIso3.set('DZ', 'DZA');
countryCodeConverterIso2ToIso3.set('EC', 'ECU');
countryCodeConverterIso2ToIso3.set('EG', 'EGY');
countryCodeConverterIso2ToIso3.set('ER', 'ERI');
countryCodeConverterIso2ToIso3.set('EH', 'ESH');
countryCodeConverterIso2ToIso3.set('ES', 'ESP');
countryCodeConverterIso2ToIso3.set('EE', 'EST');
countryCodeConverterIso2ToIso3.set('ET', 'ETH');
countryCodeConverterIso2ToIso3.set('FI', 'FIN');
countryCodeConverterIso2ToIso3.set('FJ', 'FJI');
countryCodeConverterIso2ToIso3.set('FK', 'FLK');
countryCodeConverterIso2ToIso3.set('FR', 'FRA');
countryCodeConverterIso2ToIso3.set('FO', 'FRO');
countryCodeConverterIso2ToIso3.set('FM', 'FSM');
countryCodeConverterIso2ToIso3.set('GA', 'GAB');
countryCodeConverterIso2ToIso3.set('GB', 'GBR');
countryCodeConverterIso2ToIso3.set('GE', 'GEO');
countryCodeConverterIso2ToIso3.set('GG', 'GGY');
countryCodeConverterIso2ToIso3.set('GH', 'GHA');
countryCodeConverterIso2ToIso3.set('GI', 'GIB');
countryCodeConverterIso2ToIso3.set('GN', 'GIN');
countryCodeConverterIso2ToIso3.set('GP', 'GLP');
countryCodeConverterIso2ToIso3.set('GM', 'GMB');
countryCodeConverterIso2ToIso3.set('GW', 'GNB');
countryCodeConverterIso2ToIso3.set('GQ', 'GNQ');
countryCodeConverterIso2ToIso3.set('GR', 'GRC');
countryCodeConverterIso2ToIso3.set('GD', 'GRD');
countryCodeConverterIso2ToIso3.set('GL', 'GRL');
countryCodeConverterIso2ToIso3.set('GT', 'GTM');
countryCodeConverterIso2ToIso3.set('GF', 'GUF');
countryCodeConverterIso2ToIso3.set('GU', 'GUM');
countryCodeConverterIso2ToIso3.set('GY', 'GUY');
countryCodeConverterIso2ToIso3.set('HK', 'HKG');
countryCodeConverterIso2ToIso3.set('HM', 'HMD');
countryCodeConverterIso2ToIso3.set('HN', 'HND');
countryCodeConverterIso2ToIso3.set('HR', 'HRV');
countryCodeConverterIso2ToIso3.set('HT', 'HTI');
countryCodeConverterIso2ToIso3.set('HU', 'HUN');
countryCodeConverterIso2ToIso3.set('ID', 'IDN');
countryCodeConverterIso2ToIso3.set('IM', 'IMN');
countryCodeConverterIso2ToIso3.set('IN', 'IND');
countryCodeConverterIso2ToIso3.set('IO', 'IOT');
countryCodeConverterIso2ToIso3.set('IE', 'IRL');
countryCodeConverterIso2ToIso3.set('IR', 'IRN');
countryCodeConverterIso2ToIso3.set('IQ', 'IRQ');
countryCodeConverterIso2ToIso3.set('IS', 'ISL');
countryCodeConverterIso2ToIso3.set('IL', 'ISR');
countryCodeConverterIso2ToIso3.set('IT', 'ITA');
countryCodeConverterIso2ToIso3.set('JM', 'JAM');
countryCodeConverterIso2ToIso3.set('JE', 'JEY');
countryCodeConverterIso2ToIso3.set('JO', 'JOR');
countryCodeConverterIso2ToIso3.set('JP', 'JPN');
countryCodeConverterIso2ToIso3.set('KZ', 'KAZ');
countryCodeConverterIso2ToIso3.set('KE', 'KEN');
countryCodeConverterIso2ToIso3.set('KG', 'KGZ');
countryCodeConverterIso2ToIso3.set('KH', 'KHM');
countryCodeConverterIso2ToIso3.set('KI', 'KIR');
countryCodeConverterIso2ToIso3.set('KN', 'KNA');
countryCodeConverterIso2ToIso3.set('KR', 'KOR');
countryCodeConverterIso2ToIso3.set('XK', 'UNK');
countryCodeConverterIso2ToIso3.set('KW', 'KWT');
countryCodeConverterIso2ToIso3.set('LA', 'LAO');
countryCodeConverterIso2ToIso3.set('LB', 'LBN');
countryCodeConverterIso2ToIso3.set('LR', 'LBR');
countryCodeConverterIso2ToIso3.set('LY', 'LBY');
countryCodeConverterIso2ToIso3.set('LC', 'LCA');
countryCodeConverterIso2ToIso3.set('LI', 'LIE');
countryCodeConverterIso2ToIso3.set('LK', 'LKA');
countryCodeConverterIso2ToIso3.set('LS', 'LSO');
countryCodeConverterIso2ToIso3.set('LT', 'LTU');
countryCodeConverterIso2ToIso3.set('LU', 'LUX');
countryCodeConverterIso2ToIso3.set('LV', 'LVA');
countryCodeConverterIso2ToIso3.set('MO', 'MAC');
countryCodeConverterIso2ToIso3.set('MF', 'MAF');
countryCodeConverterIso2ToIso3.set('MA', 'MAR');
countryCodeConverterIso2ToIso3.set('MC', 'MCO');
countryCodeConverterIso2ToIso3.set('MD', 'MDA');
countryCodeConverterIso2ToIso3.set('MG', 'MDG');
countryCodeConverterIso2ToIso3.set('MV', 'MDV');
countryCodeConverterIso2ToIso3.set('MX', 'MEX');
countryCodeConverterIso2ToIso3.set('MH', 'MHL');
countryCodeConverterIso2ToIso3.set('MK', 'MKD');
countryCodeConverterIso2ToIso3.set('ML', 'MLI');
countryCodeConverterIso2ToIso3.set('MT', 'MLT');
countryCodeConverterIso2ToIso3.set('MM', 'MMR');
countryCodeConverterIso2ToIso3.set('ME', 'MNE');
countryCodeConverterIso2ToIso3.set('MN', 'MNG');
countryCodeConverterIso2ToIso3.set('MP', 'MNP');
countryCodeConverterIso2ToIso3.set('MZ', 'MOZ');
countryCodeConverterIso2ToIso3.set('MR', 'MRT');
countryCodeConverterIso2ToIso3.set('MS', 'MSR');
countryCodeConverterIso2ToIso3.set('MQ', 'MTQ');
countryCodeConverterIso2ToIso3.set('MU', 'MUS');
countryCodeConverterIso2ToIso3.set('MW', 'MWI');
countryCodeConverterIso2ToIso3.set('MY', 'MYS');
countryCodeConverterIso2ToIso3.set('YT', 'MYT');
countryCodeConverterIso2ToIso3.set('NA', 'NAM');
countryCodeConverterIso2ToIso3.set('NC', 'NCL');
countryCodeConverterIso2ToIso3.set('NE', 'NER');
countryCodeConverterIso2ToIso3.set('NF', 'NFK');
countryCodeConverterIso2ToIso3.set('NG', 'NGA');
countryCodeConverterIso2ToIso3.set('NI', 'NIC');
countryCodeConverterIso2ToIso3.set('NU', 'NIU');
countryCodeConverterIso2ToIso3.set('NL', 'NLD');
countryCodeConverterIso2ToIso3.set('NO', 'NOR');
countryCodeConverterIso2ToIso3.set('NP', 'NPL');
countryCodeConverterIso2ToIso3.set('NR', 'NRU');
countryCodeConverterIso2ToIso3.set('NZ', 'NZL');
countryCodeConverterIso2ToIso3.set('OM', 'OMN');
countryCodeConverterIso2ToIso3.set('PK', 'PAK');
countryCodeConverterIso2ToIso3.set('PA', 'PAN');
countryCodeConverterIso2ToIso3.set('PN', 'PCN');
countryCodeConverterIso2ToIso3.set('PE', 'PER');
countryCodeConverterIso2ToIso3.set('PH', 'PHL');
countryCodeConverterIso2ToIso3.set('PW', 'PLW');
countryCodeConverterIso2ToIso3.set('PG', 'PNG');
countryCodeConverterIso2ToIso3.set('PL', 'POL');
countryCodeConverterIso2ToIso3.set('PR', 'PRI');
countryCodeConverterIso2ToIso3.set('KP', 'PRK');
countryCodeConverterIso2ToIso3.set('PT', 'PRT');
countryCodeConverterIso2ToIso3.set('PY', 'PRY');
countryCodeConverterIso2ToIso3.set('PS', 'PSE');
countryCodeConverterIso2ToIso3.set('PF', 'PYF');
countryCodeConverterIso2ToIso3.set('QA', 'QAT');
countryCodeConverterIso2ToIso3.set('RE', 'REU');
countryCodeConverterIso2ToIso3.set('RO', 'ROU');
countryCodeConverterIso2ToIso3.set('RU', 'RUS');
countryCodeConverterIso2ToIso3.set('RW', 'RWA');
countryCodeConverterIso2ToIso3.set('SA', 'SAU');
countryCodeConverterIso2ToIso3.set('SA', 'KSA');
countryCodeConverterIso2ToIso3.set('SD', 'SDN');
countryCodeConverterIso2ToIso3.set('SN', 'SEN');
countryCodeConverterIso2ToIso3.set('SG', 'SGP');
countryCodeConverterIso2ToIso3.set('GS', 'SGS');
countryCodeConverterIso2ToIso3.set('SJ', 'SJM');
countryCodeConverterIso2ToIso3.set('SB', 'SLB');
countryCodeConverterIso2ToIso3.set('SL', 'SLE');
countryCodeConverterIso2ToIso3.set('SV', 'SLV');
countryCodeConverterIso2ToIso3.set('SM', 'SMR');
countryCodeConverterIso2ToIso3.set('SO', 'SOM');
countryCodeConverterIso2ToIso3.set('PM', 'SPM');
countryCodeConverterIso2ToIso3.set('RS', 'SRB');
countryCodeConverterIso2ToIso3.set('SS', 'SSD');
countryCodeConverterIso2ToIso3.set('ST', 'STP');
countryCodeConverterIso2ToIso3.set('SR', 'SUR');
countryCodeConverterIso2ToIso3.set('SK', 'SVK');
countryCodeConverterIso2ToIso3.set('SI', 'SVN');
countryCodeConverterIso2ToIso3.set('SE', 'SWE');
countryCodeConverterIso2ToIso3.set('SZ', 'SWZ');
countryCodeConverterIso2ToIso3.set('SX', 'SXM');
countryCodeConverterIso2ToIso3.set('SC', 'SYC');
countryCodeConverterIso2ToIso3.set('SY', 'SYR');
countryCodeConverterIso2ToIso3.set('TC', 'TCA');
countryCodeConverterIso2ToIso3.set('TD', 'TCD');
countryCodeConverterIso2ToIso3.set('TG', 'TGO');
countryCodeConverterIso2ToIso3.set('TH', 'THA');
countryCodeConverterIso2ToIso3.set('TJ', 'TJK');
countryCodeConverterIso2ToIso3.set('TK', 'TKL');
countryCodeConverterIso2ToIso3.set('TM', 'TKM');
countryCodeConverterIso2ToIso3.set('TL', 'TLS');
countryCodeConverterIso2ToIso3.set('TO', 'TON');
countryCodeConverterIso2ToIso3.set('TT', 'TTO');
countryCodeConverterIso2ToIso3.set('TN', 'TUN');
countryCodeConverterIso2ToIso3.set('TR', 'TUR');
countryCodeConverterIso2ToIso3.set('TV', 'TUV');
countryCodeConverterIso2ToIso3.set('TW', 'TWN');
countryCodeConverterIso2ToIso3.set('TZ', 'TZA');
countryCodeConverterIso2ToIso3.set('UG', 'UGA');
countryCodeConverterIso2ToIso3.set('UA', 'UKR');
countryCodeConverterIso2ToIso3.set('UM', 'UMI');
countryCodeConverterIso2ToIso3.set('UY', 'URY');
countryCodeConverterIso2ToIso3.set('US', 'USA');
countryCodeConverterIso2ToIso3.set('UZ', 'UZB');
countryCodeConverterIso2ToIso3.set('VA', 'VAT');
countryCodeConverterIso2ToIso3.set('VC', 'VCT');
countryCodeConverterIso2ToIso3.set('VE', 'VEN');
countryCodeConverterIso2ToIso3.set('VG', 'VGB');
countryCodeConverterIso2ToIso3.set('VI', 'VIR');
countryCodeConverterIso2ToIso3.set('VN', 'VNM');
countryCodeConverterIso2ToIso3.set('VU', 'VUT');
countryCodeConverterIso2ToIso3.set('WF', 'WLF');
countryCodeConverterIso2ToIso3.set('WS', 'WSM');
countryCodeConverterIso2ToIso3.set('YE', 'YEM');
countryCodeConverterIso2ToIso3.set('ZA', 'ZAF');
countryCodeConverterIso2ToIso3.set('ZM', 'ZMB');
countryCodeConverterIso2ToIso3.set('ZW', 'ZWE');

