import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
export const theme = createTheme({
  palette: {
    primary: {
      main: '#FF7D00',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000',
    },
    error: {
      main: '#FF6666',
    },
    background: {
      default: '#fff',
    },
    success: {
      main: '#27C780',
      contrastText: '#fff',
    },
    text: {
      primary: '#1B1B1B',
      secondary: '#6D6D6D',
    },
    // action: {
    //   disabledBackground: '#FFCB99',
    //   disabled: '#fff'
    // },
  },
  typography: {
    fontFamily: 'Inter',
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiSelect: {
      root: {
        display: 'flex',
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiRadio: {
      root: {
        color: '#8C8C8C',
        '&$checked': {
          color: '#27C780',
        },
      },
      colorPrimary: {
        color: '#8C8C8C',
        '&$checked': {
          color: '#27C780',
        },
      },
    },
    MuiButton: {
      root: {
        width: '100%',
        padding: '14px',
        background: '#27C780',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        borderColor: '#f00',
        color: '#8C8C8C',
        '&$checked': {
          color: '#27C780',
        },
      },
    },
  },
});

// A custom rtl theme for this app
export const rtlTheme = createTheme({
  ...theme,
  direction: "rtl",
});

// A custom ltr theme for this app
export const ltrTheme = createTheme({
  ...theme,
  direction: "ltr",
});
