import { axiosInstance } from './axiosInstance';

export const getLevel = async (path: string,lang: string): Promise<any> => {
  const axios = axiosInstance();
  const response = await axios.post('/public/sublevels', {
    path,lang
  });
  return response.data.levels;
};

export const getColors = async (): Promise<Record<string, any>[]> => {
  const axios = axiosInstance();
  const response = await axios.post('/vehicles/colors');
  return response.data.data;
};

export const getBrands = async (): Promise<any> => {
  const axios = axiosInstance();
  const response = await axios.post('/vehicles/brands');
  return response.data.data;
};

export const getModels = async (brand: string): Promise<any> => {
  const axios = axiosInstance();
  const response = await axios.post('/vehicles/models', {
    brand,
  });
  return response.data.data;
};
