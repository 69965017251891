import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import carsImage from '../../assets/cars_plus5.svg';

interface DialogProps {
  image: string | undefined;
  title: string | React.ReactNode;
  // subtitle: string | React.ReactNode | undefined;
  content: string | React.ReactNode;
  actions: React.ReactNode;
  openDialog: boolean;
  closeDialog: () => void;
}

const CustomDialog: React.FC<DialogProps> = ({
  image,
  title,
  content,
  actions,
  openDialog,
  closeDialog,
}): ReactElement => {
  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={classes.dialogContainer}
    >
      <div className={classes.container}>
        <DialogTitle id='alert-dialog-title'>
          {image ? (
            <div className={classes.image}>
              <img src={carsImage} alt='images' />
            </div>
          ) : (
            ''
          )}
          <Typography className={classes.title}>{title}</Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id='alert-dialog-description'>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>{actions}</DialogActions>
      </div>
    </Dialog>
  );
};

export default CustomDialog;
