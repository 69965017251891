import React from 'react';
import { IntlContext } from '../contexts/intlContext';

export default function Translate(
  message: Record<string, any> = {},
  name?: string,
): string {
  //   const lang = getLanguage();
  const { locale } = React.useContext(IntlContext)?.locales;

  return message && message[locale]
    ? message[locale] || message.en
    : name || null;
}
