import React, { ReactElement, useCallback, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, FormLabel } from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { IntlContext } from '../../contexts/intlContext';

interface RadioProps {
  name: string;
  label: MultiLangType;
  options: {
    id?: string;
    value: string;
    viewValue: MultiLangType;
  }[];
  disabled: boolean | undefined;
}

const RadioGroupCustom: React.FC<RadioProps> = ({
  name,
  label,
  options,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  const { fieldState } = useController({ name });
  const { control, getValues, setValue } = useFormContext();

  useEffect(() => {
    if (getValues(name) === '/') setValue(name, '')
  }, [getValues(name)])

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref } }) => (
          <>
            <FormLabel className={classes.label}> {Translate(label)}</FormLabel>
            <RadioGroup
              aria-label={Translate(label)}
              name={name}
              value={`${value}` || `${selectedValue}`}
              onChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              onBlur={onBlur}
              ref={ref}
            >
              {React.Children.toArray(
                options.map((item) => (
                  <FormControlLabel
                    value={item.value}
                    className={classes.field}
                    control={<Radio color='primary' ref={ref} />}
                    label={
                      item.viewValue ? Translate(item.viewValue) : item.value
                    }
                    disabled={disabled}
                  />
                )),
              )}
              {fieldState.invalid && (
                <Typography className={classes.error}>
                  {fieldState.invalid && `${Translate(label)} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
                </Typography>
              )}
            </RadioGroup>
          </>
        )}
        rules={{ required: true }}
      />
    </>
  );
};

export default RadioGroupCustom;
