import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  styleScreenTitle:{
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '36px',    
    textAlign: 'center',
    color: '#1A0244'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    background: '#F7F7F7',
    borderRadius: '12px',
    margin: '0px auto',
    padding: '10px',
    width: 550,
    boxSizing: 'border-box',
    [theme.breakpoints.down("xs")]: {
      width: '320px',
    },
    '@media (max-width:500px)': {
      background: 'transparent',
      width: 'auto',
    }
  },

  contentBody:{
    background: '#F7F7F7',
    borderRadius: '12px',
    margin: '45px auto 50px',
    paddingTop: '40px',
    '@media (max-width:500px)': {
      margin: '0px auto 55px',
      background: 'transparent',
      paddingTop: '0px',
    }
  },
  bgHeader: {
    boxShadow: '0px 2px 8px 4px rgba(27, 27, 27, 0.1)',
    display:'grid',
    '@media (min-width:620px)': {
      height: 96,
    },
  },
  body:{    
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1A0244 !important',
    padding: '0px 20px 0px 20px',
  }
}));