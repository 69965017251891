import React, { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { FaPlus } from 'react-icons/fa';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStyles } from './styles';
import Header from '../../components/Header/Header';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CardItem from '../../components/CardItem/CardItem';
import { useConfigData } from '../../contexts/configContext';
import DialogStepper from '../../components/MultiSteps/DialogMultiSteps';
import { ICard, ParamsTypes } from '../../utils/types';

const driversData: ICard[] = [
  {
    id: '1',
    title: 'Daniel Grosjean',
    subtitle: '06 67 55 57 01',
    image: 'profile',
  },
  {
    id: '2',
    title: 'Maxime Hector',
    subtitle: '06 67 55 57 02',
    image: 'profile',
  },
  {
    id: '3',
    title: 'Daniel Grosjean',
    subtitle: '06 67 55 57 02',
    image: 'profile',
  },
];

const DriversList: React.FC = (): ReactElement => {
  const classes = useStyles();
  // const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState(driversData);

  const [selectedItem, setSelectedItem] = useState<undefined | string>(
    undefined,
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(event.currentTarget.dataset.id);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleDelete = (itemId: string): void => {
    const data = drivers.filter((item) => item.id !== itemId);
    setDrivers(data);
    handleClose();
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (): void => {
    setOpenDialog(true);
  };

  const closeDialog = (): void => {
    setOpenDialog(false);
  };

  const { groupStepId, stepId } = useParams<ParamsTypes>();
  const { getStep } = useConfigData();
  const selectedStep = getStep(groupStepId, stepId);

  return (
    <>
      <div>
        <DialogStepper
          title='New Driver'
          groupFields={selectedStep.groupFields}
          openDialog={openDialog}
          closeDialog={closeDialog}
        />
        <div className={classes.bgHeader}>
          <Header />
          <Breadcrumb
            title='Liste des chauffeurs'
            subtitle='Ajouter et gérer votre flotte de chauffeurs.'
            icon='scan_perso_doc'
            isMultiSteps={false}
          />
        </div>
        <div className={classes.content}>
          <Button
            color='secondary'
            className={classes.button}
            startIcon={<FaPlus />}
            onClick={handleOpenDialog}
          >
            <FormattedMessage id='drivers.add-driver' />
          </Button>
          <Typography className={classes.subtitle}>
            <FormattedMessage id='drivers.add-drivers-first' />
          </Typography>

          {React.Children.toArray(
            drivers.map(
              (item): ReactElement => (
                <CardItem
                  id={item.id}
                  title={item.title}
                  subtitle={item.subtitle}
                  image={item.image}
                  anchorEl={anchorEl}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  handleDelete={handleDelete}
                  selectedItem={selectedItem}
                />
              ),
            ),
          )}

          <div className={classes.maxWidth}>
            <Button
              variant='contained'
              disabled
              classes={{
                root: classes.button,
                disabled: classes.disabled,
              }}
              color='secondary'
            >
              <FormattedMessage id='general-info.subscribe' />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriversList;
