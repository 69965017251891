import React, { ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './styles';

const Loader: React.FC = (): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={45} />
    </div>
  );
};

export default Loader;
