import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    margin: '16px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    background: theme.palette.background.paper,
    width: '432px',
    boxSizing: 'border-box',
  },
  disabledButton: {
    // backgroundColor: '#F2F2F2',
    background: '#f00',
  },
  button: {
    background: '#fff',
    color: '#1B1B1B',
    padding: '14px',
    fontWeight: 'bold',
    fontSize: '16px',
    '& svg': {
      margin: '0 10px',
      width: '1.2em',
      height: '1.2em',
      color: '#545454',
    },
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    margin: 16,
  },
  disabled: {},
  bgHeader: {
    // background:'#F7F7F7'
  },
  maxWidth: {
    width: '100%',
  },
  innerWrapper: {
    width: `calc(100% - 40px)`,
    background: theme.palette.background.paper,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textSecondary: {
    margin: '16px 0',
    color: theme.palette.text.secondary,
  },
}));
