const en = {
  'firstName': 'First name',
  'lastName': 'Last name',
  'isCarOwner': 'Is car owner',
  'hasDrivingLicence': 'Has driving licence',
  'firstName.is.required': 'First name is required',
  'lastName.is.required': 'Last name is required',
  'driver.already.exist': 'Driver already exist!',
  'click.here.to.login': 'Login',
  'click.here.to.login2': 'Login',
  'driver.not.found': 'Driver not found',
  'you.must.register.first': 'Register',
  'you.must.register.first2': 'Register',
  'become.a.driver.vtc.yassir': 'Become a driver VTC Yassir',
  'being.a.yassir.partner.driver': 'Being a Yassir partner driver means managing your business with peace of mind and optimizing your income with the best VTC on the market.',
  'go.to.home': 'Back to home page',
  'i.have.a.valid.driving.license': 'I have a valid driving license',
  'i.own.a.vehicle': 'I own a vehicle',
  'conditions.utilisations1': 'By subscribing to YASSIR you accept the',
  'conditions.utilisations2': 'terms of use',
  'commenncez.des.maintenant': 'Drive with the partner more active in the market.',
  'commenncez.des.maintenant2': 'The most active partner on the market.',
  'Become.your.own.boss.can': 'Become your own boss and follow your balances live.',
  'Become.your.own.boss.dza': '',
  'Become.your.own.boss.mar': '',
  'Become.your.own.boss.tun': '',
  'Become.your.own.boss.sen': '',
  'Manage.your.schedule.can': 'Manage your schedule and work according to your personal and family needs.',
  'Manage.your.schedule.dza': '',
  'Manage.your.schedule.mar': '',
  'Manage.your.schedule.tun': '',
  'Manage.your.schedule.sen': '',
  'increase.your.income.can': 'Increase your income and make ends meet.',
  'the.phone.number.has.missing.digits': 'The phone number has missing digits',
  'internal.error.server': 'Oops an error occured please try again later!',
  'Phone.is.required': 'Phone is required',
  'header.logout': 'Logout',
  'home.welcome': 'Welcome to YASSIR',
  'home.signup': "Signup",
  'home.description-line1': 'Become a YASSIR partner and optimize your income',
  'home.description-line2': 'with the lowest commission on the market!',
  'home.login': 'Login',
  'not.supported.title': 'Contact Support',
  'not.supported.body': 'For more information regarding your registration request, please contact Yassir support.',
  'signin.phone-required': 'Please enter your phone number',
  'signin.code-required': 'Please enter the code',
  'signin.sign-in': 'Login',
  'signin.use-phone': 'Use your phone number to connect',
  'signin.verify-number': 'Validate your number',
  'signin.code-sent-text': 'A 6-digit code has been sent to your number',
  'signin.accept-conditions': 'By connecting to YASSIR you accept',
  'signin.use-terms': 'the terms of use.',
  'signin.code-sent': 'Code sent',
  'signin.send': 'Submit',
  "signin.confirm": "Confirm",
  'signin.invalid-code': 'Invalid or expired code',
  'signin.validated-code': 'Code has been validated',
  'signin.code-expires-in': 'Code expires in...',
  'signin.resend-code': 'Resend the code',
  'signin.accept': 'Accept',
  'signin.use-terms-privacy-policy': 'the terms of use / privacy policy',
  'title.document': 'Join YASSIR team',
  'are.you.already.registered': 'Congratulations, you have completed your registration',
  'text.congrat.one': 'Thank you for choosing Yassir, your account is now active.',
  'text.congrat.two': 'You can now take your first race by logging into the Yassir app.',
  'text.congrat.three': 'If you have not yet downloaded the application, we invite you to do so by clicking below.',
  'text.congrat.four': 'We wish you a pleasant experience!',
  'app.join-us': 'Join us !',
  'app.join-us-subtitle':
    'Register in two easy steps to become a YASSIR partner.',
  'app.sign-up-text':
    'In order to start driving on the Yassir platform, simply follow the steps in this form and provide the requested documents. {br} All the documents provided must first be reviewed, verified and validated by the Yassir team.{br} If all the documents provided are in order, you will be invited to take an online training course and must pass the assessment to be granted access to the platform. {br}When you upload the requested documents, make sure that all the information is clearly visible. Do not use flash.{br} You will be notified of the processing progress of your file',
  'app.general-info': 'Personal Information',
  'app.general-info-description': 'Add your personal information',
  'app.company-docs': 'Company documents',
  'app.add-docs-to-continue':
    'Add the documents below to continue your registration (Copies and scans are not accepted)',
  'app.vehicles-list': 'Vehicle List',
  'app.add-manage-vehicles': 'Add vehicle information and documents',
  'app.drivers-list': 'Personal documents',
  'app.add-manage-drivers': 'Add your personal documents',
  'app.bank': 'Bank details',
  'app.bank-description': 'Add your bank details',
  'registration.add-company-title': 'General information',
  'registration.add-company-sub-title': 'Name, First name...',
  'registration.edit-company-title': 'Company documents',
  'registration.edit-company-sub-title': 'Company name, Legal form...',
  'registration.add-company-vehicles-title':
    'Vehicle information and documents',
  'registration.add-company-vehicles-sub-title': 'Brand, Model...',
  'registration.add-company-driver-title': 'Personal information and documents',
  'registration.add-company-driver-sub-title': 'Name, First name....',
  'registration.file-being-processed': 'Your file is being processed',
  'registration.complete-profile': 'Complete your profile:',
  'registration.add-documents': 'Fill in your personal information',
  'registration.vehicles': 'Vehicles',
  'registration.drivers': 'Drivers',
  'registration.finalize-registration': 'Complete the registration',
  'registration.finalize-to-submit': 'Complete the form to submit.',
  'registration.contact-us': 'You can contact us at',
  'registration.contact-email': 'canadadrivers@yassir.com',
  'registration.more-than-5': 'Do you own more than 5 vehicles?',
  'registration.more-than-5-text':
    'Our team can manage the registration of your fleet and drivers if you have more than 5 vehicles.',
  'registration.i-have-more-than-5': 'I own a fleet of more than 5 vehicles',
  'registration.checking-this-end-registration':
    'By selecting this option the registration is completed at this stage.',
  'registration.save-&-finish': 'Save and end the registration',
  'registration.i-add-myself': 'I add my vehicles on my own',
  'registration.personal-information-title': 'Personal Information',
  'registration.personal-information-sub-title': 'Name, Last Name, email...',
  'registration.personal-documents-title': 'Personal Documents',
  'registration.rejected-documents': 'One or more documents have been rejected',
  'registration.accepted-documents': 'All documents have been accepted',
  'registration.pending-documents': 'Documents awaiting processing',
  'registration.personal-documents-sub-title': 'Personal Documents',
  'registration.vehicles-information-title': 'Vehicle information',
  'registration.vehicles-information-sub-title': 'Brand, Model...',
  'registration.submit-application': 'Submit your application',
  'registration.application-submitted-message-1':
    "Thank you for choosing to become one of YASSIR's partners and we confirm receipt of your application.",
  'registration.application-submitted-message-2':
    'Your application is now being processed. We will contact you by e-mail as soon as the information and documents provided have been validated.',
  'registration.training-access-message':
    'You will have access to the training once all your documents have been checked and validated.',
  'registration.choice-of-training': 'Training',
  'registration.training': 'Training',
  'registration.training-online-title': 'Online Training',
  'registration.training-online-start':
    'Your application was approuved, you can now start your online training.',
  'registration.training-sub-title': 'Take your training online',
  'registration.bank': 'Banking Details',
  'registration.bank-sub-title': 'Banking Details',
  'registration.docs-upload-note':
    'When you upload the requested documents, make sure that all the information is clearly visible. Do not use flash. You will be notified of the progress of processing your file',
  'registration.training-on-line': 'Take the training and test online',
  'registration.training-on-line-desc':
    'As soon as your file is approved, you can start your training.',
  'registration.training-at-office': 'Training at office',
  'registration.training-at-office-desc':
    'Pass the training and test at the office, you will receive an email specifying the date and time at which he should appear.',
  'registration.rejected-status':
    'Your documents have been rejected because they do not seem to match the requested documents',
  'registration.training-online-success': 'Training succeeded',
  'registration.training-online-success-message': 'Training succeeded',
  'registration.download-training-certificate': 'Download your certificate',
  'validation.required': 'Required',
  'validation.last-name-required': 'Name is required',
  'validation.first-name-required': 'First name is required',
  'validation.birth-date': 'Birth date is required',
  'validation.email-required': 'E-mail address is required',
  'validation.email-not-valid': 'E-mail address is not valid',
  'validation.email-not-match': 'Email does not match',
  'validation.confirm-email': 'Confirm the email',
  'validation.phone-required': 'Phone number is required',
  'validation.confirm-phone': 'Please confirm your phone number',
  'validation.phone-not-match': 'Téléphone ne correspond pas',
  'validation.company-name-required': 'The company name is required',
  'validation.id-required': 'Please add ID',
  'validation.subtype-required': 'Please select the identity document type',
  'validation.end-date-required': 'Add the validity date',
  'validation.inspection-date-required': 'Add the mechanical control date',
  'validation.vtc-recto-required': 'Please add the VTC card front',
  'validation.vtc-verso-required': 'Please add the VTC card back',
  'validation.driver-lic-recto-required':
    'Please add the driver’s license front',
  'validation.driver-lic-verso-required':
    'Please add the driver’s license back',
  'validation.legal-form-required': 'Legal form is required',
  'validation.city-required': 'City is required',
  'validation.siret-required': 'SIRET No is required',
  'validation.siret-length': 'SIRET No has to be 14 digits',
  'validation.iban-required': 'IBAN is required',
  'validation.kbis-insee': 'KBIS OR INSEE is required',
  'validation.subscription-certificate-required':
    'Certificate of enrollment in the VTC Registry is required',
  'validation.rc-pro-required':
    'Professional liability insurance (RC Pro) is required',
  'validation.rib-required': 'RIB is required',
  'validation.car-brand': 'Please select the vehicle brand',
  'validation.car-model': 'Please select the vehicle model',
  'validation.car-color': 'Please select the vehicle color',
  'validation.car-year': 'Please select the vehicle year',
  'validation.valid-number': 'Please enter a valid number',
  'validation.is-number': 'Please type a number',
  'validation.car-seats': 'Please select the number of seats',
  'validation.car-seats-min': 'The number of seats has to be at least 1',
  'validation.car-plate': 'Please select the vehicle registration plate',
  'validation.grey-card-required':
    'Please add the vehicle registration document',
  'validation.grey-card-front-required':
    'Please add the vehicle registration document front',
  'validation.grey-card-back-required':
    'Please add the vehicle registration document back',
  'validation.green-card-required': 'Please add the green card',
  'validation.insurance-required':
    'Please add the insurance for the paid transportation of persons',
  'validation.technical-control-required': 'Please add the technical control',
  'validation.macaron-required': 'Please add the sticker',
  'validation.car-picture-required': 'Please add a picture of the odometer ',
  'validation.lvc-thumbnail-required': 'Please add LVC thumbnail',
  'validation.bce':
    'Please add a Copy of BCE (Banque-Carrefour des Entreprises)',
  'validation.medical-visit-required': 'Please add the medical check-up',
  'validation.criminal-record-required': 'Please add the criminal record',
  'validation.driving-record-required': 'Please add the driving record',
  'validation.drivingRecord': 'Please add your driving record',
  'validation.profile-pic-required': 'Profile picture is required',
  'validation.agglomeration-required': 'Please select the agglomération ',
  'validation.licence-type-required': 'Please select the licence type',
  'validation.kilometers-required': 'Vehicle mileage is required',
  'validation.lic-number-required': "The driver's licence number is required",
  'validation.birth-date-required': 'Birth date is required',
  'validation.address-1-required': 'Address is required',
  'validation.postal-code-required': 'Zip Code is required',
  'validation.fuel-type': 'Fuel type is required',
  'validation.mechanical-control': 'Mechanical control is required',
  'valide.ext': 'The extension must be: png, jpg or pdf',
  'general-info.server-error': 'An error has occurred, please retry',
  'general-info.who-are-you': 'Who are you?',
  'general-info.choose-suitable-option': 'Please choose the appropriate option',
  'general-info.owner': 'Manager',
  'general-info.mandated-employee':
    'Authorized employee to register the company',
  'general-info.mandated-info': 'Authorized employee information',
  'general-info.last-name': 'Name',
  'general-info.first-name': 'First Name',
  'general-info.email': 'E-mail address',
  'general-info.email-confirmation': 'E-mail address confirmation',
  'general-info.phone-number': 'Phone number',
  'general-info.owner-info': "Manager's information",
  'general-info.personal-info': 'Personal information',
  'general-info.city': 'City',
  'general-info.subscribe': 'Save',
  'general-info.birth-date': 'Birth Date',
  'general-info.agglomeration': 'Agglomeration',
  'general-info.licence-type': 'Licence type',
  'general-info.class5-accepted': 'Class 5 licence is accepted',
  'general-info.licence-over-1year': 'Is your licence more than one year old?',
  'general-info.yes': 'Yes',
  'general-info.no': 'No',
  'general-info.driving-question':
    ' Are you currently driving with another ride hailing platform?',
  'general-info.taxi-question': 'Are you a Taxi?',
  'general-info.address': 'Address',
  'general-info.address2': 'Address line 2',
  'general-info.postal-code': 'Zip Code',
  'level.path': 'Town',
  'drivers.add-driver': 'Add a driver',
  'drivers.add-drivers-first': 'Add drivers first then save',
  'drivers.save-to-continue': 'this operation to continue',
  'drivers.modify': 'Modify',
  'drivers.delete': 'Delete',
  'drivers.driver-info': 'Add your personal information',
  'drivers.driver-docs': 'Documents relatives à ce chauffeur',
  'drivers.id': 'Identity card',
  'drivers.passport': 'Passport',
  'drivers.residence-permit': 'Residence permit',
  'drivers.server-error': 'An error has occurred, please retry',
  'drivers.personal-info': 'Personal information',
  'drivers.first-name': 'First name',
  'drivers.last-name': 'Name',
  'drivers.mail': 'E-mail address',
  'drivers.mail-confirmation': 'E-mail address confirmation',
  'drivers.phone': 'Phone number',
  'drivers.phone-confirmation': 'Phone number confirmation',
  'drivers.doc-type': 'Document type',
  'drivers.vtc-card': 'VTC Card',
  'drivers.only-accept-new-cards':
    'We only accept new laminated cards (receipts are not accepted).',
  'drivers.eu-driver-lic': "Upload your driver's license",
  'drivers.new-driver': 'Personal documents',
  'drivers.add-driver-info': 'Add your personal documents',
  'drivers.medical-visit': 'Medical check-up',
  'drivers.criminal-record': 'Upload your criminal record document',
  'drivers.criminal-record-note':
    'A background check with a Quebec police force is required to become an authorized driver',
  'drivers.driving-record': 'Upload driving record',
  'drivers.driving-record-note':
    'The driving record must be requested from the SAAQ The driving record of an authorized driver must not be the subject of more than three minor infractions that occurred during the last three years and must not be the subject of any major violation in the past three years.',
  'drivers.profile-pic': 'Upload your profile picture',
  'drivers.profile-pic-note':
    'In order to help your passenger members easily identify you and to improve security on the app, all authorized drivers must have a profile picture associated with their account. Make sure you are dressed neatly and professionally',
  'drivers.lic-number': "Driver's licence number",
  'drivers.licence-note':
    "You will need to be a holder of a class 5 driver's license (or higher) and have driving experience of 12 months or more (excluding the driving experience of the probationary license",
  'dropzone.doc-added': 'Document added',
  'dropzone.add-doc': 'Add the document',
  'dropzone.add-doc-page1': 'Add the first page of the document',
  'dropzone.add-doc-page2': 'Add the second page of the document',
  'dropzone.add-doc-page3': 'Add the third page of the document',
  'dropzone.add-doc-recto': 'Add the front document',
  'dropzone.add-doc-verso': 'Add the back document',
  'dropzone.saving-in-progress': 'Saving',
  'dropzone.modify': 'Modify',
  'dropzone.rejected': 'This document has been rejected',
  'dropzone.accepted': 'This document has been accepted',
  'done.download-yassir-driver-app':
    "Download YASSIR driver application",
  'done.thanks-for-submitting':
    'Thank you for taking the time to fill in the form',
  'done.application-under-review':
    'Your submission has been sent successfully. We will get back to you soon to activate your account.',
  'company.server-error': 'An error has occurred, please retry',
  'company.about': 'About the company',
  'company.name': 'Company Name',
  'company.legal-form': 'Legal form',
  'company.siret': 'SIRET No',
  'company.city': 'City',
  'company.kbis-insee': 'KBIS or INSEE',
  'company.subscription-certificate':
    'Certificate of enrollment in the VTC Registry',
  'company.legit-docs-only': 'We only accept official ministerial documents.',
  'company.rc-pro': 'Professional liability insurance (RC Pro)',
  'company.doc-requirement':
    'The document must include the mention "civil liability" relevant to your',
  'company.rib': 'Statement of Bank Identity (RIB)',
  'company.iban-or-rib': 'Please enter your IBAN and add a copy of your RIB',
  'company.submit': 'Save',
  'company.tva': 'TVA No',
  'company.bce-certificate': 'Copy of BCE (Banque-Carrefour des Entreprises)',
  'company.lvc-licence': 'LVC Licence',
  'cars.add-vehicle': 'Add a vehicle',
  'cars.add-vehicles-first': 'Add vehicles first than save',
  'cars.this-operation': 'this operation to continue',
  'cars.submit': 'Save',
  'cars.modify': 'Modify',
  'cars.delete': 'Delete',
  'cars.general-info': 'General Information',
  'cars.brand': 'Brand',
  'cars.model': 'Model',
  'cars.color': 'Color',
  'cars.year': 'Year',
  'cars.seats': 'Number of seats',
  'cars.plate': 'Vehicle registration plate',
  'cars.grey-card': 'License plate',
  'cars.grey-card-note': 'All information should be clearly visible',
  'cars.green-card': 'Green card',
  'cars.serive-available-onboard': 'Available services on the vehicle',
  'cars.check-available-services':
    'Please select the available services on the vehicle',
  'cars.pmr-accessibility': 'PMR Accessibility (person with reduced mobility)',
  'cars.child-seat': 'Child seat',
  'cars.usb-charger': 'micro-USB mobile device charger',
  'cars.wifi': 'WIFI',
  'cars.trp-insurance': 'Vehicle insurance',
  'cars.insurance-note':
    'The driver must be the primary or secondary driver on the insurance, make sure your name, vehicle identification number, insurance company and expiration date are visible.',
  'cars.technical-control': 'Technical Control',
  'cars.macaron': 'Sticker',
  'cars.picture': 'Odometer picture',
  'cars.picture-description':
    'The number of Kilometers traveled by your vehicle must be clearly visible.',
  'cars.picture-example': 'View a sample picture',
  'cars.server-error': 'An error has occurred, please retry',
  'cars.new': 'Vehicle information',
  'cars.add-vehicle-info': 'Add vehicle information',
  'cars.new-doc': 'Vehicle documents',
  'cars.add-vehicle-doc': 'Add vehicle documents',
  'cars.next': 'Next',
  'cars.grey-card-recto': 'Vehicle registration document',
  'cars.grey-card-vers': 'Vehicle registration document',
  'cars.lvc-thumbnail': 'Thumbnail LVC',
  'cars.kilometers': 'Kilometers',
  'cars.mechanical-control': 'Mechanical inspection of the vehicle',
  'cars.mechanical-control-note':
    'You must have your vehicle inspected by a certified mechanic at one of the garages affiliated with the SAAQ. Vehicles having traveled less than 80K km or less than 4 years old do not require a mechanical inspection.',
  'cars.fuel-type': 'Fuel type',
  'bank-details.financial-institute': 'Financial Institution',
  'bank-details.financial-institute-number': 'Financial Institution Number',
  'bank-details.transit-number': 'Transit-number',
  'bank-details.bank-account-number': 'Bank Account Number',
  'bank-details.tps-doc': 'TPS',
  'bank-details.tps-number': 'TPS Number',
  'bank-details.tvq-doc': 'TVQ',
  'bank-details.tvq-number': 'TVQ Number',
  'bank-details.subscribe': 'Submit',
  'training.start': 'Start the training',
  'training.content-title': 'Content',
  'training.next-course': 'Next Course',
  'training.previous-course': 'Previous Course',
  'training.validate-answer': 'Validate my andswer',
  'cancel.text': 'Cancel',
  'apply.text': 'Ok',
  "exceed.25.characters": "The first and last name must not exceed 25 characters",
  "min.length.names": "the first and last name must contain more than two characters"
};

export default en;
