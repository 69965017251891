import React, { ReactElement, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
};
type AppDataType = {
  user: User | undefined;
  setUser: (user: User) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

const UserProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [user, setUser] = useState({
    firstName: 'test',
    lastName: 'test',
    email: 'test',
  });

  return (
    <Context.Provider value={{ user, setUser }}>{children}</Context.Provider>
  );
};

export { UserProvider, Context as UserContext };
