import React, { ReactElement, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ChildrenType } from '../utils/types';
import { useCountries } from '../hooks/useAuth';
import { countryCodeConverterIso2ToIso3 } from '../utils/countryCodeConverter';
import YassirLoader from '../components/YassirLoader';

type AppDataType = {
  isTokenValid: boolean;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useAuthData = (): any => React.useContext(Context);

const AuthProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {

  const { data: countriesData, isFetching: isFetchingCountries } = useCountries();

  const [isTokenValid, setIsTokenValid] = useState(false);
  const [countries, setCountries] = useState(countriesData?.countries);
  const [selectedCountry, setSelectedCountry] = useState();
  const [defaultBled, setDefaultBled] = useState('');

  useEffect(() => {
    if(localStorage.getItem('token')) setIsTokenValid(true);
  }, [localStorage.getItem('token')])

  useEffect(() => {
    setCountries(countriesData?.countries);
  }, [countriesData?.countries])

  useEffect(() => {
    setDefaultBled(countriesData?.activeCountry)
  },[countriesData])

  useEffect(() => {
    if (countries && countries.length > 0) {
      const defaultCountry = countries?.filter(country => country.code === `${countryCodeConverterIso2ToIso3.get(countriesData?.activeCountry)}`.toUpperCase())[0]
      if (!selectedCountry && countries) setSelectedCountry(defaultCountry || countries.find(c => c.code === 'DZA'))
    }
  }, [countries, countriesData?.activeCountry, selectedCountry]);

  const value = React.useMemo(
    () => ({
      isTokenValid,
      setIsTokenValid,
      countries,
      selectedCountry,
      setSelectedCountry,
      defaultBled
    }),
    [
      isTokenValid,
      setIsTokenValid,
      countries,
      selectedCountry,
      setSelectedCountry,
      defaultBled
    ],
  );
  if (isFetchingCountries) {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} >
        <YassirLoader/>
      </div>
    );
  }
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { AuthProvider, Context as AuthContext };

