import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Image } from 'react-image-and-background-image-fade'
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Countdown, { CountdownApi } from "react-countdown";
import { useStyles } from './styles';
import { IntlContext } from '../../contexts/intlContext';
import Header from "../../components/Header-v2/Header";
import FormSignIn from "./FormSignIn";
import { useAuthData } from "../../contexts/authContext";
import { useConfigData } from '../../contexts/configContext';
import { useSubmit } from "../../hooks/useSubmit";
import { ONBOARDING_API } from "../../utils/constants";
import PinForm from "../Auth/PinForm";
import { useHeaderData } from "../../contexts/headerContext";
import BackgroundAfric from '../../assets/signIn_signUp_bg_afric.jpg';
import BackgroundMaghreb from '../../assets/signIn_signUp_bg_maghreb.png';
import BackgroundMaghrebMobile from '../../assets/signIn_signUp_bg_maghreb_mobile.png'; 
import BackgroundAfricMobile from '../../assets/signIn_signUp_bg_afric_mobile.png';
import Loader from '../../components/YassirLoader';
import { useCompData } from "../../contexts/compComtext";

const Auth: React.FC = (): ReactElement => {

  const scope = 'signin';
  const [stile, setStile] = useState({})
  const {setHeader, header} = useHeaderData()
  const { setComp, comp } = useCompData()
  const history = useHistory();
  const { isTokenForConfig, setIsTokenForConfig, config, isFetching} = useConfigData();
  const { setIsTokenValid, selectedCountry, defaultBled } = useAuthData();
  const [charge,setCharge] = useState(true);
  const { locale, messageLang } = React.useContext(IntlContext).locales;
  const [codeSent, setCodeSent] = useState(false);
  const [codeStatus, setCodeStatus] = useState(0)
  const [codeValidated, setCodeValidated] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false);
  const [countDownApi, setCountDownApi] = useState<CountdownApi>();
  const [errorCode, setErrorCode] = useState(false);
  const [autoStart, setAutoStart] = useState(true);
  const [phone, setPhone] = useState('');
  const [testImg,setTestImg] = useState('')
  const [bgImg, setBgImg] = useState('');
  const [timer, setTimer] = useState(0);
  const [heightScreen, setSetHeightScreen] = useState(`${window.innerHeight}px`)
  const refBck = useRef(document.createElement("div"))

    const classes = useStyles();
    const [blockedPhoneMessage, setBlockedPhoneMessage] = useState('');
    const setRef = (countdown: Countdown | null): void => {
      if (countdown) {
        setCountDownApi(countdown.getApi());
      }
    };
    const {
        isLoading: loadingRequestPin,
        isSuccess: successRequestPin,
        isError: errorRequestPin,
        data: codePinData,
        error: codePinError,
        mutate: mutateRequestPin,
      } = useSubmit({
        path: `${ONBOARDING_API}/v2/signIn`,
      });
    
      const {
        isLoading: loadingLogin,
        isSuccess: successLogin,
        isError: errorLogin,
        mutate: mutateLogin,
        data: authData,
        error: errorLoginData,
      } = useSubmit({
        path: `${ONBOARDING_API}/v2/checkpin`,
      });

    const onSubmit = (submitedData: any): void => {
      const phoneField = `${submitedData?.dialCode}${submitedData?.phone.replace(/\s/g, '')}`;
      setPhone(phoneField);
      mutateRequestPin({ ...submitedData, phone: phoneField });
    };
    
    const handleResendRequestPin = (): void => {
      setAutoStart(false);
      mutateRequestPin({ phone });
    };
    
    const handleConfirmPin = (submitedData: any): void => {
      const body = {
        phone,
        pin: submitedData.code,
      };
      mutateLogin(body);
    };
    
      // if (!data) {
      //   return <> ERROR : No Config Data </>;
      // }
    
    useEffect(() => {
      console.log('🚀 ~ useEffect ~ errorRequestPin', errorRequestPin);
      if (successRequestPin) {
        setCodeSent(true);
          setTimeout(() => {
          setValidatePhone(true)
        }, 500);
      };
    }, [successRequestPin, errorRequestPin]);
    
    useEffect(() => {
      setBlockedPhoneMessage("")
      if (successLogin) {
        setErrorCode(false)
        setCodeValidated(true)
        setIsTokenValid(true)
        setIsTokenForConfig(true)
        localStorage.setItem('token', authData?.data?.token)
      } else if (errorLogin) {
        setErrorCode(true)
        setIsTokenValid(false)
      }
    }, [authData, errorLogin, history, setIsTokenValid, successLogin])
    
    useEffect(() => {
      setBlockedPhoneMessage('')
      if(errorLoginData?.response?.status === 404){
        setBlockedPhoneMessage(JSON.parse(JSON.stringify(messageLang))['driver.not.found'])
        setCodeStatus(errorLoginData?.response?.status)
      }
    },[errorLoginData]);
    
    useEffect(() => {
      if(config){
        if(config?.groupSteps)
          history.push(`/${locale}/register`);
        else
          switch(config?.successMessageKey){
            case 'DISPLAY_ONBOARDING_SCREEN':
              history.push(`/${locale}/screen`);
            break;
            case 'NEED_TERMINATE_REGISTRATION':
              history.push(`/${locale}/register`);
            break;
            case 'DRIVER_EXISTS':
              history.push(`/${locale}/registred`);
            break;
            default:
              history.push(`/${locale}`);
            break;
          }
        }
    },[config])
    
    useEffect(() => {
        setBlockedPhoneMessage('')
        setCodeStatus(0)
        if(codePinError?.response?.status){
          setCodeStatus(codePinError?.response?.status)
          if(codePinError?.response?.status === 404)
            setBlockedPhoneMessage(JSON.parse(JSON.stringify(messageLang))['driver.not.found'])            
          else if(codePinError?.response?.data?.errorMessageKey === 'INVALID_PHONE_NUMBER')
            setBlockedPhoneMessage(JSON.parse(JSON.stringify(messageLang))['invalid.phone.number']) 
          else if(codePinError?.response?.status === 400){
            if(codePinError?.response?.data?.errorMessageML[locale]) 
              setBlockedPhoneMessage(codePinError?.response?.data?.errorMessageML[locale])      
          }  
        }      
      }, [codePinError, locale])

      useEffect(() => {    
        setTimer(Date.now() + 60000);
        if (countDownApi !== undefined) { countDownApi.start() };    
      }, [codePinData?.data?.errorMessageML, codePinError, countDownApi, locale, successRequestPin])
    
    const handleResize = (): void => {
        setStile({ ...stile, 
          height: `${window.innerHeight - header?.height}px`,
        });
        setSetHeightScreen(`${window.innerHeight - header?.height}px`);
    }

    useEffect(() => {        
       if(window.innerWidth > 500) window.addEventListener('resize', (handleResize))  
    },[])

    useEffect(() => {        
      handleResize()  
    },[window.innerHeight,header?.height])

    useEffect(() => { 
      if(selectedCountry?.code === 'SEN')   
        setBgImg(window.innerWidth < 600 ? BackgroundAfricMobile : BackgroundAfric) 
      else setBgImg(window.innerWidth < 600 ? BackgroundMaghrebMobile : BackgroundMaghreb)
    },[selectedCountry?.code,window.innerWidth,BackgroundAfricMobile,BackgroundAfric,BackgroundMaghrebMobile,BackgroundMaghreb])

    useEffect(() => {    
      setTestImg(bgImg)    
      setCharge(false)    
    },[bgImg])

    useEffect(() => {  
      setCharge(true) 
    },[testImg]);

    useEffect(() => {
      if(successRequestPin)
        setTimer(Date.now() + 60000)
    },[successRequestPin])

    const useStyles1 = makeStyles({
      haut: {
        height: window.innerHeight - header.height
      },
    });

    const refChange = useCallback(() => {
      setComp({ ...comp, refBck: refBck?.current })  
    } ,[setComp, refBck, header])
  
    useEffect(() => {    
      refChange();  
    }, [])

    const classes1 = useStyles1();

    return (<>
        {isTokenForConfig ? <Loader/> :        
        (<div style={stile} className={classes.background} ref={refBck}>
            <Header/>
            {validatePhone && (
              <>
              <div className={classes.effectBcg} style={{ height: `${window.innerHeight - header?.height}px` }} />
              <div className={classes.effectBcg2} style={{ height: `${window.innerHeight - header?.height}px` }} />
              <div className={classes.ctnBcg}>
                {charge && <Image style={{height: `${window.innerHeight}px`, position: 'absolute'}}
                  src={bgImg}
                  lazyLoad
                  width='100%'
                  height={`${window.innerHeight - header?.height}px`}                  
                />}
              </div>
              <Grid
              container
              spacing={0}
              alignContent="center"
              justifyContent="center"
              direction="column" className={classes1.haut}
              >
                <Grid item style={{position: 'relative', zIndex: 1}}>
                  <PinForm isLoading={loadingLogin} onSubmit={handleConfirmPin} codeValidated={codeValidated} errorCode={errorCode}/>
                </Grid>
                <Grid item style={{position: 'relative', zIndex: 1}}>
                  <div
                  style={{
                    margin: '20px auto 0px',
                    textAlign: 'center',
                    padding: '10px 0px 13px 0px',
                  }}
                  >                                    
                    <>
                      <Countdown
                        autoStart={autoStart}
                        ref={setRef}
                        date={timer}
                        renderer={(props) => {
                            if (props.completed) {
                              return <div aria-hidden="true" onClick={() => { handleResendRequestPin() }} onKeyDown={() => handleResendRequestPin()} style={{ 
                                color: "#1b1b1b", 
                                fontWeight: '500',                                
                                fontSize: '13px',
                                border: '1px solid',
                                padding: '13px',
                                background: 'white',
                                borderRadius: '5px',
                                margin: '0 auto',
                                width:'155px',
                                cursor: 'pointer' }}><FormattedMessage id={`${scope}.resend-code`} /></div>;
                            }
                            return <div style={{ color: 'grey',
                            fontSize: '13px',
                            border: '1px solid',
                            padding: '13px',
                            background: 'white',
                            borderRadius: '5px',
                            margin: '0 auto',
                            width:'155px',
                            cursor: 'pointer' }}><FormattedMessage id={`${scope}.code-expires-in`} /> {props.minutes}:{props.seconds}</div>;
                          }
                        }
                      />
                    </>                  
                  </div>
                </Grid>
              </Grid>
              </>
            )}
            {header?.height !== 0 && !validatePhone && <FormSignIn isLoading={loadingRequestPin} onSubmit={onSubmit} blockedPhoneMessage={blockedPhoneMessage} codeSent={codeSent} codeStatus={codeStatus} activeInput={false} charge={charge} stile={stile} bgImg={bgImg} heightScreen={heightScreen}/>}
        </div>)}
        </>
    );
};

export default Auth;