import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: '14px 16px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    margin:'0px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    margin: 0,
    color: theme.palette.text.primary,
  },
}));
