import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  loadingDiv: {
    left: '0 !important',
    top: '0 !important',
    backgroundColor: 'white !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 90000,
    width: '100% !important',
    height: '100% !important',
    position: 'fixed',
  },
  yassirLogo: {
    width: '300px',
  },
  loadingGif: {
    marginTop: '-50px',
  },
}));