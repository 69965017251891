import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../AlreadyRegistredPage/styles';
import { useConfigData } from '../../contexts/configContext';
import { useFooterData } from '../../contexts/footerContext';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { useScreenData } from '../../contexts/screenDataContext';
import FooterForScreen from '../../components/FooterForScreen';
import { IntlContext } from '../../contexts/intlContext';
import PENDING from '../../assets/icons/PendingDocs.png';
import check from '../../assets/icons/Check.png';
import Checklist from '../../assets/icons/Checklist.png';
import unCheck from '../../assets/icons/UnCheck.png';

const Screen: React.FC = (): ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const { config, isFetching } = useConfigData();
  const { screen, setScreen } = useScreenData();
  const [loading, setLoading] = useState(true)
  const { locale } = React.useContext(IntlContext).locales;
  const refBody = useRef(document.createElement("div"))
  const refHeader = useRef(document.createElement("div"))
  const refContent = useRef(document.createElement("div"))
  const {footer} = useFooterData();
  
  const title = new Map()
  const body = new Map()

  const [titre, setTitre] = useState('')
  const [content, setContent] = useState('')
  const [fixe, setFixe] = useState(false)
  const [sizeContent, setSizeContent] = useState(0);
  const [status, setStatus] = useState('')
  const [checkGoodScreen, setCheckGoodScreen] = useState('40px 20px');

  useEffect(() => {
    if(!localStorage.getItem('token')) history.push(`/${locale}`);
  },[localStorage.getItem('token')])

  useEffect(() => {
    setScreen(new Map())
    if(config){
      if(config?.groupSteps)
        history.push(`/${locale}/register`);
      else
        switch(config?.successMessageKey){
          case 'DISPLAY_ONBOARDING_SCREEN':
            setLoading(false);
          break;
          case 'NEED_TERMINATE_REGISTRATION':
            history.push(`/${locale}/register`);
          break;
          case 'DRIVER_EXISTS':
            history.push(`/${locale}/registred`);
          break;
          default:
            history.push(`/${locale}`);
          break;
        }
      }
  },[config]);

  useEffect(() => {
    if(config?.data?.screen?.title || config?.data?.data?.screen?.title){
      title.set(locale,config?.data?.screen?.title[locale]);
    }

    if(config?.data?.screen?.bodyHtml)
      body.set(locale,config?.data?.screen?.bodyHtml[locale]);
    else if(config?.data?.screen?.body)
      body.set(locale,config?.data?.screen?.body[locale]);
  },[config?.data?.screen,config?.data?.screen,body,title]);

  useEffect(() => {
    if(screen?.title){
        title.set(locale,screen?.title[locale]);
    }
  },[screen?.title,title,locale]);

  useEffect(() => {
    if(screen?.body){
      body.set(locale,screen?.body[locale]);
    }
  },[screen?.body,body,locale]);

  useEffect(() => {
    setTitre(title.get(locale))
    setContent(body.get(locale))
  },[title,body,locale]);  
  
  useEffect(() => {
    if(!localStorage.getItem('token'))
        history.push(`/${locale}`);
  },[localStorage.getItem('token')])

  useEffect(() => {
    switch(config?.data?.status){
      case 'PENDING_DOCS':
      case 'PROCESSING_IN_PROGRESS':
      case 'TRAINING_IN_PROGRESS':
      case 'SIGNED_CONTRACT':
      case 'IN_PROGRESS':
      case 'PENDING':
        setStatus(PENDING);
      break;
      case 'VALID_DOCS':
      case 'APPROVED':
      case 'ACCEPTED_POST_TRAINING':
        setStatus(check);
      break;
      case 'REJECTED':
      case 'REJECTED_POSTAPPROVAL':
      case 'REJECTED_POST_TRAINING':
      case 'INVALID_DOCS':
        setStatus(unCheck)
      break;
      default:
        setStatus(Checklist);
      break;
    }
  },[config])

  useEffect(() => {       
    const calcul = window.innerHeight - (footer?.height + refHeader?.current?.clientHeight)
    setSizeContent(refContent?.current?.clientHeight > calcul ? refContent?.current?.clientHeight : calcul) 
  },[titre,content,refHeader,footer,refContent])

  useEffect(() => {       
    setCheckGoodScreen(refContent?.current?.clientHeight < sizeContent ? '0px' : '40px 20px') 
    if(refContent?.current?.clientHeight < sizeContent) setFixe(true); else setFixe(false)   
  },[sizeContent,refContent]);

  return (
    <>
      <div>
            <div ref={refBody} style={{ border: '0px solid transparent'}}>              
                <div ref={refHeader} className={classes.bgHeader}>
                    <Header />
                </div>
                <div style={{display: 'flex', minHeight: sizeContent, padding: window.innerWidth < 500 ? '20px' : checkGoodScreen }}>
                  <div ref={refContent} className={classes.content}>
                    {loading ? <Loader/> : 
                      <>
                        <div>
                          <img src={status} alt=''/>
                        </div>
                        {window.innerWidth > 500 ? 
                          <h1 className={classes.styleScreenTitle}>{(!isFetching) && String(titre)}</h1> : 
                          <h2 className={classes.styleScreenTitle}>{(!isFetching) && titre}</h2>}
                        {(!isFetching) && <h4 style={{marginTop: '-10px'}}className={classes.body} dangerouslySetInnerHTML={{__html: content}}/>}
                      </>
                      }
                  </div>  
                </div>   
            </div>
            
          {fixe ? <div style={{
              bottom: 0, 
              width: '100%',
              position: 'fixed',
              border: '0px solid transparent'}}>
              <FooterForScreen/>
          </div> :
          <div style={{
            bottom: 0, 
            width: '100%',
            position: 'relative',
            border: '0px solid transparent'}}>
            <FooterForScreen/>
          </div>}
        </div>
    </>);
};

export default Screen;