/* eslint-disable react/destructuring-assignment */
import React, { ReactElement } from 'react';
import GroupFields from '../GroupFields/GroupFields';

export const StepContent = (
  groupFields: Record<string, any>[],
  getCanEditFields: any,
): ReactElement => (
  <>
    {React.Children.toArray(
      groupFields.map((groupField: any) => (
        <>
          <GroupFields
            title={groupField.label}
            subtitle={groupField.description}
            inputs={groupField.fields}
            canEditFields={getCanEditFields()}
          />
        </>
      )),
    )}
  </>
);
