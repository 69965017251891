const fr = {
  '67.years.old.retired.dza': '67 ans, retraité.',
  '67.years.old.retired.mar': '67 ans, retraité.',
  '67.years.old.retired.tun': '67 ans, retraité.',
  '67.years.old.retired.sen': '40 ans, salariée.',
  '67.years.old.retired.ben': '40 ans, salariée.',
  '67.years.old.retired.tgo': '40 ans, salariée.',
  '67.years.old.retired.can': '67 ans, retraité.',
  '38.ans.employe.dza': '38 ans, employé.',
  '38.ans.employe.mar': '38 ans, employé.',
  '38.ans.employe.tun': '38 ans, employé.',
  '38.ans.employe.sen': '',
  '38.ans.employe.ben': '',
  '38.ans.employe.tgo': '',
  '38.ans.employe.can': '38 ans, employé.',
  '25.ans.etudiant.dza': '25 ans, étudiant.',
  '25.ans.etudiant.mar': '25 ans, étudiant.',
  '25.ans.etudiant.tun': '25 ans, étudiant.',
  '25.ans.etudiant.sen': '25 ans, jeune actif.',
  '25.ans.etudiant.ben': '25 ans, jeune actif.',
  '25.ans.etudiant.tgo': '25 ans, jeune actif.',
  '25.ans.etudiant.can': '25 ans, étudiant.',
  '2nd.year.student.dza': '“Étudiant en 2ème année à la fac de droit, j\'ai quotidiennement besoin d’argent pour mon argent de poche et mes frais d\'études.',
  '2nd.year.student.mar': '“Étudiant en 2ème année à la fac de droit, j\'ai quotidiennement besoin d’argent pour mon argent de poche et mes frais d\'études.',
  '2nd.year.student.tun': '“Étudiant en 2ème année à la fac de droit, j\'ai quotidiennement besoin d’argent pour mon argent de poche et mes frais d\'études.',
  '2nd.year.student.sen': '“N’ayant pas encore trouvé de travail après mes études, il me fallait une rentrée d’argent pour mes dépenses quotidiennes. J’ai donc rejoint Yassir en tant que chauffeur partenaire en utilisant la voiture de mon père. Maintenant j’arrive à subvenir à mes besoins et à épargner un peu.“',
  '2nd.year.student.ben': '“N’ayant pas encore trouvé de travail après mes études, il me fallait une rentrée d’argent pour mes dépenses quotidiennes. J’ai donc rejoint Yassir en tant que chauffeur partenaire en utilisant la voiture de mon père. Maintenant j’arrive à subvenir à mes besoins et à épargner un peu.“',
  '2nd.year.student.tgo': '“N’ayant pas encore trouvé de travail après mes études, il me fallait une rentrée d’argent pour mes dépenses quotidiennes. J’ai donc rejoint Yassir en tant que chauffeur partenaire en utilisant la voiture de mon père. Maintenant j’arrive à subvenir à mes besoins et à épargner un peu.“',
  '2nd.year.student.can': '“Étudiant en 2ème année à la fac de droit, j\'ai quotidiennement besoin d’argent pour mon argent de poche et mes frais d\'études.',
  'are.you.already.registered': 'Bravo, vous avez terminé votre inscription',
  'text.congrat.one': 'Merci d’avoir choisi Yassir, votre compte est désormais actif.',
  'text.congrat.two': 'Vous pouvez dès maintenant prendre votre première course en vous connectant sur l’application Yassir.',
  'text.congrat.three': 'Si vous n’avez pas encore téléchargé l’application, nous vous invitons à le faire en cliquand ci-dessous.',
  'text.congrat.four': 'Nous vous souhaitons une agréable expérience !',
  'after.my.retirement.dza': '"Après ma retraite, je voulais toujours être actif, j’ai commencé à chercher quoi faire et c’est là que j’ai découvert Yassir. Cela fait maintenant quelques <span id="textUpdate">années</span> déjà que cela m’aide à m’occuper en gérant mon propre temps et surtout arrondir mes fins de mois tout en profitant de ma retraite."',
  'after.my.retirement.mar': '"Après ma retraite, je voulais toujours être actif, j’ai commencé à chercher quoi faire et c’est là que j’ai découvert Yassir. Cela fait maintenant quelques <span id="textUpdate">années</span> déjà que cela m’aide à m’occuper en gérant mon propre temps et surtout arrondir mes fins de mois tout en profitant de ma retraite."',
  'after.my.retirement.tun': '"Après ma retraite, je voulais toujours être actif, j’ai commencé à chercher quoi faire et c’est là que j’ai découvert Yassir. Cela fait maintenant quelques <span id="textUpdate">années</span> déjà que cela m’aide à m’occuper en gérant mon propre temps et surtout arrondir mes fins de mois tout en profitant de ma retraite."',
  'after.my.retirement.sen': '"Au lieu de laisser ma voiture au parking à mes horaires de bureau, je l’ai inscrite avec un chauffeur chez Yassir et cela me fait un bon revenu supplémentaire par mois."',
  'after.my.retirement.ben': '"Au lieu de laisser ma voiture au parking à mes horaires de bureau, je l’ai inscrite avec un chauffeur chez Yassir et cela me fait un bon revenu supplémentaire par mois."',
  'after.my.retirement.tgo': '"Au lieu de laisser ma voiture au parking à mes horaires de bureau, je l’ai inscrite avec un chauffeur chez Yassir et cela me fait un bon revenu supplémentaire par mois."',
  'after.my.retirement.can': '"Après ma retraite, je voulais toujours être actif, j’ai commencé à chercher quoi faire et c’est là que j’ai découvert Yassir. Cela fait maintenant quelques <span id="textUpdate">années</span> déjà que cela m’aide à m’occuper en gérant mon propre temps et surtout arrondir mes fins de mois tout en profitant de ma retraite."',
  'are.there.races.to.other.wilayas': 'Y a-t-il des courses vers d’autres wilayas?',
  'i.heard.about.yassir.dza': 'J\'ai entendu parler de Yassir sur les réseaux sociaux, qu’ils cherchaient des intervenants (chauffeurs).',
  'i.heard.about.yassir.mar': 'J\'ai entendu parler de Yassir sur les réseaux sociaux, qu’ils cherchaient des chauffeurs.',
  'i.heard.about.yassir.tun': 'J\'ai entendu parler de Yassir sur les réseaux sociaux, qu’ils cherchaient des chauffeurs.',
  'i.heard.about.yassir.sen': '',
  'i.heard.about.yassir.ben': '',
  'i.heard.about.yassir.tgo': '',
  'i.heard.about.yassir.can': 'J\'ai entendu parler de Yassir sur les réseaux sociaux, qu’ils cherchaient des chauffeurs.',
  'i.was.very.happy.dza': 'J\'étais très content de savoir qu’ils acceptent les étudiants, j’étais surpris de trouver qu\'il était possible de travailler avec le véhicule de mon père sous procuration. Depuis, dès que je termine mes cours, j\'allume mon application, fais quelques courses et gagne de l\'argent.“',
  'i.was.very.happy.mar': 'J\'étais très content de savoir qu’ils acceptent les étudiants, j’étais surpris de trouver qu\'il était possible de travailler avec le véhicule de mon père sous procuration. Depuis, dès que je termine mes cours, j\'allume mon application, fais quelques courses et gagne de l\'argent.“',
  'i.was.very.happy.tun': 'J\'étais très content de savoir qu’ils acceptent les étudiants, j’étais surpris de trouver qu\'il était possible de travailler avec le véhicule de mon père sous procuration. Depuis, dès que je termine mes cours, j\'allume mon application, fais quelques courses et gagne de l\'argent.“',
  'i.was.very.happy.sen': '',
  'i.was.very.happy.ben': '',
  'i.was.very.happy.tgo': '',
  'i.was.very.happy.can': 'J\'étais très content de savoir qu’ils acceptent les étudiants, j’étais surpris de trouver qu\'il était possible de travailler avec le véhicule de mon père sous procuration. Depuis, dès que je termine mes cours, j\'allume mon application, fais quelques courses et gagne de l\'argent.“',
  'as.an.employee.dza': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que intervenant (chauffeur) Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.mar': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.tun': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.sen': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.ben': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.tgo': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'as.an.employee.can': '<span>&quot;</span>En tant qu’employé, marié avec 3 enfants, mon salaire ne me suffit plus, un ami à moi m’a conseillé de travailler en tant que chauffeur Yassir, maintenant, quand je sors du travail, j’allume mon application, et j’ai déjà une rentrée d’argent quotidienne.<span>&quot;</span>',
  'choose.a.schedule.dza': 'Choisissez un emploi du temps et adaptez le à votre vie familiale et sociale, mais vous pouvez travailler 24h/24 7j/7, car plus vous travaillez, plus vous augmentez vos gains en tant que intervenant (chauffeur).',
  'choose.a.schedule.mar': 'Choisissez un emploi du temps et adaptez le à votre vie familiale et sociale, mais vous pouvez travailler 24h/24 7j/7, car plus vous travaillez, plus vous augmentez vos gains en tant que chauffeur.',
  'choose.a.schedule.tun': 'Choisissez un emploi du temps et adaptez le à votre vie familiale et sociale, mais vous pouvez travailler 24h/24 7j/7, car plus vous travaillez, plus vous augmentez vos gains en tant que chauffeur.',
  'choose.a.schedule.sen': 'Vous pouvez travailler à n’importe quel moment de la journée mais attention, plus vous avez de courses, plus vous gagnez.',
  'choose.a.schedule.ben': 'Vous pouvez travailler à n’importe quel moment de la journée mais attention, plus vous avez de courses, plus vous gagnez.',
  'choose.a.schedule.tgo': 'Vous pouvez travailler à n’importe quel moment de la journée mais attention, plus vous avez de courses, plus vous gagnez.',
  'choose.a.schedule.can': 'Choisissez un emploi du temps et adaptez le à votre vie familiale et sociale, mais vous pouvez travailler 24h/24 7j/7, car plus vous travaillez, plus vous augmentez vos gains en tant que chauffeur.',
  'driven.with.Yassir.since': '- Conduit avec Yassir depuis',
  'double.airbag': 'Double airbag (côté passager et côté conducteur) – de préférence.',
  'Do.i.have.to.show.up.with.my.vehicle': 'Dois-je me présenter avec mon véhicule?',
  'firstName': 'Prénom',
  'lastName': 'Nom',
  'isCarOwner': 'Propriétaire de la voiture',
  'hasDrivingLicence': 'Permis de conduire',
  'firstName.is.required': 'Le prénom est requis',
  'lastName.is.required': 'Le nom de famille est requis',
  'driver.already.exist': 'Ce chauffeur existe déjà!',
  'click.here.to.login': 'Me connecter',
  'click.here.to.login2': 'Me connecter',
  'driver.not.found': 'Chauffeur introuvable!',
  'become.a.driver.vtc.yassir.dza': 'Devenez intervenant (Chauffeur) auprès de la plateforme numérique de YASSIR',
  'become.a.driver.vtc.yassir.mar': 'Devenez Chauffeur auprès de la plateforme numérique de YASSIR',
  'become.a.driver.vtc.yassir.tun': 'Devenez Chauffeur auprès de la plateforme numérique de YASSIR',
  'become.a.driver.vtc.yassir.can': 'Devenez Chauffeur auprès de la plateforme numérique de YASSIR',
  'become.a.driver.vtc.yassir.sen': 'Vous avez une voiture ? Devenez chauffeur partenaire Yassir !',
  'become.a.driver.vtc.yassir.ben': 'Vous avez une voiture ? Devenez chauffeur partenaire Yassir !',
  'become.a.driver.vtc.yassir.tgo': 'Vous avez une voiture ? Devenez chauffeur partenaire Yassir !',
  'being.a.yassir.partner.driver.dza': 'Être intervenant (chauffeur) Yassir, c’est gérer son activité en toute tranquillité et augmenter ses revenus avec l’application N°1 sur le marché.',
  'being.a.yassir.partner.driver.mar': 'Être chauffeur Yassir, c’est gérer son activité en toute tranquillité et augmenter ses revenus avec l’application N°1 sur le marché.',
  'being.a.yassir.partner.driver.tun': 'Être chauffeur Yassir, c’est gérer son activité en toute tranquillité et augmenter ses revenus avec l’application N°1 sur le marché.',
  'being.a.yassir.partner.driver.can': 'Être chauffeur Yassir, c’est gérer son activité en toute tranquillité et augmenter ses revenus avec l’application N°1 sur le marché.',
  'being.a.yassir.partner.driver.sen': 'Être chauffeur Yassir, c’est bien gérer son activité et augmenter son revenu avec la meilleure appli.',
  'being.a.yassir.partner.driver.ben': 'Être chauffeur Yassir, c’est bien gérer son activité et augmenter son revenu avec la meilleure appli.',
  'being.a.yassir.partner.driver.tgo': 'Être chauffeur Yassir, c’est bien gérer son activité et augmenter son revenu avec la meilleure appli.',
  'is.required': ' est requis(e)',
  'i.have.a.valid.driving.license': 'J’ai un permis de conduire de plus de deux (2) ans et en cours de validité',
  'i.own.a.vehicle': 'Je possède un véhicule',
  'conditions.utilisations1': 'En vous inscrivant à YASSIR vous acceptez les',
  'conditions.utilisations2': 'conditions d\'utilisations',
  'commenncez.des.maintenant2': 'Le partenaire le plus actif du marché.',
  'commenncez.des.maintenant.dza': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.mar': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.tun': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.can': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.sen': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.ben': 'Inscrivez-vous maintenant',
  'commenncez.des.maintenant.tgo': 'Inscrivez-vous maintenant',
  'bad.request': 'Ré-essayer ultérieurement',
  'Become.your.own.boss.can': 'Devenez votre propre patron et suivez vos soldes en direct.',
  'Become.your.own.boss.dza': 'Certains de nos intervenants (chauffeurs) gagnent jusqu’à <br/> 150.000 DZD par mois!',
  'Become.your.own.boss.mar': '0% commission pour votre premier mois en tant que chauffeur Yassir.',
  'Become.your.own.boss.tun': '0% commission pour votre premier mois en tant que chauffeur Yassir.',
  'Become.your.own.boss.sen': 'Nos chauffeurs partenaires perçoivent jusqu’à<br/> 1.500.000 FCFA/mois.',
  'Become.your.own.boss.ben': '0% commission pour votre premier mois en tant que chauffeur Yassir.',
  'Become.your.own.boss.tgo': '0% commission pour votre premier mois en tant que chauffeur Yassir.',
  'Manage.your.schedule.can': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'Manage.your.schedule.dza': 'Assurance pour les véhicules de nos intervenants (chauffeurs).',
  'Manage.your.schedule.mar': 'Devenez votre propre patron et suivez vos soldes en direct.',
  'Manage.your.schedule.tun': 'Devenez votre propre patron et suivez vos soldes en direct.',
  'Manage.your.schedule.sen': 'Devenez votre propre patron, percevez votre revenu en temps réel.',
  'Manage.your.schedule.ben': 'Devenez votre propre patron et suivez vos soldes en direct.',
  'Manage.your.schedule.tgo': 'Devenez votre propre patron et suivez vos soldes en direct.',
  'increase.your.income.can': 'Augmentez vos revenus et arrondissez vos fins de mois.',
  'increase.your.income.dza': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'increase.your.income.mar': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'increase.your.income.tun': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'increase.your.income.sen': 'Travaillez à votre rythme selon vos disponibilités.',
  'increase.your.income.ben': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'increase.your.income.tgo': 'Gérez votre emploi du temps et votre travail selon vos besoins personnels et familiaux.',
  'you.must.register.first': 'M\'inscrire',
  'you.must.register.first2': 'M\'inscrire',
  'the.phone.number.has.missing.digits': 'Le numéro de téléphone comporte des chiffres manquants',
  'internal.error.server': 'Oups une erreur est survenue veuillez réessayer plus tard!',
  'Phone.is.required': 'Le numéro de téléphone est requis',
  'header.logout': 'Se déconnecter',
  'how.can.i.contact.you': 'Comment je peux vous contacter?',
  'home.welcome': 'Bienvenue sur YASSIR',
  'home.signup': "Inscription",
  'home.description-line1': 'Devenez partenaire YASSIR et optimisez vos revenus',
  'home.description-line2': 'avec la commission la plus basse du marché!',
  'home.login': 'Se connecter',
  'how.to.become.a.partner.driver.at.yassir.dza': 'Comment devenir intervenant (chauffeur) chez Yassir?',
  'how.to.become.a.partner.driver.at.yassir.mar': 'Comment devenir chauffeur chez Yassir?',
  'how.to.become.a.partner.driver.at.yassir.tun': 'Comment devenir chauffeur chez Yassir?',
  'how.to.become.a.partner.driver.at.yassir.can': 'Comment devenir chauffeur chez Yassir?',
  'how.to.become.a.partner.driver.at.yassir.sen': 'Comment devenir chauffeur partenaire chez Yassir',
  'how.to.become.a.partner.driver.at.yassir.ben': 'Comment devenir chauffeur partenaire chez Yassir',
  'how.to.become.a.partner.driver.at.yassir.tgo': 'Comment devenir chauffeur partenaire chez Yassir',
  'have.a.4G.Smartphone': 'Disposer d’un Smartphone',
  'invalid.phone.number': 'Numéro de téléphone invalide',
  'License.of.2.years.or.more.and.be.25.years.or.more': 'Permis de conduire de plus de deux ans',
  'level.path': 'Ville',
  'formation.trad': 'Formation',
  'Mohamed.G.dza': 'Mohamed G',
  'Mohamed.G.mar': 'Mohamed G',
  'Mohamed.G.tun': 'Mohamed G',
  'Mohamed.G.can': 'Mohamed G',
  'Mohamed.G.sen': 'Gorgui M',
  'Mohamed.G.ben': 'Gorgui M',
  'Mohamed.G.tgo': 'Gorgui M',
  'none.trad': 'Aucun',
  'not.supported.title': 'Contactez le support',
  'not.supported.body': 'Pour plus d’information concernant votre demande d’inscription, veuillez contacter le support Yassir.',
  'signin.phone-required': 'Veuillez entrer votre numéro de téléphone',
  'signin.code-required': 'Veuillez entrer le code',
  'signin.sign-in': 'Connectez-vous',
  'signin.use-phone': 'Utilisez votre numéro de téléphone pour vous connecter',
  'signin.verify-number': 'Vérifiez votre numéro',
  'signin.code-sent-text': 'Un code de 6 chiffres a été envoyé à votre numéro',
  'signin.accept-conditions': 'En vous connectant à YASSIR vous acceptez',
  'signin.use-terms': "les conditions d'utilisations",
  'signin.code-sent': 'Code envoyé',
  'signin.send': 'S\'inscrire',
  "signin.confirm": "Confirmer",
  'signin.invalid-code': 'Code invalide ou expiré',
  'signin.validated-code': 'Code validé',
  'signin.code-expires-in': 'Code expire dans',
  'signin.resend-code': 'Renvoyer le code',
  'signin.accept': 'Accepter',
  'title.document': 'Rejoignez l\'équipe YASSIR',
  'signin.use-terms-privacy-policy': 'les CGU / politique de confidentialité',
  'app.join-us': 'Rejoignez-nous!',
  'app.join-us-subtitle':
    'Inscrivez-vous en deux étapes simples pour \n devenir partenaire YASSIR.',
  'app.sign-up-text':
    'Afin de commencer à conduire sur la plateforme Yassir , il suffit de suivre les étapes de ce formulaire et fournir les documents demandés.{br}L’ensemble des documents fournis devra d’abord être revu, vérifié et validé par les équipes Yassir en charge des intervenants (chauffeurs).{br}Si l’ensemble des documents fournis sont en règle avec les lois en vigueur, le futur Partenaire se verra inviter à passer une formation en ligne et devra réussir l’évaluation pour se voir accorder l’accès à la plateforme.{br}Lorsque vous téléversez les documents demandés ,soyez sûrs que toutes les informations soient bien visibles. N’utilisez pas de flash.{br}Vous serez notifiés de l’avancement du traitement de votre dossier',
  'app.general-info': 'Informations Personnelles',
  'app.general-info-description': 'Ajouter vos informations personnelles',
  'app.company-docs': "Documents de l'entreprise",
  'app.add-docs-to-continue':
    "Ajouter les documents ci-dessous pour continuer l'inscription (Les photocopies et les scans ne sont pas acceptés)",
  'app.vehicles-list': 'Liste des véhicules',
  'app.add-manage-vehicles':
    'Ajouter les informations et documents relatives au véhicule',
  'app.drivers-list': 'Documents personnels',
  'app.add-manage-drivers': 'Ajouter vos documents personelles',
  'app.bank': 'Coordonnées bancaires',
  'app.bank-description': 'Ajoutez vos coordonnées bancaires',
  'now.you.can.activate.dza': 'Vous pouvez maintenant activer votre application, faire des courses et gagner de l\'argent.',
  'now.you.can.activate.mar': 'Vous pouvez maintenant activer votre application, faire des courses et gagner de l\'argent.',
  'now.you.can.activate.tun': 'Vous pouvez maintenant activer votre application, faire des courses et gagner de l\'argent.',
  'now.you.can.activate.can': 'Vous pouvez maintenant activer votre application, faire des courses et gagner de l\'argent.',
  'now.you.can.activate.sen': 'Vous pouvez activer votre application, faire des courses et gagner de l’argent.',
  'now.you.can.activate.ben': 'Vous pouvez activer votre application, faire des courses et gagner de l’argent.',
  'now.you.can.activate.tgo': 'Vous pouvez activer votre application, faire des courses et gagner de l’argent.',
  'registration.trad': 'Inscription',
  'register.on.our.webpage.dza': 'Inscrivez-vous sur notre page web et remplissez toutes les informations nécessaires.',
  'register.on.our.webpage.mar': 'Inscrivez-vous sur notre page web et remplissez toutes les informations nécessaires.',
  'register.on.our.webpage.tun': 'Inscrivez-vous sur notre page web et remplissez toutes les informations nécessaires.',
  'register.on.our.webpage.sen': 'Inscrivez-vous en remplissant le formulaire ci-dessus.',
  'register.on.our.webpage.ben': 'Inscrivez-vous en remplissant le formulaire ci-dessus.',
  'register.on.our.webpage.tgo': 'Inscrivez-vous en remplissant le formulaire ci-dessus.',
  'register.on.our.webpage.can': 'Inscrivez-vous sur notre page web et remplissez toutes les informations nécessaires.',
  'registration.add-company-title': 'Informations générales',
  'registration.add-company-sub-title': 'Nom, prénom, email...',
  'registration.edit-company-title': "Documents de l'entreprise",
  'registration.edit-company-sub-title':
    "Nom de l'enterprise, form juridique...",
  'registration.add-company-vehicles-title':
    'Informations et documents du véhicule',
  'registration.add-company-vehicles-sub-title': 'Marque, Modèle...',
  'registration.add-company-driver-title':
    'Informations et documents personnels',
  'registration.add-company-driver-sub-title': 'Nom, prénom, email...',
  'registration.file-being-processed':
    'Votre dossier est en cours de traitement',
  'registration.complete-profile': 'Complétez votre profil',
  'registration.add-documents': 'Remplir vos informations personnelles',
  'registration.vehicles': 'véhicules',
  'registration.drivers': 'chauffeurs',
  'registration.finalize-registration': "Finaliser l'inscription",
  'registration.finalize-to-submit':
    'Finalisez le formulaire afin de pouvoir le soumettre.',
  'registration.contact-us': 'Vous pouvez nous contacter',
  'registration.contact-email': 'canadadrivers@yassir.com',
  'registration.more-than-5': 'Possédez-vous plus de 5 véhicules?',
  'registration.more-than-5-text':
    'Notre équipe peut prendre en charge l’enregistrement de votre flotte et de vos chauffeurs si vous possédez plus de 5 véhicules.',
  'registration.i-have-more-than-5':
    'Je possède une flotte de plus de 5 véhicules',
  'registration.checking-this-end-registration':
    'En cochant cette option l’inscription est finalisée à cette étape',
  'registration.save-&-finish': 'Enregistrer et finir l’inscription',
  'registration.i-add-myself': 'J’ajoute mes véhicules moi-même',
  'registration.choice-of-training': 'Formation',
  'registration.personal-information-title': 'Informations personnelles',
  'registration.personal-information-sub-title': 'Nom, prénom, email...',
  'registration.personal-documents-title': 'Documents Personnels',
  'registration.personal-documents-sub-title': 'Documents Personnels',
  'registration.vehicles-information-title': 'Information Du Véhicule',
  'registration.vehicles-information-sub-title': 'Marque, Modèle...',
  'registration.bank': 'Informations Bancaires',
  'registration.bank-sub-title': 'Informations Bancaires',
  'registration.rejected-documents':
    'Un ou plusieurs documents ont été rejetés',
  'registration.accepted-documents': 'Tous les documents ont été validés',
  'registration.pending-documents': 'Documents en attente d’être traités',
  'registration.docs-upload-note':
    'Lorsque vous téléversez les documents demandés ,soyez sûrs que toutes les informations soient bien visibles. N’utilisez pas de flash. Vous serez notifiés de l’avancement du traitement de votre dossier.',
  'registration.submit-application': 'Soumettre votre candidature',
  'registration.application-submitted-message-1':
    'Nous vous remercions d’avoir choisi de devenir l’un des partenaires de YASSIR et vous confirmons la réception de votre candidature.',
  'registration.application-submitted-message-2':
    'Votre demande est désormais en cours de traitement. Nous vous contacterons par e-mail dès la validation des informations et documents fournis.',
  'registration.training-access-message':
    'Vous aurez accès à la formation une fois que tous vos documents sont vérifiés et validés.',
  'registration.training': 'Formation',
  'registration.training-online-title': 'Formation en ligne',
  'registration.training-online-success': 'Formation réussite',
  'registration.training-online-success-message':
    'Vous avez réussi votre formation yassir',
  'registration.training-online-start':
    'Votre dossier a été approuvé, vous pouvez maintenant commencer votre formation.',
  'registration.training-sub-title': 'Passer votre formation en ligne',
  'registration.training-on-line': 'Passez la formation et test en ligne',
  'registration.training-on-line-desc':
    'Dés que votre dossier sera approuvé, vous pourrez commencer votre formation.',
  'registration.training-at-office': 'Formation au bureau',
  'registration.training-at-office-desc':
    'Passer la formation et test au bureau, vous recevrez un mail spécifiant la date et l’heure à laquelle il devra se présenter.',
  'registration.download-training-certificate': 'Télécharger le certificat',
  'registration.rejected-status':
    "Vos documents ont été rejetés puisqu'ils ne semblent pas correspondre aux documents demandés",
  'validation.trad': 'Validation',
  'validation.required': 'Requis',
  'validation.last-name-required': 'Le nom est requis',
  'validation.first-name-required': 'Le prénom est requis',
  'validation.birth-date': 'La date de naissance est requise',
  'validation.email-required': 'Email est requis',
  'validation.email-not-valid': "Email n'est pas valide",
  'validation.email-not-match': 'Email ne correspond pas',
  'validation.confirm-email': "Confirmer l'email",
  'validation.phone-required': 'Téléphone est requis',
  'validation.confirm-phone': 'Veuillez confirmer le numéro de téléphone',
  'validation.phone-not-match': 'Téléphone ne correspond pas',
  'validation.company-name-required': "Le nom de l'entreprise est",
  'validation.id-required': "Veuillez ajouter la pièce d'identité",
  'validation.subtype-required':
    "Veuillez choisir le type de la pièce d'identité",
  'validation.end-date-required': "Veuillez ajouter la date d'expiration",
  'validation.inspection-date-required':
    "Veuillez ajouter la date de l'inspection mécanique",
  'validation.vtc-recto-required': 'Veuillez ajouter la carte VTC recto',
  'validation.vtc-verso-required': 'Veuillez ajouter la carte VTC verso',
  'validation.driver-lic-recto-required':
    'Veuillez ajouter le permis de conduire recto',
  'validation.driver-lic-verso-required':
    'Veuillez ajouter le permis de conduire verso',
  'validation.legal-form-required': 'Form juridique est requis',
  'validation.city-required': 'La ville est requis',
  'validation.siret-required': 'N° siret est requis',
  'validation.siret-length': 'N° siret doit avoir 14 chiffres',
  'validation.iban-required': 'IBAN est requis',
  'validation.kbis-insee': 'kbis ou insee est requis',
  'validation.subscription-certificate-required':
    "Certificat d'inscription est requise",
  'validation.rc-pro-required': 'RC Pro est requis',
  'validation.rib-required': 'RIB est requis',
  'validation.car-brand': 'Veuillez choisir la marque du véhicule',
  'validation.car-model': 'Veuillez choisir le modèle de véhicule',
  'validation.car-color': 'Veuillez choisir la couleur du véhicule',
  'validation.car-year': "Veuillez choisir l'année du véhicule",
  'validation.valid-number': 'Veuillez choisir un nombre',
  'validation.is-number': 'Veuiller entrer un nombre',
  'validation.car-seats': 'Veuillez choisir le nombre de sièges',
  'validation.car-seats-min': 'le nombre de sièges doit être au moins 1',
  'validation.car-plate': "Veuillez saisir l'immatriculation du véhicule",
  'validation.grey-card-required': 'Veuillez ajouter la carte grise',
  'validation.grey-card-front-required':
    'Veuillez ajouter la carte grise recto',
  'validation.grey-card-back-required': 'Veuillez ajouter la carte grise verso',
  'validation.green-card-required': 'Veuillez ajouter la carte verte',
  'validation.insurance-required': "Veuillez ajouter l'assurance du véhicule",
  'validation.technical-control-required':
    'Veuillez ajouter le contrôle technique',
  'validation.macaron-required': 'Veuillez ajouter le macaron',
  'validation.car-picture-required': "Veuillez ajouter la photo de l'odomètre",
  'validation.lvc-thumbnail-required': 'Veuillez ajouter la vignette LVC',
  'validation.bce':
    "Veuillez ajouter l'extrait de la BCE (Banque-Carrefour des Entreprises)",
  'validation.medical-visit-required': 'Veuillez ajouter la visite médicale',
  'validation.criminal-record-required':
    'Veuillez ajouter le document de vos antécédents judiciaires',
  'validation.driving-record-required':
    'Veuillez ajouter le dossier de conduite',
  'validation.drivingRecord': 'Veuillez ajouter le dossier de conduite',
  'validation.profile-pic-required': 'La photo de profil est requise',
  'validation.agglomeration-required': "Veuillez choisir l'agglomération ",
  'validation.licence-type-required':
    'Veuillez choisir  le type de permis de conduire',
  'validation.kilometers-required': 'kilométrage du véhicule est requis',
  'validation.lic-number-required':
    'Le numéro de permis de conduire est requis',
  'validation.birth-date-required': 'La date de naissance est requise',
  'validation.address-1-required': "L'adresse est requise",
  'validation.postal-code-required': 'Code postal est requis',
  'validation.fuel-type': 'Le type de carburant est requis',
  'validation.mechanical-control': "L'inspection mécanique est requise",
  'valide.ext': 'L\'extention doit être: png, jpg ou pdf',
  'general-info.server-error': "Une erreur s'est produite veuillez réessayer",
  'general-info.who-are-you': 'Qui êtes-vous?',
  'general-info.choose-suitable-option':
    "Veuillez choisir l'option qui vous correspond",
  'go.to.home': 'Revenir à la page d\'accueil',
  'general-info.owner': 'Gérant',
  'general-info.mandated-employee': 'Employé mandaté pour inscrire la société',
  'general-info.mandated-info': 'Informations de l’employé mandaté',
  'general-info.last-name': 'Nom',
  'general-info.first-name': 'Prénom',
  'general-info.email': 'Adresse e-mail',
  'general-info.email-confirmation': 'Confirmation de l’adresse e-mail',
  'general-info.phone-number': 'Numéro de téléphone',
  'general-info.owner-info': 'Informations du gérant',
  'general-info.personal-info': 'Informations personnelles',
  'general-info.city': 'Ville',
  'general-info.subscribe': 'Enregistrer',
  'general-info.birth-date': 'Date de naissance',
  'general-info.agglomeration': 'Agglomération',
  'general-info.licence-type': 'Type de permis',
  'general-info.class5-accepted': 'Le permis classe 5 est accepté',
  'general-info.licence-over-1year': 'Avez vous le permis depuis plus d’un an?',
  'general-info.yes': 'Oui',
  'general-info.no': 'Non',
  'general-info.driving-question':
    'Est ce que vous conduisez actuellement avec une autre plateforme de transport de personnes?',
  'general-info.taxi-question': 'êtes vous un Taxi?',
  'general-info.address': 'Adresse',
  'general-info.address2': "Ligne d'adresse 2",
  'general-info.postal-code': 'Code postal',
  'drivers.add-driver': 'Ajouter un chauffeur',
  'drivers.add-drivers-first':
    'Ajouter des chauffeurs d’abord puis enregistrer',
  'drivers.save-to-continue': 'cette opération pour poursuivre.',
  'drivers.modify': 'Modifier',
  'drivers.delete': 'Supprimer',
  'drivers.driver-info': 'Ajouter vos informations personelles',
  'drivers.driver-docs': 'Documents relatives à ce chauffeur',
  'drivers.id': "Pièce d'identité",
  'drivers.passport': 'Passeport',
  'drivers.residence-permit': 'Titre de séjour',
  'drivers.server-error': "Une erreur s'est produite veuillez réessayer",
  'drivers.personal-info': 'Informations personnelles',
  'drivers.first-name': 'Prénom',
  'drivers.last-name': 'Nom',
  'drivers.mail': 'Adresse e-mail',
  'drivers.mail-confirmation': 'Confirmation de l’adresse e-mail',
  'drivers.phone': 'Numéro de téléphone',
  'drivers.phone-confirmation': 'Confirmation du numéro de téléphone',
  'drivers.doc-type': 'Type de document',
  'drivers.vtc-card': 'Carte VTC',
  'drivers.only-accept-new-cards':
    'Nous n’acceptons que les nouvelles cartes plastifiées (Les récépissés ne sont pas acceptés).',
  'drivers.eu-driver-lic': 'Téléversez votre permis de conduire',
  'drivers.new-driver': 'Documents personnels',
  'drivers.add-driver-info': 'Ajouter vos documents personelles',
  'drivers.medical-visit': 'Visite médicale',
  'drivers.criminal-record':
    'Téléversez le document de vos antécédents judiciaires',
  'drivers.criminal-record-note':
    'Une vérification d’antécédents auprès d’un corps de police du Québec est requise pour devenir chauffeur autorisé',
  'drivers.driving-record': 'Téléversez le dossier de conduite',
  'drivers.driving-record-note':
    "Le dossier de conduite est à demander auprès de la SAAQ Le dossier de conduite d'un chauffeur autorisé ne doit pas faire l'objet de plus de trois infractions mineures survenues au cours des trois dernières années et ne doit faire l'objet d'aucune infraction majeure au cours des trois dernières années.",
  'drivers.profile-pic': 'Ajoutez votre photo de profil',
  'drivers.profile-pic-note':
    'Afin d’aider vos membres passagers à vous identifier facilement et afin d’améliorer la sécurité sur l’application, tous les chauffeurs autorisés doivent avoir une photo de profil associée à leur compte.Assurez-vous que vous êtes habillés proprement et de façon professionnelle',
  'drivers.lic-number': 'Numéro de permis de conduire',
  'drivers.licence-note':
    "Il faudra être titulaire d'un permis de conduire de la classe 5 (ou d’une classe supérieure) et avoir une expérience de conduite de 12 mois ou plus (excluant l’expérience de conduite du permis probatoire",
  'drivers.agglomeration': 'Agglomération',
  'drivers.licence-type': 'Type de permis',
  'drivers.class5-accepted': 'Le permis classe 5 est accepté',
  'drivers.licence-over-1year': 'Avez vous le permis depuis plus d’un an?',
  'drivers.yes': 'Oui',
  'drivers.no': 'Non',
  'drivers.driving-question':
    'Est ce que vous conduisez actuellement avec une autre plateforme de transport de personnes?',
  'drivers.taxi-question': 'êtes vous un Taxi?',
  'dropzone.doc-added': 'Document ajouté',
  'dropzone.add-doc': 'Ajouter le document',
  'dropzone.add-doc-page1': 'Ajouter la première page du document',
  'dropzone.add-doc-page2': 'Ajouter la deuxième page du document',
  'dropzone.add-doc-page3': 'Ajouter la troisième page du document',
  'dropzone.add-doc-recto': 'Ajouter le document Recto',
  'dropzone.add-doc-verso': 'Ajouter le document Verso',
  'dropzone.saving-in-progress': 'En attente d’enregistrement',
  'dropzone.modify': 'Modifier',
  'dropzone.rejected': 'Ce document a été rejetté',
  'dropzone.accepted': 'Ce document a été validé',
  'done.download-yassir-driver-app':
    "Télécharger l’application YASSIR Chauffeur",
  'done.thanks-for-submitting':
    "Merci d'avoir pris le temps de remplir le formulaire",
  'done.application-under-review':
    'Nous avons bien reçu votre candidature. Nous la traiterons le plus rapidement possible et reviendrons vers vous pour activer votre compte.',
  'company.server-error': "Une erreur s'est produite veuillez réessayer",
  'company.about': "À propos de l'entreprise",
  'company.name': "Nom de l'entreprise",
  'company.legal-form': 'Forme juridique',
  'company.siret': 'N° SIRET',
  'company.city': 'Ville',
  'company.kbis-insee': 'KBIS ou INSEE',
  'company.subscription-certificate':
    "Certificat d'inscription au Registre de VTC",
  'company.legit-docs-only':
    "Nous n'acceptons que les documents officiels du ministère.",
  'company.rc-pro': 'Assurance responsabilité civile professionnelle (RC Pro)',
  'company.doc-requirement':
    "Le document doit contenir la mention 'responsabilité civile' en rapport avec l'activité de votre entreprise.",
  'company.rib': "Relevé d'Identité Bancaire (RIB)",
  'company.iban-or-rib':
    'Veuillez saisir votre IBAN et ajouter une copie de votre RIB.',
  'company.submit': 'Enregistrer',
  'company.tva': 'N° de TVA',
  'company.bce-certificate':
    'Extrait de la BCE (Banque-Carrefour des Entreprises)',
  'company.lvc-licence': 'Licence LVC',
  'cars.add-vehicle': 'Ajouter un véhicule',
  'cars.add-vehicles-first':
    'Ajoutez vos véhicules puis, enregistrez cette étape pour poursuivre.',
  'cars.this-operation': 'cette opération pour poursuivre.',
  'cars.submit': 'Enregistrer',
  'cars.modify': 'Modifier',
  'cars.delete': 'Supprimer',
  'cars.general-info': 'Informations générales',
  'cars.brand': 'Marque',
  'cars.model': 'Modèle',
  'cars.color': 'Couleur',
  'cars.year': 'Année',
  'cars.seats': 'Nombre de places',
  'cars.plate': "Plaque d'immatriculation",
  'cars.grey-card': "Carte d'immatriculation",
  'cars.grey-card-note':
    'Toutes les informations doivent être clairement visibles',
  'cars.green-card': 'Carte verte',
  'cars.serive-available-onboard': 'Services disponibles à bord du véhicule',
  'cars.check-available-services':
    'Veuillez cocher les services disponibles à bord du véhicule',
  'cars.pmr-accessibility': 'Accessibilité PMR (Personne à mobilité réduite)',
  'cars.child-seat': 'Siège enfant',
  'cars.usb-charger': 'Chargeur d’appareil mobile micro-USB',
  'cars.wifi': 'WIFI',
  'cars.trp-insurance': 'Assurance du véhicule',
  'cars.insurance-note':
    "Le conducteur doit être le conducteur principal ou secondaire sur l’assurance, assurez-vous que votre nom, le numéro d'identification du véhicule, la compagnie d'assurance et la date d'expiration soient visibles.",
  'cars.technical-control': 'Contrôle technique',
  'cars.macaron': 'Macaron',
  'cars.picture': "Photo de l'odomètre",
  'cars.picture-description':
    'Le nombre de kilomètres parcourus par votre véhicule doit être clairement visible.',
  'cars.picture-example': 'Voir un exemple de photo',
  'cars.server-error': "Une erreur s'est produite veuillez réessayer",
  'cars.new': 'Informations du véhicule',
  'cars.add-vehicle-info': 'Ajouter les informations relatives au véhicule',
  'cars.new-doc': 'Documents du véhicule',
  'cars.add-vehicle-doc': 'Ajouter les documents du véhicule',
  'cars.next': 'Suivant',
  'cars.grey-card-recto': 'Carte grise',
  'cars.grey-card-vers': 'Carte grise',
  'cars.kilometers': 'Kilométrage',
  'lvc-thumbnail': 'Vignette LVC',
  'cars.mechanical-control': 'Inspection mécanique du véhicule',
  'cars.mechanical-control-note':
    'Vous devez faire inspecter votre véhicule par un mécanicien certifié à l’un des garages affiliés à la SAAQ .Les véhicules ayant parcouru moins de 80K Km ou âgés de moins de 4 ans ne nécessitent pas de vérification mécanique',
  'cars.fuel-type': 'Type de carburant',
  'congratulations.trad': 'Félicitations',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.dza': 'Bravo! Vous êtes maintenant intervenant (chauffeur) Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.mar': 'Bravo! Vous êtes maintenant chauffeur Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.tun': 'Bravo! Vous êtes maintenant chauffeur Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.sen': 'Bravo! Vous êtes maintenant chauffeur partenaire Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.ben': 'Bravo! Vous êtes maintenant chauffeur partenaire Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.tgo': 'Bravo! Vous êtes maintenant chauffeur partenaire Yassir.',
  'cheer.you.are.now.a.vtc.yassir.partner.driver.can': 'Bravo! Vous êtes maintenant chauffeur Yassir.',
  'bank-details.financial-institute': 'Institution financière',
  'bank-details.financial-institute-number':
    "Numéro de l'institution financière",
  'bank-details.transit-number': 'Numéro de transit',
  'bank-details.bank-account-number': 'Numéro de compte bancaire',
  'bank-details.tps-doc': 'TPS',
  'bank-details.tps-number': 'Numéro TPS',
  'bank-details.tvq-doc': 'TVQ',
  'bank-details.tvq-number': 'Numéro TVQ',
  'bank-details.subscribe': 'Enregistrer',
  'find.answers.to.your.questions': 'Trouvez des réponses à vos questions',
  'Hafid.Z.can': 'Hafid Z',
  'Hafid.Z.dza': 'Hafid Z',
  'Hafid.Z.mar': 'Hafid Z',
  'Hafid.Z.tun': 'Hafid Z',
  'Hafid.Z.sen': 'Mamadou D',
  'Hafid.Z.ben': 'Mamadou D',
  'Hafid.Z.tgo': 'Mamadou D',
  'how.long.will.the.training.take': 'Combien de temps prendra la formation?',
  'more.than.40000.drivers.are.already.with.yassir.dza': 'Plus de 40.000 intervenants (chauffeurs) sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.mar': 'Plus de 40.000 chauffeurs sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.tun': 'Plus de 40.000 chauffeurs sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.sen': 'Plus de 40.000 chauffeurs partenaires sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.ben': 'Plus de 40.000 chauffeurs partenaires sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.tgo': 'Plus de 40.000 chauffeurs partenaires sont déjà chez Yassir.',
  'more.than.40000.drivers.are.already.with.yassir.can': 'Plus de 40.000 chauffeurs sont déjà chez Yassir.',
  'over.5.million.rides.completed.by.our.drivers.dza': 'Plus de 5 millions de courses terminées par nos intervenants (chauffeurs).',
  'over.5.million.rides.completed.by.our.drivers.mar': 'Plus de 5 millions de courses terminées par nos chauffeurs.',
  'over.5.million.rides.completed.by.our.drivers.tun': 'Plus de 5 millions de courses terminées par nos chauffeurs.',
  'over.5.million.rides.completed.by.our.drivers.sen': 'Plus de 5 millions de courses terminées par nos chauffeurs partenaires.',
  'over.5.million.rides.completed.by.our.drivers.ben': 'Plus de 5 millions de courses terminées par nos chauffeurs partenaires.',
  'over.5.million.rides.completed.by.our.drivers.tgo': 'Plus de 5 millions de courses terminées par nos chauffeurs partenaires.',
  'over.5.million.rides.completed.by.our.drivers.can': 'Plus de 5 millions de courses terminées par nos chauffeurs.',
  'ready.for.a.new.adventure': 'Prêt pour une nouvelle aventure?',
  'submit.your.application.to.become.a.yassir.partner.driver.today.dza': 'Soumettez votre candidature pour devenir intervenant (chauffeur) Yassir aujourd\'hui.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.mar': 'Soumettez votre candidature pour devenir chauffeur Yassir aujourd\'hui.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.tun': 'Soumettez votre candidature pour devenir chauffeur Yassir aujourd\'hui.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.sen': 'Inscrivez vous pour devenir chauffeur partenaire chez Yassir.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.ben': 'Inscrivez vous pour devenir chauffeur partenaire chez Yassir.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.tgo': 'Inscrivez vous pour devenir chauffeur partenaire chez Yassir.',
  'submit.your.application.to.become.a.yassir.partner.driver.today.can': 'Soumettez votre candidature pour devenir chauffeur Yassir aujourd\'hui.',
  'training.start': 'Commencer la formation',
  'training.content-title': 'Contenu',
  'training.next-course': 'Prochain Cours',
  'training.previous-course': 'Cours Précedent',
  'training.validate-answer': 'Valider ma réponse',
  'the.training.will.last.approximately.half.a.day.dza': 'La formation durera environ une demi-journée, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur et éventuellement poser toutes les questions que vous souhaitez concernant le service.',
  'the.training.will.last.approximately.half.a.day.mar': 'La formation durera environ une demi-journée, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur et éventuellement poser toutes les questions que vous souhaitez concernant le service.',
  'the.training.will.last.approximately.half.a.day.tun': 'La formation durera environ une demi-journée, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur et éventuellement poser toutes les questions que vous souhaitez concernant le service.',
  'the.training.will.last.approximately.half.a.day.sen': 'La formation durera au maximum 30mn, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur ainsi que de faire un rappel des règles de sécurité routières.',
  'the.training.will.last.approximately.half.a.day.ben': 'La formation durera au maximum 30mn, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur ainsi que de faire un rappel des règles de sécurité routières.',
  'the.training.will.last.approximately.half.a.day.tgo': 'La formation durera au maximum 30mn, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur ainsi que de faire un rappel des règles de sécurité routières.',
  'the.training.will.last.approximately.half.a.day.can': 'La formation durera environ une demi-journée, l\'objectif est de vous apprendre à utiliser l\'application Yassir Chauffeur et éventuellement poser toutes les questions que vous souhaitez concernant le service.',
  'to.become.a.partner.driver': 'Pour devenir intervenant “Chauffeur”, vous devez remplir les conditions suivantes :',
  'to.have.a.car': 'Disposer d\'un véhicule',
  'the.day.you.go.to.the.yassir.headquarters.dza': 'Le jour où vous vous rendez au niveau du siège Yassir, il est très important de vous présenter avec votre véhicule pour que notre technicien puisse procéder à une vérification.',
  'the.day.you.go.to.the.yassir.headquarters.mar': 'Le jour où vous vous rendez au niveau du siège Yassir, il est très important de vous présenter avec votre véhicule pour que notre technicien puisse procéder à une vérification.',
  'the.day.you.go.to.the.yassir.headquarters.tun': 'Le jour où vous vous rendez au niveau du siège Yassir, il est très important de vous présenter avec votre véhicule pour que notre technicien puisse procéder à une vérification.',
  'the.day.you.go.to.the.yassir.headquarters.sen': 'Il est important de vous présenter avec votre véhicule pour vérifier l’état de la voiture.',
  'the.day.you.go.to.the.yassir.headquarters.ben': 'Il est important de vous présenter avec votre véhicule pour vérifier l’état de la voiture.',
  'the.day.you.go.to.the.yassir.headquarters.tgo': 'Il est important de vous présenter avec votre véhicule pour vérifier l’état de la voiture.',
  'the.day.you.go.to.the.yassir.headquarters.can': 'Le jour où vous vous rendez au niveau du siège Yassir, il est très important de vous présenter avec votre véhicule pour que notre technicien puisse procéder à une vérification.',
  'two.payment.methods.are.available': 'Deux modes de paiement sont disponibles: Paiement en argent comptant, le mode utilisé jusqu’à présent paiement par carte bancaire, TPE lancé pour quelques partenaires.',
  'une.fois.selectionne.vous.serez.contacte.dza': 'Une fois sélectionné, vous serez contacté afin de passer une formation au sein de notre centre.',
  'une.fois.selectionne.vous.serez.contacte.mar': 'Une fois sélectionné, vous serez contacté afin de passer une formation au sein de notre centre.',
  'une.fois.selectionne.vous.serez.contacte.tun': 'Une fois sélectionné, vous serez contacté afin de passer une formation au sein de notre centre.',
  'une.fois.selectionne.vous.serez.contacte.can': 'Une fois sélectionné, vous serez contacté afin de passer une formation au sein de notre centre.',
  'une.fois.selectionne.vous.serez.contacte.sen': 'Une fois sélectionné, nous vous contacterons pour la formation.',
  'une.fois.selectionne.vous.serez.contacte.ben': 'Une fois sélectionné, nous vous contacterons pour la formation.',
  'une.fois.selectionne.vous.serez.contacte.tgo': 'Une fois sélectionné, nous vous contacterons pour la formation.',
  'what.our.drivers.say.about.us.dza': 'Ceux que nos intervenants (chauffeurs) disent de nous.',
  'what.our.drivers.say.about.us.mar': 'Ceux que nos chauffeurs disent de nous.',
  'what.our.drivers.say.about.us.tun': 'Ceux que nos chauffeurs disent de nous.',
  'what.our.drivers.say.about.us.sen': 'Ce que nos partenaires disent de nous.',
  'what.our.drivers.say.about.us.ben': 'Ce que nos partenaires disent de nous.',
  'what.our.drivers.say.about.us.tgo': 'Ce que nos partenaires disent de nous.',
  'what.our.drivers.say.about.us.can': 'Ceux que nos chauffeurs disent de nous.',
  'what.are.the.high.demand.places.dza': 'Quels sont les endroits à forte demande?',
  'what.are.the.high.demand.places.mar': 'Quels sont les endroits à forte demande?',
  'what.are.the.high.demand.places.tun': 'Quels sont les endroits à forte demande?',
  'what.are.the.high.demand.places.sen': 'Comment est-ce que je suis payé ?',
  'what.are.the.high.demand.places.ben': 'Comment est-ce que je suis payé ?',
  'what.are.the.high.demand.places.tgo': 'Comment est-ce que je suis payé ?',
  'what.are.the.high.demand.places.can': 'Quels sont les endroits à forte demande?',
  'where.there.are.yassir.customers.dza': 'Là où il y a des clients Yassir, il y a de la demande! C\'est à vous d’assurer une bonne prestation de service. Un client satisfait est la meilleure stratégie d\'affaire.',
  'where.there.are.yassir.customers.mar': 'Là où il y a des clients Yassir, il y a de la demande! C\'est à vous d’assurer une bonne prestation de service. Un client satisfait est la meilleure stratégie d\'affaire.',
  'where.there.are.yassir.customers.tun': 'Là où il y a des clients Yassir, il y a de la demande! C\'est à vous d’assurer une bonne prestation de service. Un client satisfait est la meilleure stratégie d\'affaire.',
  'where.there.are.yassir.customers.sen': 'Vous êtes payé directement par le client à chaque fin de course, plus vous en faites plus vous gagnez. De plus avec Yassir vous avez de nombreux bonus qui vous sont reversés à J+1.',
  'where.there.are.yassir.customers.ben': 'Vous êtes payé directement par le client à chaque fin de course, plus vous en faites plus vous gagnez. De plus avec Yassir vous avez de nombreux bonus qui vous sont reversés à J+1.',
  'where.there.are.yassir.customers.tgo': 'Vous êtes payé directement par le client à chaque fin de course, plus vous en faites plus vous gagnez. De plus avec Yassir vous avez de nombreux bonus qui vous sont reversés à J+1.',
  'where.there.are.yassir.customers.can': 'Là où il y a des clients Yassir, il y a de la demande! C\'est à vous d’assurer une bonne prestation de service. Un client satisfait est la meilleure stratégie d\'affaire.',
  'what.time.of.day.can.i.work': 'A quel moment de la journée je peux travailler?',
  'what.is.the.file.to.provide': 'Quelles sont les conditions à remplir ?',
  'what.is.the.payment.method': 'Quel est le mode de paiement?',
  'we.are.present.in.more.than.4.countries.across.africa.dza': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.mar': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.tun': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.sen': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.ben': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.tgo': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'we.are.present.in.more.than.4.countries.across.africa.can': 'Nous sommes présents dans plus de 4 pays à travers l\'Afrique.',
  'your.file.is.sent.to.our.teams': 'Votre dossier est transmis à nos équipes afin d\'être vérifié et étudié.',
  'yes.you.can.take.inter.wilaya.races': 'Oui, vous pouvez prendre des courses inter-wilayas.',
  'you.can.reach.us': 'Vous pouvez nous joindre via le service client 24H/7J au 021999999, également via Facebook/Messenger. Nous nous ferons un plaisir de vous répondre!',
  'Zohir.H.can': 'Zohir H',
  'Zohir.H.dza': 'Zohir H',
  'Zohir.H.mar': 'Zohir H',
  'Zohir.H.tun': 'Zohir H',
  'Zohir.H.sen': 'Adja N',
  'Zohir.H.ben': 'Adja N',
  'Zohir.H.tgo': 'Adja N',
  'cancel.text': 'Annuler',
  'apply.text': 'Appliquer',
  "exceed.25.characters": "Le prénom et le nom ne doivent pas dépasser 25 caractères",
  "min.length.names": "Le nom et prénoms doivent comporter plus de deux caracteres"
};

export default fr;
