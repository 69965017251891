/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, ReactElement } from 'react';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import MaskedInput from 'react-text-mask';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Countdown from 'react-countdown';
import ReactCodeInput from 'react-code-input';
import { FormattedMessage } from 'react-intl';
import { Button, useMediaQuery, useTheme, CircularProgress } from '@material-ui/core';
// import AppContext from '../../context/AppContext';
// import Checkbox from '../../components/CheckBox';
// import Row from '../../components/Row';
import clsx from 'clsx';
import { Controller, useForm } from 'react-hook-form';
import { useStyles } from './styles';
import Loader from '../../components/Loader/Loader';
import { IntlContext } from '../../contexts/intlContext';

interface IFormProps {
  isLoading: boolean;
  onSubmit: (data: Record<string, any>) => void;
  codeValidated: boolean;
  errorCode: boolean;
}

const PinForm: React.FC<IFormProps> = ({ isLoading, onSubmit, codeValidated, errorCode }): ReactElement => {

  const scope = 'signin';
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext).locales;

  const btnClasses = clsx(classes.button, classes.disabledButton);
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      code: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.card}>
        <Controller
          name='code'
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <>
            <DirectionProvider direction={DIRECTIONS.LTR} lang={locale}>
            <ReactCodeInput
              inputMode='numeric'
              type='number'
              fields={6}
              inputStyle={{
                width: isMobile ? 25 : 35,
                height: isMobile ? 25 : 35,
                borderRadius: 8,
                border: `solid 1px ${codeValidated ? '#84dfb6' : 'grey'}`,
                margin: 5,
                fontSize: isMobile ? 20 : 18,
                fontWeight: 'normal',
                textAlign: 'center',
                outline: 'none',
              }}
              name='code'
              // onBlur={onBlur}
              onChange={onChange}
              ref={ref}
              // onChange={(e) => console.log('event onchange')}
              // onBlur={(e) => console.log('event onBlur')}
              />
              </DirectionProvider>
              {
                errorCode ?
                  <span style={{ color: 'tomato', fontSize: 12 }}>
                    <FormattedMessage id={`${scope}.invalid-code`} />
                  </span>
                  :
                  null
              }
              </>
          )}
          rules={{ required: true }}
        />

        {isSubmitting ? (
          <Loader />
        ) : codeValidated ? (
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <small style={{ color: '#84dfb6', textAlign: 'center' }}>
              <FormattedMessage id={`${scope}.validated-code`} />
            </small>
          </div>
        ) : (
          <>
            <button
              id='confirmCodePin'
              color='primary'
              className={!isDirty || !isValid ? classes.disabledButton : classes.button }
              type='submit'
              disabled={!isDirty || !isValid}
            >
              {isLoading && <CircularProgress className={classes.styleCircular}/>} <FormattedMessage id={`${scope}.confirm`} />
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default PinForm;
