import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Button,
  Stepper,
  Step,
  StepButton,
} from '@material-ui/core';
import { FaArrowAltCircleLeft, FaTimesCircle } from 'react-icons/fa';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import { useSubmit } from '../../hooks/useSubmit';
import { IFormInput, ParamsTypes } from '../../utils/types';
import { StepContent } from './StepContent';
import { ColorlibConnector } from '../../screens/MultiStepsForm/styles';
import { useConfigData } from '../../contexts/configContext';

export interface StepperProps {
  groupFields: any;
  title: string;
  openDialog: boolean;
  closeDialog: () => void;
}

const DialogMultiSteps: React.FC<StepperProps> = ({
  title,
  groupFields,
  openDialog,
  closeDialog,
}): ReactElement => {
  const classes = useStyles();

  const { isLoading, mutate } = useSubmit();

  const methods = useForm<IFormInput>({
    shouldUnregister: false,
    defaultValues: {
      gender: '',
      firstname: 'hello',
      lastname: '',
    },
  });

  const { groupStepId } = useParams<ParamsTypes>();
  const { getStep, getGroupStepById, getCanEditFields } = useConfigData();

  const selectedGroup = getGroupStepById(groupStepId);

  const { handleSubmit } = methods;

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>(
    {},
  );
  const steps = new Array(groupFields.length).fill('');

  const totalSteps = (): number => steps.length;

  const completedSteps = (): number => Object.keys(completed).length;

  const isLastStep = (): boolean => activeStep === totalSteps() - 1;

  const allStepsCompleted = (): boolean => completedSteps() === totalSteps();

  const handleNext = (submitedData: any): void => {
    let newActiveStep;
    if (isLastStep() && !allStepsCompleted()) {
      newActiveStep = steps.findIndex((step, i) => !(i in completed));
      setActiveStep(newActiveStep);
    } else if (isLastStep() && allStepsCompleted()) {
      console.log('SSSSUBMIT !!!', submitedData);
      mutate(submitedData);
    } else {
      newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  const handleBack = (): void => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = (submitedData: any): void => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext(submitedData);
  };

  const handleReset = (): void => {
    setActiveStep(0);
    setCompleted({});
  };

  const onSubmit: SubmitHandler<IFormInput> = (submitedData: any) => {
    handleComplete(submitedData);
  };

  let btnContent = <></>;
  if (isLoading) {
    btnContent = <CircularProgress size={24} />;
  } else if (activeStep === 1) {
    btnContent = <FormattedMessage id='cars.submit' />;
  } else {
    btnContent = <FormattedMessage id='cars.next' />;
  }

  return (
    <Dialog
      open={openDialog}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='md'
      className={classes.dialogContainer}
    >
      <div className={classes.container}>
        <DialogTitle id='alert-dialog-title'>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <IconButton onClick={handleBack}>
              <FaArrowAltCircleLeft />
            </IconButton>
            <Typography className={classes.title}>{title}</Typography>
            <IconButton aria-label='close' onClick={closeDialog}>
              <FaTimesCircle />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText id='alert-dialog-description'>
            <FormProvider {...methods}>
              <div className={classes.root}>
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  className={classes.stepper}
                  connector={<ColorlibConnector />}
                >
                  {React.Children.toArray(
                    steps.map((label, index) => (
                      <Step>
                        {label}
                        <StepButton
                          // onClick={handleStep(index)}
                          completed={completed[index]}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    )),
                  )}
                </Stepper>
                {allStepsCompleted() ? (
                  <>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                  </>
                ) : (
                  <>
                    {StepContent(selectedGroup.steps[activeStep].groupFields,getCanEditFields)}

                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      onClick={handleSubmit(onSubmit)}
                    >
                      {btnContent}
                    </Button>
                  </>
                )}
              </div>
            </FormProvider>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default DialogMultiSteps;
