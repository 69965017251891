import React, { ReactElement, useRef, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type Footer = {
  height: number;
  isFetching: boolean;
};
type AppDataType = {
  footer: Footer | undefined;
  setFooter: (footer: Footer) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useFooterData = (): any => React.useContext(Context);

const FooterProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [footer, setFooter] = useState({  
    height: 0,
    isFetching: true,
  });

  const value = React.useMemo(
    () => ({
      setFooter,
      footer
    }),
    [
      setFooter,
      footer
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { FooterProvider, Context as FooterContext };
