import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './styles';
import Header from '../../components/Header/Header';
import { IntlContext } from '../../contexts/intlContext';

const PageNotFound: React.FC = (): ReactElement => {
  const classes = useStyles();
  const { locale } = React.useContext(IntlContext).locales;

  return (
    <div>
      <div className={classes.bgHeader}>
        <Header />
      </div>
      <div className={classes.content}>
        <div style={{ width: '100%' }}>
          <h1> 404 </h1>
          <h3> Page not found !</h3>
          <Link to={`/${locale}`}>Go home page</Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
