import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ONBOARDING_API } from '../../utils/constants';
import { useConfigData } from '../../contexts/configContext';
import { useScreenData } from '../../contexts/screenDataContext';
import { useCallApi } from '../../hooks/useCallApi';
import { IntlContext } from '../../contexts/intlContext';
import YassirLoader from '../../components/YassirLoader';
import { useAuthData } from '../../contexts/authContext';

const EspaceMsg: React.FC = (): ReactElement => {
  const history = useHistory();
  const { screen, setScreen } = useScreenData();
  const { setIsTokenForConfig, config } = useConfigData();
  const { isTokenValid, setIsTokenValid } = useAuthData();
  const { locale } = React.useContext(IntlContext).locales;
  const title = new Map()
  const body = new Map()

  // invok url avec token 
  const url = `${window.location.origin}/${locale}/login`
  const queryParams = new URLSearchParams(window.location.href.replace(url, ''));
  const token = queryParams.get("token");

  const {
    isLoading: loadingRequestLogin,
    isSuccess: successRequestLogin,
    isError: errorRequestLogin,
    data: codeLoginData,
    error: codeLoginError,
    mutate: mutateRequestLogin,
  } = useCallApi(String(token), {
    path: `${ONBOARDING_API}/login`,
    method: 'get'
  });

  useEffect(() => {
    if (token && localStorage.getItem('token') && token !== localStorage.getItem('token')) {
      localStorage.setItem('token', String(token))
    }
  }, [token])

  useEffect(() => {
    if (["404","400"].includes(String(codeLoginError?.response?.status))) {
      localStorage.setItem('token','')
      window.location.replace(`/${locale}`);
    }
  }, [codeLoginError])

  useEffect(() => {
    if (successRequestLogin) {
      localStorage.setItem('token', String(token))
      setIsTokenValid(true)
      setIsTokenForConfig(true)
    }
  }, [successRequestLogin, codeLoginData])

  useEffect(() => {
    if (errorRequestLogin)
      localStorage.removeItem('token');
  }, [errorRequestLogin])

  useEffect(() => {
    if (errorRequestLogin && localStorage.length === 0)
      history.push("/");
  }, [errorRequestLogin, localStorage])

  useEffect(() => {
    if (token)
      mutateRequestLogin({})
    else
      window.location.replace('/logout');
  }, [token])

  useEffect(() => {
    if(successRequestLogin){
      if (codeLoginData?.data?.data?.screen?.title) {
        title.set(locale, codeLoginData?.data?.data?.screen?.title[locale])
      }
      if (codeLoginData?.data?.data?.screen?.body_html) {
        body.set(locale, codeLoginData?.data?.data?.screen?.body_html[locale])
      } else {
        body.set(locale, codeLoginData?.data?.data?.screen?.body[locale])
      }
    }
  }, [successRequestLogin, codeLoginData?.data?.data?.screen, title, body])

  useEffect(() => {
    if (codeLoginData?.data?.data?.successMessageKey === 'DISPLAY_ONBOARDING_SCREEN' && title.size > 0 && body.size > 0) {
      setScreen({
        ...screen,
        title: title.size > 0 ? title : undefined,
        body: body.size > 0 ? body : undefined
      })
    }
  }, [codeLoginData?.data?.data?.successMessageKey, screen, title, body])

  useEffect(() => {
    localStorage.setItem('inApp', String(true))
  }, [])

  useEffect(() => {
    if(successRequestLogin){
      if (codeLoginData?.data) {
          switch (codeLoginData?.data?.successMessageKey) {
            case 'DISPLAY_ONBOARDING_SCREEN':
              history.push(`/${locale}/screen`)
              break;
            case 'NEED_TERMINATE_REGISTRATION':
              history.push(`/${locale}/register`);
              break;
            case 'DRIVER_EXISTS':
              history.push(`/${locale}/registred`);
              break;
            case 'LEAD_NOT_EXISTS':
            default:
              localStorage.setItem('token','')
              window.location.replace(`/${locale}`);
              break;
          }
      }
    }
  }, [codeLoginData, successRequestLogin])

  return (
    <>
      <YassirLoader />
    </>);
};

export default EspaceMsg;
