import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  field: {
    background: '#F5F5F5',
    borderRadius: theme.shape.borderRadius,
    padding: '4px 8px',
    margin: '4px 0',
    color: '#1B1B1B',
  },
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
    textAlign: 'left',
    display: 'flex',
  },
  selectFlex: {
    background: '#f00',
    display: 'flex',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    lineHeight: '1.66',
  },
  spanColor: {
    width: '15px',
    height: '15px',
    borderRadius: '50px',
    marginRight: '10px',
    // position: 'absolute',
    // right: '25px',
    // top: '5px',
    border: '2px solid #fff',
    boxShadow: '0 1px 3px 0px rgba(0,0,0,0.3)',
  },
}));
