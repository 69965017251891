export interface ICard {
  id: string;
  title: string;
  subtitle: string;
  image: string;
}

export enum GroupStepStatus {
  none = 'NONE',
  disabled = 'DISABLED',
  pending = 'PENDING',
  success = 'SUCCESS',
  valid = 'VALID',
  invalid = 'INVALID'
}

export enum DocumentStatus {
  pending = 'PENDING',
  rejected = 'REJECTED',
}