import React, { ReactElement } from 'react';
import useStyles from './styles';
import GroupStepItem from '../GroupStepItem';
import { MultiLangType } from '../../utils/types';
import { getPathBySteps } from '../../utils/getPath';
import { useConfigData } from '../../contexts/configContext';

interface StepUnit {
  description: MultiLangType;
  name: string;
  label: MultiLangType;
  icon: string;
  status: string;
  id?: string;
  groupFields: Record<string, any>[];
}

interface IGroupSteps {
  id?: string;
  title: string;
  icon: string;
  label: MultiLangType;
  steps: StepUnit[];
  status: any;
  description: any;
  isActive: boolean | undefined;
}
interface FormProps {
  groupSteps: IGroupSteps[];
}

const GroupSteps: React.FC<FormProps> = ({ groupSteps }): ReactElement => {
  const classes = useStyles();
  const { isPreviousStepCompleted } = useConfigData();

  return (
    <>
      {groupSteps && React.Children.toArray(
        groupSteps.map((groupStep: IGroupSteps) => (
          <div className={classes.container}>
            <GroupStepItem
              status={groupStep.status || 'NONE'}
              isActive={isPreviousStepCompleted(groupStep.id)}
              label={groupStep.label}
              description={groupStep.description}
              icon={groupStep.icon}
              groupId={groupStep.id}
              id={groupStep.id}
              path={getPathBySteps(groupStep)}
            />
          </div>
        )),
      )}
    </>
  );
};

export default GroupSteps;
