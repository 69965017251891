import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  linkSignup: {
    color: '#2f9ab7',
    textDecoration: 'none'
  },
  content: {
    padding: '30px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    padding: '12px',
    width: '100%',
    background: '#6316DB',
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    fontSize: '18px;',
    marginTop: '10px',
    cursor: 'pointer',
  },
  disabledButton: {
    // backgroundColor: theme.palette.background.paper, // '#cbcbcb',
    padding: '12px',
    width: '100%',
    border: 'none',
    borderRadius: '10px',
    fontSize: '18px;',
    marginTop: '10px',
    backgroundColor: '#cbcbcb',
    color: '#8c8c8c',
  },
  card: {
    display: 'block',
    flexGrow: 1,
    boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    flex: '1',
    margin: '0px',
    width: '310px',
    textAlign: 'left',
    [theme.breakpoints.down("xs")]: {
      width: '250px',
    },
  },
  formControl: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '12px',
    marginRight: '16px',
    '& .MuiIconButton-root': {
      padding: '0',
      paddingRight: '6px',
    },
  },
  bgHeader: {
    background: '#F7F7F7',
  },
  innerWrapper: {
    width: `calc(100% - 40px)`,
    background: theme.palette.background.paper,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textSecondary: {
    margin: '16px 0',
    color: theme.palette.text.secondary,
  },
  accept: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '10px 0',
    // margin: ${props => props.margin},
    // & .border {
    //     border-right: 3px solid gray,
    //     margin-left: 14px,
    //     flex: 1,
    //     margin-right: 20px,
    // },
    // width: ${props => props.width},
  },
  flagButton: {
    minWidth: 30,
    maxWidth: 30,
    padding: 0,
    marginRight: 15,
    marginLeft: 5,
    height: 30,
  },
  flagItem: {
    height: '20px',
    padding: 0,
    minWidth: '30px',
    maxWidth: 30,
    marginLeft: '5px',
    marginRight: '15px',
    paddingTop: '0px',
    position: 'relative',
  },
  styleCircular: {
    marginRight: '10px', width: '15px !important', height: '15px !important', color: '#FFFFFF'
  },
}));
