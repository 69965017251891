import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Flags from 'country-flag-icons/react/3x2'
import { useStyles } from './styles';
import { countryCodeConverterIso3ToIso2 } from '../../utils/countryCodeConverter';
import { IntlContext } from '../../contexts/intlContext';


const FlagItem = (props: { item: any; handleCountryChange: any; }): any => {
    const { locale } = React.useContext(IntlContext).locales;
    const {
        item,handleCountryChange
    } = props;
    const FlagComponent = Flags[`${countryCodeConverterIso3ToIso2.get(item?.code)}`.toUpperCase()];
    const classes = useStyles();
    return (
        <MenuItem style={{display:'flex', alignItems:"center"}} onClick={() => handleCountryChange(item)}>
            <div className={classes.flagItem}>
                <FlagComponent />
            </div>

            <span className="country-name">
                {item?.country_label && item?.country_label[locale] ? item?.country_label[locale] : item?.name}
            </span>

            <span className="dial-code"> {` ${item?.phone_prefix}`}</span>
        </MenuItem>
    );
}

FlagItem.defaultProps = {
};

export default FlagItem;