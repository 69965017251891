import React, { ReactElement, useRef, useState } from 'react';
import { ChildrenType } from '../utils/types';

export type Comp = {
  refBck: any;
};
type AppDataType = {
  comp: Comp | undefined;
  setComp: (comp: Comp) => void;
};

const Context = React.createContext<AppDataType>(undefined!);

export const useCompData = (): any => React.useContext(Context);

const CompProvider: React.FC<ChildrenType> = ({ children }): ReactElement => {
  const [comp, setComp] = useState({  
    refBck: useRef(null)
  });

  const value = React.useMemo(
    () => ({
      setComp,
      comp
    }),
    [
      setComp,
      comp
    ]
  )

  return (
    <Context.Provider value={value}>{children}</Context.Provider>
  );
};

export { CompProvider, Context as CompContext };
