import { StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    padding: '20px',
  },
  content: {
    margin: '16px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    background: theme.palette.background.paper,
    width: '432px',
    boxSizing: 'border-box',
    [theme.breakpoints.down("xs")]: {
      width: '300px',
    },
  },
  form: {
    display: 'contents',
  },
  disabledButton: {
    // backgroundColor: '#F2F2F2',
    background: '#f00',
  },
  button: {
    background: '#000',
    padding: '14px',
    marginTop: '40px',
    '&$disabled': {
      background: '#F2F2F2',
      color: '#B6B6B6',
    },
  },


  disabledSubmitButton: {
    background: '#F2F2F2',
    color: '#B6B6B6',
  },
  enabledSubmitButton: {
    color: 'white',
    background: '#000',
  },
  submitButton: {
    padding: '14px',
    marginTop: '40px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    border: 'none',
    width: '100%',
    fontSize: '0.875rem',
    minWidth: '64px',
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    lineHeight: '1.75',
    borderRadius: '8px',
    textTransform: 'uppercase',
    order: 0,
    cursor: 'pointer',
    margin: '0',
    display: 'inline-flex',
    outline: '0',
    position: 'relative',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
  },
  disabled: {},
  bgHeader: {
    // background:'#F7F7F7'
  },
  maxWidth: {
    width: '100%',
  },
  innerWrapper: {
    width: `calc(100% - 40px)`,
    background: theme.palette.background.paper,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  textSecondary: {
    margin: '16px 0',
    color: theme.palette.text.secondary,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    width: '45%',
    margin: '0 auto',
  },
  stepIcon: {
    color: '#cbcbcb',
  },

  stepIconActive: {
    color: '#ff7d00 !important',
  },
  stepIconCompleted: {
    color: '#27c780 !important',
  },
}));

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      borderColor: '#ff7d00',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#27c780',
    },
  },
  line: {
    borderTopStyle: 'dashed',
    borderTopWidth: '2px',
    marginRight: '5px',
  },
})(StepConnector);
