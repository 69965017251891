import React, { ReactElement, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { getLevel } from '../../api/customSelect';
import SelectLevel from './SelectLevel';
import { useConfigData } from '../../contexts/configContext';
import { IntlContext, useIntlData } from '../../contexts/intlContext';
import SelectDisabled from '../SelectCustom/SelectDisabled';

interface SelectProps {
  name: string;
  label: MultiLangType;
  // values?: {
  //   level: string;
  //   level1: string;
  //   level2: string;
  // };
  required: boolean | undefined;
  disabled: boolean | undefined;
}

const SelectPathV3: React.FC<SelectProps> = ({
  name,
  label,
  required,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { fieldState } = useController({ name });
  const { control, setValue, getValues } = useFormContext();
  const { config } = useConfigData();
  const { isRtl } = useIntlData();
  const { locale, messageLang } = React.useContext(IntlContext)?.locales;
  const labels = Translate(label) ? Translate(label).split(';') : ['level1_path', 'level2_path', 'level3_path']
  // const [selectedLastLevel, setSelectedLastLevel] = React.useState(
  //   defaultValues?.level || '',
  // );
  const [lastLevelDisabled, setLastLevelDisabled] = React.useState(true);

  const { isFetched: isFirstLevelFetched, data: level1path } = useQuery(
    ['level1_path'],
    () => getLevel(config.mapPath,locale),
  );

  const { isFetched: isSecondLevelFetched, data: level2path } = useQuery(
    ['level2_path', getValues('level1_path')],
    () => getLevel(getValues('level1_path'),locale),
    {
      enabled: !!getValues('level1_path'),
    },
  );

  const {
    isFetched: isLastLevelFetched,
    isFetching: isLastLevelFetching,
    data: lastLevel,
    refetch: refetchLastLevel,
  } = useQuery('lastLevel', () => getLevel(getValues('level2_path'),locale), {
    enabled: !!getValues('level2_path'),
  });

  const [dataLevel3, setDataLevel3] = useState([])

  useEffect(() => {
    setDataLevel3(lastLevel)
  },[lastLevel])

  useEffect(() => {
    setDataLevel3([])
  },[getValues('level2_path')])

  const handleFirstLevel = (event: any): void => {
    setValue('level1_path', (event.target as HTMLInputElement).value);
    setValue('level2_path', getValues('level2_path'));
    setValue('level3_path', getValues('level2_path'));
    setValue(name, '');
    setLastLevelDisabled(true);
  };
  const handleSecondLevel = (event: any): void => {
    setValue('level2_path', (event.target as HTMLInputElement).value);
    setValue('level3_path', '');
    setValue(name, '');
    refetchLastLevel();
    setLastLevelDisabled(false);
  };
  const handleLastLevel = (event: any): void => {
    // setSelectedLastLevel((event.target as HTMLInputElement).value);
    setValue('level3_path', (event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    if (getValues('level3_path')) setLastLevelDisabled(false)
  })

  const communeDisabled =
    lastLevelDisabled === false
      ? isLastLevelFetching || !isLastLevelFetched
      : true;

  return (
    <>
      <SelectLevel
        name='level1_path'
        label={isRtl ? labels[2] : labels[0]}
        isSuccess={isFirstLevelFetched}
        selectedLevel={level1path?.length ? getValues('level1_path') : ''}
        data={level1path || []}
        handleLevel={handleFirstLevel}
        required={required}
        disabled={disabled}
      />
      <SelectLevel
        name='level2_path'
        label={labels[1]}
        isSuccess={isSecondLevelFetched}
        selectedLevel={level2path?.length ? getValues('level2_path'): ''}
        data={level2path || []}
        handleLevel={handleSecondLevel}
        required={required}
        disabled={disabled}
      />
      {isLastLevelFetched ? (
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='custom-select-outlined-label'>
            {isRtl ? labels[0] : labels[2]}
          </InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                labelId='custom-select-outlined-label'
                id='custom-select-outlined'
                value={getValues('level3_path') || value?.level3_path || getValues(name)?.level3_path  || ''}
                onChange={(e) => {
                  handleLastLevel(e);
                  onChange(e);
                  setValue(name, {
                    level1_path: getValues('level1_path'),
                    level2_path: getValues('level2_path'),
                    level3_path: e.target.value
                  });
                }}
                onBlur={onBlur}
                label={isRtl ? labels[0] : labels[2]}
                name={name}
                ref={ref}
                // disabled={disabled || communeDisabled}
              >
                {/* <MenuItem value=''>
                  <em>None</em>
                </MenuItem> */}
                {dataLevel3 
                  ? React.Children.toArray(
                      lastLevel.map((item: any) => (
                        <MenuItem value={item.path}>
                          {item.label
                            ? Translate(item.label, item.name)
                            : item.name}
                        </MenuItem>
                      )),
                    )
                  : <div style={{textAlign: 'center'}}>
                      <CircularProgress size={35} />
                    </div>}
              </Select>
            )}
            rules={{ required }}
          />
          {fieldState.invalid && (
            <Typography className={classes.error}>
              {fieldState.invalid && `${isRtl ? labels[0] : labels[2]} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
            </Typography>
          )}
        </FormControl>
      ) : (
        <SelectDisabled label={label} name={name} fieldState={fieldState} required={required} disable={getValues('level2_path')}/>
      )}
    </>
  );
};

export default SelectPathV3;
