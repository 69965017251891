import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  background: {
    width: '100%',
    height: `${window.innerHeight}px`,
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zIndex: 0,
    overflowY: 'hidden',
  },
  ctnBcg: {
    position: 'absolute',
    width: '100%',
    zIndex: -1,
    ovevflowY: 'hidden',
  },
  effectBcg: {
    width: '100%',
    background: `linear-gradient(180deg, transparent, #6316DB)`,
    position: 'absolute',
    zIndex: 1
  },
  styleFormSignIn: {
    position: 'relative',
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '48px',
    gap: '10px',
    width: window.innerWidth / 2.7,
    height: 'auto',
    background: '#FFFFFF',
    '@media (max-width:800px)': {
      width: '350px',
    },
    '@media (max-width:500px)': {
      width: 'auto',
      padding: '20px',
      borderRadius: '0px',
      background: '#6316DB',
    },
    borderRadius: '24px',
  },

  styleFormSignIn2: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '0px',
    gap: '10px',
    width: '100%',
    height: 'auto',
    background: '#FFFFFF',
    '@media (max-width:500px)': {
      background: 'transparent',
      width: '100%',
    },
    borderRadius: '24px',
  },
  effectBcg2: {
    display: 'none',
    position: 'absolute',
    zIndex: 1,
    width: `${window.innerWidth}px`,
    '@media (max-width:500px)': {
      display: 'block',
      zIndex: 0,
      background: `linear-gradient(180deg, transparent, #6316DB)`,
    },
  },
  ctnTxtSignIn: {

  },
  txtSignIn: {
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '31px',
    letterSpacing: '-0.342857px',
    color: '#191919',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    position: 'relative',
    zIndex: 2,
    '@media (max-width:500px)': {
      color: 'white',
    }
  },
  ctnTxtNumPhone: {
    marginTop: '20px',
    marginBottom: '5px',
    '@media (max-height:800px)': {
      marginTop: '10px',
    },
    '@media (max-height:700px)': {
      marginTop: '8px',
    },
    '@media (max-width:500px)': {
      marginTop: '25px',
    },
  },
  txtNumPhone: {
    width: '142px',
    height: '20px',
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    color: 'black !important',
    '@media (max-width:500px)': {
      color: '#FFFFFF !important',
      position: 'relative',
      zIndex: 2,
    }
  },
  ctnInputPhone: {

  },
  inputPhone: {

  },
  flagButton: {
    minWidth: 30,
    maxWidth: 30,
    padding: 0,
    marginLeft: 5,
    height: 30,
    position: 'relative',
    zIndex: 1,
  },
  linkSignup: {
    color: '#9747FF',
    float: 'right',
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'none',
    border: '1px solid #C4C0D3',
    padding: '10px',
    margin: '-10px 0px 0px 0px',
    borderRadius: '18px',
  },
  button: {
    padding: '12px',
    width: '100%',
    background: '#6316DB',
    '&:hover': {
      background: "#430d98",
    },
    color: 'white',
    border: 'none',
    borderRadius: '10px',
    fontSize: '17px;',
    marginTop: '10px',
    cursor: 'pointer',
    fontFamily: 'Gellix',
    fontWeight: 700,
    borderRdius: '30px',
    '@media (max-width:500px)': {
      background: 'white',
      color: '#6316DB',
      '&:hover': {
        background: 'white',
        color: '#6316DB',
      },
      '&.MuiButton-contained.Mui-disabled': {
        background: '#a1a1a1',
        color: 'white',
      }
    }
  },
  imgBrandArrow: {
    position: 'absolute',
    zIndex: 1,
    display: 'block',
    '@media (max-width:500px)': {
      display: 'none'
    }
  },
  ctnPhoneMenu: {
    border: '1px solid #F2F2F2',
    height: '30px',
    width: 'auto',
    borderRadius: '5px',
    backgroundColor: '#F2F2F2',
    display: 'inline-flex',
    cursor: 'pointer'
  },
  btn_submit: {
    marginTop: window.innerHeight < 800 ? '0rem' : '1rem',
    '@media (max-width:500px)': {
      position: 'relative',
      zIndex: 1,
    }
  },
  ctnLevelpath: {
    marginTop: '25px',
    marginBottom: '32px',
    '@media (max-height:700px)': {
      marginTop: '15px',
      marginBottom: 15,
      position: 'relative', zIndex: 1
    },
    '@media (max-width:500px)': {
      position: 'relative', zIndex: 1
    }
  },
  styleLblInput: {
    color: 'black !important',
    '@media (max-width:500px)': {
      color: '#FFFFFF !important',
    }
  },
  selectLevelpath: {
    width: "100%",
    backgroundColor: 'white',
    height: '45px',
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #aeaeae",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "&:hover": {
      "&& fieldset": {
        borderColor: "#aeaeae !important"
      }
    },
    "&": {
      "&& fieldset": {
        border: "1px solid #aeaeae",
      },
    },
    "& .MuiCheckbox-root": {
      color: 'white !important',
      backgroudColor: 'white !important',
    },
    '@media (max-width:500px)': {
      height: '42px',
    }
  },
  customIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: '20px !important',
    },
  },
  txtLabel: {
    fontSize: '15px',
    transform: 'translate(14px, 15px) scale(1)',
    "&.MuiFormLabel-root.Mui-focused": {
      color: "#aeaeae",
    },
    '@media (max-width:500px)': {
      "&.MuiFormLabel-root.Mui-focused": {
        color: "transparent",
      },
    }
  },
  msg_error: {
    textAlign: 'center',
    marginTop: 20,
  },
  styleErrorMsg: {
    background: '#F7F3F8',
    color: 'black',
    padding: '22px 11px 22px 11px',
    fontSize: '15px',
    borderRadius: '8px',
    fontWeight: 300,
    marginTop: '10px',
    '@media (max-height:700px)': {
      padding: '20px 11px 20px 11px',
      fontSize: '13px',
    },
    '@media (max-width:500px)': {
      padding: '20px 11px 20px 11px',
    }
  },
  ctnMsgError: {
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Gellix',
    color: '#6316DB',
    '@media (max-height:700px)': {
      padding: '11px 16px 11px 11px',
    },
    '@media (min-width:1200px)': {
      padding: 16
    }
  },

  brandArrowLoginForm: {
    display: 'none',
    '@media (max-width:600px)': {
      display: 'block',
    }
  },
  box_msg: {
    color: 'black !important',
    border: '1px solid #07b020',
    padding: '15px',
    fontSize: '18px',
    background: '#daffd9',
    fontWeight: 400,
    borderRadius: '8px',
    textAlign: 'center',
    marginTop: '10px',
    position: 'relative', zIndex: 3
  },
  txtNewInput: {
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'black !important',
    '@media (max-width:500px)': {
      color: '#FFFFFF !important',
      position: 'relative',
    }
  },
  ctnNewsInputs: {
    marginTop: '0px',
    '@media (max-width:500px)': {
      marginTop: '15px',
      position: 'relative', zIndex: 1
    }
  },
  newInput: {
    marginLeft: '-10px'
  },
  hasDrivingLicenceInput: {
    marginLeft: '-10px'
  },
  ctnStyleFormSignIn: {
  },
  card: {
    maxWidth: "40%",
    minHeight: "20vh",
    display: "flex",
    alignItems: "center"
  },
  styleCircular: {
    marginRight: '10px',
    width: '20px !important',
    height: '20px !important',
    color: '#6316DB'
  },
  tabCheckBoxOne: {
    marginTop: '0px',
    marginLeft: '0px',
    '@media (max-height:700px)': {
      marginTop: '-5px',
      marginLeft: '-5px'
    }
  },
  tabCheckBoxTwo: {
    marginTop: '0px',
    marginLeft: '0px',
    '@media (max-height:700px)': {
      marginTop: '-15px',
      marginLeft: '-5px'
    }
  }
}));

