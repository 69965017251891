import { Button, Checkbox, CircularProgress, FormControl, IconButton, makeStyles, Menu, withStyles, InputLabel, MenuItem, Select, createMuiTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { Image } from 'react-image-and-background-image-fade'
import { FaCaretDown } from "react-icons/fa";
import MaskedInput from 'react-maskedinput'
import Flags from 'country-flag-icons/react/3x2';
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSubmit } from "../../hooks/useSubmit";
import { IntlContext, useIntlData } from '../../contexts/intlContext';
import { countryCodeConverterIso3ToIso2 } from "../../utils/countryCodeConverter";
import { useStyles } from './styles';
import FlagItem from "../Auth/FlagItem";
import { useValudData } from "../../contexts/valudContext";
import Loader from "../../components/Loader/Loader";
import { useAuthData } from "../../contexts/authContext";
import { useHeaderData } from "../../contexts/headerContext";
import { useCompData } from "../../contexts/compComtext";
import brandArrow from '../../assets/images/brand-arrow.png';
import { countriesMaghreb, ONBOARDING_API } from "../../utils/constants";

interface IFormProps {
    isLoading: boolean,
    onSubmit: (data: Record<string, any>) => void;
    blockedPhoneMessage: string;
    codeSent: boolean;
    codeStatus: number;
    activeInput: boolean;
    charge: any;
    stile: any;
    bgImg: any;
    heightScreen: any;
}

const FormSignIn: React.FC<IFormProps> = ({ isLoading, onSubmit, blockedPhoneMessage, codeSent, codeStatus, activeInput, charge, stile, bgImg, heightScreen }): ReactElement => {

    const { header } = useHeaderData()
    const { comp } = useCompData()
    const { valud } = useValudData()
    const { locale, messageLang } = React.useContext(IntlContext).locales;
    const scope = 'signin';
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const { countries, selectedCountry, setSelectedCountry } = useAuthData();
    const FlagComponent: any = Flags[`${countryCodeConverterIso3ToIso2.get(selectedCountry?.code)}`.toUpperCase()];
    const navigate = useHistory();
    const [phone, setPhone] = useState('')
    const [isCarOwner, setIsCarOwner] = useState(false);
    const [hasDrivingLicence, setHasDrivingLicence] = useState(false)
    const [digitMissing, setDigitMissing] = useState(false)
    const [activeDetecteDigitMissing, setActiveDetecteDigitMissing] = useState(false)
    const [dialCode, setDialCode] = useState('')
    const [nbrMaskPhone, setNbrMaskPhone] = useState(0)
    const [lengthNumberPhone, setLengthNumberPhone] = useState(0)
    const [activeControlNbrPhone, setActiveControlNbrPhone] = useState(false)
    const [msgAlert, setMsgAlert] = useState('')
    const [countriess, setCountriess] = useState(countries)
    const [levelpath, setLevelpath] = useState('')
    const refLogin = useRef(document.createElement("div"))
    const { isRtl } = useIntlData();
    const refBcg = useRef(document.createElement("div"))
    const [taillForm, setTilleForm] = useState<any>()
    const taillScreen = useState<any>()

    const {
        isLoading: loadingRequestLevePath,
        isSuccess: successRequestLevePath,
        isError: errorRequestLevePath,
        data: LevePathData,
        error: codeErrorLevePath,
        mutate: mutateRequestLevePath,
    } = useSubmit({
        path: `${ONBOARDING_API}/public/sublevels`,
    });

    useEffect(() => {
        mutateRequestLevePath({ path: selectedCountry?.map_path, lang: locale! })
    }, [selectedCountry?.map_path])

    const useStyles1 = makeStyles({
        root: {
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            bottom: 0,
            position: "initial",
            justifyContent: 'center',
            minHeight: activeInput ? 'auto' : window.innerHeight - header.height,
            alignItems: 'center',
            '@media (max-width:500px)': {
                position: [`/${locale}`,`/logout`].includes(window.location.pathname) ? "initial" : "fixed",
                justifyContent: window.location.pathname === `/${locale}/signin` ? 'end' : 'xs-center',
                alignItems: 'normal',
                minHeight: 'auto',
            }
        },
    });

    const classes1 = useStyles1();

    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        formState: { errors, isSubmitting, isDirty, isValid },
        control,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            phone: '',
            level1_path: '',
            accept: '',
            dialCode: '',
            isCarOwner: false,
            hasDrivingLicence: false
        },
    });

    useEffect(() => {
        setValue('level1_path', levelpath)
    }, [levelpath])

    const checkPhoneMissingDigit = (): any => {
        if (nbrMaskPhone === lengthNumberPhone && lengthNumberPhone > 0) setActiveControlNbrPhone(true)
        if (lengthNumberPhone < nbrMaskPhone && lengthNumberPhone > 1 && activeControlNbrPhone)
            setDigitMissing(true)
        else {
            setDigitMissing(false)
        }
    }

    const handleCountryChange = (value: any): any => {
        reset({ ...getValues, "dialCode": `${value?.phone_prefix}` })
        setSelectedCountry(value);
        setAnchorEl(undefined);
        setIsCarOwner(false)
        setHasDrivingLicence(false)
        checkPhoneMissingDigit()
        setLevelpath('')
    };

    const nbrDigitValide = (): any => {
        if (lengthNumberPhone === 9) {
            setDigitMissing(false)
            setActiveDetecteDigitMissing(true)
        } else if (activeDetecteDigitMissing)
            setDigitMissing(true)
    }

    useEffect(() => {
        if (selectedCountry?.phone_prefix !== dialCode)
            setPhone('')
    }, [selectedCountry, dialCode])

    useEffect(() => {
        reset({ ...getValues, "dialCode": `${selectedCountry?.phone_prefix}` })
    }, [getValues, reset, selectedCountry])

    useEffect(() => {
        let Phone: string[] = [...phone];
        Phone = Phone.filter(x => x !== '_' && x !== ' ')

        if (selectedCountry) {
            let Mask: string[] = [...selectedCountry?.phone_mask];
            Mask = Mask.filter(x => x !== '_' && x !== ' ')

            setNbrMaskPhone(Mask.length)
        }
        setLengthNumberPhone(Phone.length)
    }, [phone, isCarOwner, hasDrivingLicence, lengthNumberPhone])

    useEffect(() => {
        nbrDigitValide()
    }, [lengthNumberPhone])

    useEffect(() => {
        setMsgAlert(blockedPhoneMessage)
    }, [blockedPhoneMessage])

    useEffect(() => {
        setValue('phone', phone)
        if ((digitMissing && window.location.pathname === `/${locale}/signin`) || phone.toString().trim() === '')
            setMsgAlert('')
    }, [phone, digitMissing])

    useEffect(() => {
        checkPhoneMissingDigit()
    }, [lengthNumberPhone])

    useEffect(() => {
        if (locale === 'ar') {
            setCountriess(countriess.filter(x => countriesMaghreb.includes(x?.code)));
        } else {
            setCountriess(countries);
        }
    }, [selectedCountry?.code])

    const handleChangeValue = (e): any => {
        setMsgAlert('')
        if (e.target.name === 'level1_path') {
            setLevelpath(e.target.value)
            setValue('level1_path', e.target.value)
        } else if (e.target.name === 'phone') {
            setPhone(e.target.value)
        } else if (e.target.name === 'isCarOwner') {
            setIsCarOwner(e.target.checked)
        } else if (e.target.name === 'hasDrivingLicence') {
            setHasDrivingLicence(e.target.checked)
        } else if (e.target.id === 'txtInputOne') {
            if (isCarOwner) {
                setIsCarOwner(false)
                setValue('isCarOwner', false);
            } else {
                setIsCarOwner(true)
                setValue('isCarOwner', true);
            }
        } else if (e.target.id === 'txtInputTwo') {
            if (hasDrivingLicence) {
                setHasDrivingLicence(false)
                setValue('hasDrivingLicence', false);
            } else {
                setHasDrivingLicence(true)
                setValue('hasDrivingLicence', true)
            }
        }
    }

    const checkBoxStyles = (): any => ({
        root: {
            color: window.innerWidth < 500 ? 'white' : '#aeaeae',
            '&$checked': {
                color: window.innerWidth < 500 ? 'white' : '#6316DB',
            },
        },
        checked: {},
    })

    const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

    useEffect(() => {
        if (msgAlert)
            setTilleForm(((window.innerHeight - header?.height) - refBcg?.current?.clientHeight) + 20);
        else setTilleForm(((window.innerHeight - header?.height) - refBcg?.current?.clientHeight) + 5);
    }, [msgAlert, header, refBcg]);

    useEffect(() => {
        if (anchorEl)
            comp.refBck.style.overflow = 'hidden';
        else {
            comp.refBck.style.overflowY = navigator.userAgent.match(/firefox|fxios/i) ? 'auto' : 'overlay';
            comp.refBck.style.overflowX = 'hidden';
        }
    }, [anchorEl, comp.refBck])

    return (
        <div ref={refLogin} style={{ height: activeInput ? 'auto' : `${taillScreen}px` }} className={!activeInput ? classes.ctnStyleFormSignIn : ''}>
            <div className={classes.effectBcg} style={{
                height: window.innerWidth > 500 ? heightScreen : '100%',
                display: window.innerWidth > 500 ? 'block' : 'none'
            }} />
            {window.location.pathname === `/${locale}/signin` && <img className={classes.imgBrandArrow} alt=''
                src={brandArrow}
                style={{
                    right: 0,
                    height: `${window.innerHeight - header?.height}px`,
                    width: `${((window.innerWidth) * 0.3)}px`,
                    marginRight: `${((window.innerWidth) * 0.02)}px`,
                }}
            />}
            <div className={classes.ctnBcg} style={{ height: heightScreen }}>
                {charge && <Image style={stile}
                    src={bgImg}
                    lazyLoad
                    width='100%'
                    height={heightScreen}
                    className={classes.background}
                />}

            </div>
            {window.location.pathname === `/${locale}/signin` && window.innerWidth < 500 && <div className={classes.effectBcg2} style={{ height: `${taillForm}px` }} />}
            <Grid ref={refBcg}
                className={classes1.root}
                spacing={0}
            >
                <div className={activeInput ? classes.styleFormSignIn2 : classes.styleFormSignIn}>
                    {!activeInput && (<div className={classes.ctnTxtSignIn}>
                        <span className={classes.txtSignIn}>{JSON.parse(JSON.stringify(messageLang))['signin.sign-in']}</span>
                    </div>)}
                    <div className={classes.ctnTxtNumPhone}>
                        <span className={classes.txtNumPhone}>{JSON.parse(JSON.stringify(messageLang))['general-info.phone-number']}</span>
                    </div>
                    <div className={classes.ctnInputPhone}>
                        <form id="formSignup" onSubmit={handleSubmit(onSubmit)} >
                            {window.location.pathname === `/${locale}/signin` && window.innerWidth < 500 && <img className={classes.brandArrowLoginForm} alt='' src={brandArrow}
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: `-${taillForm + header?.height}px`,
                                    margin: locale === 'ar' ? '0px -320px 0px 0px' : '0px 0px 0px 60px'
                                }} />}
                            <Controller
                                name='phone'
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <>
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: 5,
                                                borderRadius: 8,
                                                border: `1px solid ${codeSent ? '#84dfb6' : '#B2B2B2'}`,
                                                alignItems: 'center',
                                                background: 'white',
                                                direction: 'ltr',
                                                position: 'relative',
                                                zIndex: 1,
                                            }}
                                        >
                                            <div className={classes.ctnPhoneMenu} onClick={(e) => setAnchorEl(e.currentTarget)} role="presentation">
                                                <IconButton
                                                    className={classes.flagButton}
                                                    onClick={(e) => setAnchorEl(e.currentTarget)} style={{ marginRight: '0px', marginLeft: '5px' }}
                                                >
                                                    {Boolean(FlagComponent) && <FlagComponent className="margin" style={{ width: '30px' }} />}
                                                </IconButton>
                                                <p
                                                    style={{
                                                        borderRadius: 20,
                                                        padding: 5,
                                                        fontSize: 14,
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        marginTop: '1px'
                                                    }}
                                                >
                                                    {selectedCountry?.phone_prefix}
                                                </p>
                                                <FaCaretDown style={{ margin: '6px 6px 0px 0px' }} />
                                            </div>
                                            <Menu
                                                id="country-menu"
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={() => setAnchorEl(undefined)}
                                            >
                                                {!countriess && Boolean(anchorEl) ? (<Loader />) :
                                                    React.Children.toArray(
                                                        countriess?.map((item: any) => (
                                                            <FlagItem
                                                                item={item}
                                                                handleCountryChange={handleCountryChange}
                                                            />
                                                        )),
                                                    )}
                                            </Menu>
                                            <MaskedInput
                                                mask={selectedCountry?.phone_mask ? selectedCountry?.phone_mask : '111 111 111'}
                                                className='no-outline'
                                                type="tel"
                                                placeholder=''
                                                id='my-input-id'
                                                name='phone'
                                                style={{
                                                    border: 'none',
                                                    fontSize: '14px',
                                                    marginLeft: '5px',
                                                    marginRight: '5px',
                                                    outline: 'none',
                                                    direction: 'ltr',
                                                    width: '100%',
                                                }}
                                                onBlur={checkPhoneMissingDigit}
                                                onChange={(event: { target: HTMLInputElement; }) => {
                                                    handleChangeValue(event)
                                                    onChange(event);
                                                    setValue('phone', (event.target as HTMLInputElement).value);
                                                }}
                                                onClick={(event: any) => { handleChangeValue(event) }}
                                                ref={ref}
                                                value={phone}
                                            />
                                        </div>
                                    </>
                                )}
                                rules={{ required: true }}
                            />

                            {errors.phone && !digitMissing && (
                                <span style={{ color: 'tomato', fontSize: 12 }}>
                                    {JSON.parse(JSON.stringify(messageLang))['Phone.is.required']}
                                </span>
                            )}
                            {digitMissing && (
                                <span style={{ color: 'tomato', fontSize: 12 }}>
                                    {JSON.parse(JSON.stringify(messageLang))['the.phone.number.has.missing.digits']}
                                </span>
                            )}

                            {activeInput && (
                                <>
                                    <div className={classes.ctnLevelpath}>
                                        <div className={classes.styleLblInput} style={{ fontFamily: 'Gellix', fontSize: '13px', fontWeight: 700, left: isRtl ? 'unset' : 0, marginBottom: 8 }}>
                                            {JSON.parse(JSON.stringify(messageLang))['level.path']}
                                        </div>
                                        <FormControl
                                            variant="outlined"
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel className={classes.txtLabel} id="labid">
                                                {JSON.parse(JSON.stringify(messageLang))['level.path']}
                                            </InputLabel>
                                            <Select
                                                id="levelPath"
                                                name="level1_path"
                                                labelId="labid"
                                                label={JSON.parse(JSON.stringify(messageLang))['level.path']}
                                                style={{ borderRadius: '8px' }}
                                                className={classes.selectLevelpath}
                                                variant="outlined"
                                                value={levelpath}
                                                onChange={handleChangeValue}
                                            >
                                                {!LevePathData?.data?.levels ? (<Loader />) :
                                                    React.Children.toArray(
                                                        LevePathData?.data?.levels.map((item: any) => (
                                                            <MenuItem value={item?.path}>{item?.label[locale]}</MenuItem>
                                                        )),
                                                    )}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className={classes.ctnNewsInputs}>
                                        <Controller
                                            name='isCarOwner'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref: hdl } }) => (
                                                <div className={classes.newInput}>
                                                    <table className={classes.tabCheckBoxOne}>
                                                        <tr>
                                                            {/* eslint-disable-next-line */}
                                                            <td>
                                                                <CustomCheckbox checked={isCarOwner}
                                                                    className={classes.customIcon}
                                                                    onChange={(event) => {
                                                                        handleChangeValue(event)
                                                                        onChange(event);
                                                                        setValue('isCarOwner', (event.target as HTMLInputElement).checked);
                                                                    }} name="isCarOwner" />
                                                            </td>
                                                            <td>
                                                                <span role="presentation" onClick={handleChangeValue} id="txtInputOne" className={classes.txtNewInput}>{JSON.parse(JSON.stringify(messageLang))['i.have.a.valid.driving.license']}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )}
                                        />
                                        <Controller
                                            name='hasDrivingLicence'
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref: hdl } }) => (
                                                <div className={classes.newInput}>
                                                    <table className={classes.tabCheckBoxTwo}>
                                                        <tr>
                                                            {/* eslint-disable-next-line */}
                                                            <td>
                                                                <CustomCheckbox checked={hasDrivingLicence}
                                                                    className={classes.customIcon}
                                                                    onChange={(event) => {
                                                                        handleChangeValue(event)
                                                                        onChange(event);
                                                                        setValue('hasDrivingLicence', (event.target as HTMLInputElement).checked);
                                                                    }} name="hasDrivingLicence" />
                                                            </td>
                                                            <td>
                                                                <span role="presentation" onClick={handleChangeValue} id="txtInputTwo" className={classes.txtNewInput}>{JSON.parse(JSON.stringify(messageLang))['i.own.a.vehicle']}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )}
                                        />
                                    </div>
                                </>
                            )}
                            {isSubmitting ? (
                                <Loader />
                            ) : null}
                            {codeSent ? (
                                <><div style={{ position: 'relative', zIndex: 3, minHeight: window.innerWidth < 500 ? '60px' : '20px' }} />
                                    <div className={classes.box_msg}>
                                        <small style={{ textAlign: 'center' }}>
                                            <FormattedMessage id={`${scope}.code-sent`} />
                                        </small>
                                    </div></>
                            ) : (
                                <div className={classes.btn_submit}>
                                    <div style={{ minHeight: window.location.pathname === `/${locale}/signin` ? '20px' : '0px' }}>
                                        {(String(msgAlert).trim() !== '' && msgAlert !== undefined) && (
                                            <div className={classes.styleErrorMsg}>
                                                <span className={classes.ctnMsgError}>{msgAlert}</span>
                                                {window.location.pathname === `/${locale}` && codeStatus === 400
                                                    ? <span role="presentation" className={classes.linkSignup} onClick={() => navigate.push(`/${locale}/signin`)}>
                                                        {window.innerWidth > 500 ? JSON.parse(JSON.stringify(messageLang))['click.here.to.login'] : <span style={{ fontSize: '12px' }}>{JSON.parse(JSON.stringify(messageLang))['click.here.to.login2']}</span>}
                                                    </span>
                                                    : <></>}
                                                {window.location.pathname === `/${locale}/signin` && (codeStatus === 404)
                                                    ? <span role="presentation" className={classes.linkSignup} onClick={() => navigate.push(`/${locale}`)}>
                                                        {window.innerWidth > 500 ? JSON.parse(JSON.stringify(messageLang))['you.must.register.first'] : <span style={{ fontSize: '12px' }}>{JSON.parse(JSON.stringify(messageLang))['you.must.register.first2']}</span>}
                                                    </span>
                                                    : <></>}
                                            </div>
                                        )}
                                    </div>
                                    {activeInput ?
                                        <Button
                                            id="btnSignupSubmit"
                                            variant='contained'
                                            color='primary'
                                            style={{
                                                height: '48px', fontFamily: 'Gellix', borderRadius: '30px',
                                            }}
                                            className={classes.button}
                                            type='submit'
                                            disabled={isLoading || !isDirty || !isValid || !valud.good || !(levelpath && isCarOwner && hasDrivingLicence) || digitMissing || !(lengthNumberPhone === nbrMaskPhone)}
                                        >
                                            <div id="btnSignupSubmit" style={{width: '100%', border: '1px solid transparent', height: '48px', position: 'absolute', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                            {isLoading &&
                                                <CircularProgress className={classes.styleCircular} />} <span id="btnSignupSubmit" style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['signin.send'].substring(0, 1)}</span><span id="btnSignupSubmit" style={{ textTransform: "lowercase" }}>{JSON.parse(JSON.stringify(messageLang))['signin.send'].substring(1, JSON.parse(JSON.stringify(messageLang))['signin.send'].length)}</span>
                                            </div>
                                        </Button>
                                        :
                                        <><Button
                                            variant='contained'
                                            color='primary'
                                            style={{
                                                height: '48px', fontFamily: 'Gellix', borderRadius: '30px',
                                            }}
                                            className={classes.button}
                                            type='submit'
                                            disabled={!isDirty || !isValid || digitMissing || !(lengthNumberPhone === nbrMaskPhone)}
                                        >
                                            <div id="btnSigninSubmit" style={{width: '100%', border: '1px solid transparent', height: '48px', position: 'absolute', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                            {isLoading &&
                                                <CircularProgress className={classes.styleCircular} />} {locale === 'ar' ? <><span id="btnSigninSubmit" style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['home.login']}</span></> : <><span id="btnSigninSubmit" style={{ textTransform: "capitalize" }}>{JSON.parse(JSON.stringify(messageLang))['home.login'].substring(0, 1)}</span><span id="btnSigninSubmit" style={{ textTransform: "lowercase" }}>{JSON.parse(JSON.stringify(messageLang))['home.login'].substring(1, JSON.parse(JSON.stringify(messageLang))['home.login'].length)}</span></>}
                                            </div>
                                        </Button>
                                            <div style={{ marginTop: '30px' }} />
                                        </>
                                    }
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </Grid>
        </div>
    )
}

export default FormSignIn;
