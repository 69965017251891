import React, { ReactElement, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  CircularProgress
} from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { getBrands, getModels } from '../../api/customSelect';
import SelectLevel from './SelectLevel';
import SelectDisabled from '../SelectCustom/SelectDisabled';
import { IntlContext, useIntlData } from '../../contexts/intlContext';

interface SelectProps {
  name: string;
  label: MultiLangType;
  required: boolean | undefined;
  disabled: boolean | undefined;
}

const SelectBrand: React.FC<SelectProps> = ({
  name,
  label,
  required,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const { fieldState } = useController({ name });
  const { control, reset, setValue, getValues } = useFormContext();
  const { isRtl } = useIntlData();
  const [donnees, setDonnees] = useState<any>();
  const [marques, setMarques] = useState<any>();
  // console.log(`🚀 ~ getValues(${name})`, getValues(name));
  // console.log('🚀 ~ getValues(brand)', getValues('brand'));

  const handleBrand = (event: any): void => {
    reset({ ...getValues(), 'marque': (event.target as HTMLInputElement).value });
    setValue(name, '');
  };
  const handleModel = (event: any): void => {
    setValue(name, (event.target as HTMLInputElement).value);
  };

  const { isFetched: isBrandFetched, data: brand } = useQuery(['marque'], () =>
    getBrands(),
  );

  const { isFetched: isModelFetched, data: models } = useQuery(
    ['model', getValues('marque')],
    () => getModels(getValues('marque')),
    {
      enabled: !!getValues('marque'),
    },
  );

  const brandRef = React.useRef(null);

  return (
    <>

      <SelectLevel
        name={name}
        label={isRtl ? Translate(label).split(';')[1] : Translate(label).split(';')[0]}
        isSuccess={isBrandFetched}
        selectedLevel={brand?.length ? getValues('marque') : ''}
        data={(brand && brand?.length) ? brand : []}
        handleLevel={handleBrand}
        ref={brandRef}
        required={required}
        disabled={disabled || !(brand && brand?.length)}
      />

      {models && models.length ? (
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='custom-select-outlined-label'>
            {isRtl ? Translate(label).split(';')[0] : Translate(label).split(';')[1]}
          </InputLabel>
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                labelId='custom-select-outlined-label'
                id='custom-select-outlined'
                value={getValues(name)?.model ? getValues(name)?.model : value}
                onChange={(e) => {
                  handleModel(e);
                  onChange(e);
                  setValue(name, {
                    marque: getValues('marque'),
                    model: e.target.value,
                  });
                }}
                onBlur={onBlur}
                label={isRtl ? Translate(label).split(';')[0] : Translate(label).split(';')[1]}
                name={name}
                ref={ref}
                disabled={disabled || !isModelFetched || !(brand && brand?.length)}
              >
                {models && models.length && <MenuItem value=''>
                  <em>{JSON.parse(JSON.stringify(messageLang))['none.trad']}</em>
                </MenuItem>}
                {React.Children.toArray(
                  models.map((item: any, index: any) => (
                    <MenuItem value={item.model}>
                      {item.model
                        ? Translate(item.model, item.model)
                        : item.model}
                    </MenuItem>
                  )),
                )}
              </Select>
            )}
            rules={{ required }}
          />
          {!getValues(name)?.model && (
            <Typography className={classes.error}>
              {fieldState.invalid && `${isRtl ? Translate(label).split(';')[0] : Translate(label).split(';')[1]} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
            </Typography>
          )}
        </FormControl>
      ) : (
        <SelectDisabled label={label} name={name} fieldState={fieldState} required={required} disable={getValues('marque')} />
      )}
    </>
  );
};

export default SelectBrand;