import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogContainer: {
    padding: '42px',
    textAlign: 'center',
  },
  container: {
    padding: '20px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  image: {
    width: '100',
    padding: '25px 0px 10px',
  },
  actions: {
    display: 'felx',
    flexDirection: 'column',
    margin: '0 auto',
    // width: '60%',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    textAlign: 'center',
    background: theme.palette.background.paper,
    width: '432px',
    boxSizing: 'border-box',
    '& > *': {
      margin: '12px 0',
      width: '100%',
    },
  },
  content: {
    fontWeight: 'normal',
    fontSize: '14px',
  },
  button: {
    // width: '100%',
    // margin: '20px 0',
  },
  link: {
    color: '#545454',
    textDecoration: 'none',
    opacity: '0.64',
    transition: '.2s',
    '&:hover': {
      opacity: '1',
    },
  },
}));
