import React, { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { FormControl } from '@material-ui/core';
import useStyles from './styles';
import CheckBoxGroup from '../CheckBoxGroup';
import { FIELD_TYPE } from '../../utils/constants';
import { IDateRangeType, MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import TextFieldCustom from '../TextFieldCustom';
import RadioButtonsCustom from '../RadioGroupCustom';
import SelectCustom from '../SelectCustom';
import SelectVehicleTypeCustom from '../SelectVehicleTypeCustom';
import DropzoneCustom from '../DropzoneCustom';
import SelectPathV3 from '../SelectPathLevel';
import SelectColor from '../SelectColor';
import SelectBrand from '../SelectBrand';
import DatePickerCustom from '../DateFieldCustom';
import SelectYear from '../SelectYear';
import SwitchCustom from '../SwitchCustom';
import { useConfigData } from '../../contexts/configContext';

interface Options {
  id: string;
  value: string;
  viewValue: MultiLangType;
}
interface IField extends IDateRangeType {
  id: string;
  type: string;
  name: string;
  value: string;

  options: Options[];
  required: boolean;

  endDate: boolean;
  entity?: string;
  isStatic?: boolean;
  label: MultiLangType;
  startDate?: boolean;
  uploadFromOnboarding?: boolean;
  entityTypes: string[];
}
interface GroupFieldsProps {
  title: any;
  subtitle: any;
  inputs: IField[];
  canEditFields:boolean;
}

const GroupFields: React.FC<GroupFieldsProps> = ({
  title,
  subtitle,
  inputs,
  canEditFields,
}): ReactElement => {
  const classes = useStyles();
  const { selectedVehicleType } = useConfigData();

  const shouldAppearInOnboarding = (): boolean | undefined => {
    const arrayOfAvailableDocs = inputs.filter(item => item.type === FIELD_TYPE.DOCUMENT);
    if (inputs.length > arrayOfAvailableDocs.length) return true;
    const shouldAppear = arrayOfAvailableDocs.map(input => input.uploadFromOnboarding).reduce((acc = false, item) => acc || item)
    return shouldAppear;
  }

  return (
    <>
      {shouldAppearInOnboarding() ?
        <div className={classes.container}>
          <div className={classes.card}>
            <Typography className={classes.title}>{Translate(title)}</Typography>
            {subtitle ? (
              <Typography className={classes.subtitle}>
                {Translate(subtitle)}
              </Typography>
            ) : (
              ''
            )}

            <FormControl className={classes.fieldset} component='fieldset'>
              {React.Children.toArray(
                inputs.filter((item: IField) => !item.entityTypes
                  || item.entityTypes.length === 0
                  || item.entityTypes.includes(selectedVehicleType))
                  .map((item: IField) => {
                  switch (item.type) {
                    case FIELD_TYPE.TEXT:
                    case FIELD_TYPE.NUMBER:
                    case FIELD_TYPE.EMAIL: {
                      return (
                        <TextFieldCustom
                          name={item.name}
                          type={item.type}
                          label={item.label}
                          required={item.required || false}
                          max={item.max}
                          min={item.min}
                          disabled={!canEditFields}
                        // required={false}
                        />
                      );
                    }
                    case FIELD_TYPE.DATE: {
                      return (
                        <DatePickerCustom
                          name={item.name}
                          // type={item.type}
                          label={item.label}
                          maxUnit={item.maxUnit}
                          max={item.max}
                          minUnit={item.minUnit}
                          min={item.min}
                          required={item.required || false}
                          disabled={!canEditFields}
                        />
                      );
                    }
                    case FIELD_TYPE.CUSTOM_LEVEL_PATH: {
                      return (
                        <SelectPathV3
                          name={item.name}
                          label={item.label}
                          key={item.name}
                          // type={item.type}
                          required={item.required}
                          disabled={!canEditFields}
                        />
                      );
                    }
                    case FIELD_TYPE.CUSTOM_VEHICLE_BRAND_MODEL: {
                      return (
                        <SelectBrand
                          name={item.name}
                          label={item.label}
                          key={item.name}
                          // type={item.type}
                          required={item.required}
                          disabled={!canEditFields}
                        />
                      );
                    }
                    case FIELD_TYPE.CUSTOM_VEHICLE_COLOR: {
                      return (
                        <SelectColor
                          name={item.name}
                          label={item.label}
                          key={item.name}
                          required={item.required}
                          disabled={!canEditFields}
                        />
                      );
                    }
                    case FIELD_TYPE.YEAR: {
                      return (
                        <SelectYear
                          name={item.name}
                          label={item.label}
                          key={item.name}
                          required={item.required}
                          min={item.min}
                          max={item.max}
                          disabled={!canEditFields}
                        />
                      );
                    }
                    case FIELD_TYPE.DOCUMENT: {
                      if (item.uploadFromOnboarding) {
                        return (
                          <DropzoneCustom
                            name={item.name}
                            key={item.name}
                            label={item.label}
                            required={item.required || false}
                            startDate={item.startDate}
                            endDate={item.endDate}
                          />
                        );
                      }
                      break;
                    }
                    case FIELD_TYPE.RADIO_BUTTON: {
                      if (item.options.length) {
                        return (
                          <RadioButtonsCustom
                            name={item.name}
                            label={item.label}
                            options={item.options}
                            disabled={!canEditFields}
                          />
                        );
                      }
                      break;
                    }
                    case FIELD_TYPE.SELECT: {
                      if (item.options.length) {
                        return (
                          <SelectCustom
                            name={item.name}
                            label={item.label}
                            options={item.options}
                            disabled={!canEditFields}
                          />
                        );
                      }
                      break;
                    }
                    case FIELD_TYPE.CHECKBOX: {
                      if (item.options.length) {
                        return (
                          <CheckBoxGroup
                            name={item.name}
                            label={item.label}
                            options={item.options}
                            disabled={!canEditFields}
                          />
                        );
                      }
                      break;
                    }
                    case FIELD_TYPE.BOOLEAN: {
                      return (
                        <SwitchCustom
                          name={item.name}
                          type={item.type}
                          label={item.label}
                          required={item.required || false}
                          disabled={!canEditFields}
                        />
                      );
                      break;
                    }
                    case FIELD_TYPE.CUSTOM_ENTITY_TYPE: {
                      if (item.options.length) {
                        return (
                          <SelectVehicleTypeCustom
                            name={item.name}
                            label={item.label}
                            options={item.options}
                            disabled={!canEditFields}
                          />
                        );
                      }
                      break;
                    }
                    default:
                      break;
                  }
                  return <></>;
                }),
              )}
            </FormControl>
          </div>
        </div>
        :
        ''}
    </>
  );
};

export default GroupFields;
