import React, { ReactElement } from 'react';
import { FormControl, InputLabel, CircularProgress, Select, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { useIntlData, IntlContext } from '../../contexts/intlContext';

interface SelectProps {
  name: string;
  label: MultiLangType;
  fieldState?: any;
  required?: boolean;
  disable: any
}

const SelectDisabled: React.FC<SelectProps> = ({
  name,
  label,
  fieldState,
  required,
  disable,
}): ReactElement => {
  const classes = useStyles();
  const { control } = useFormContext();
  const { isRtl } = useIntlData();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const labels = Translate(label) ? Translate(label).split(';') : ['level1_path', 'level2_path', 'level3_path']
  return (
    <>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id='custom-select-outlined-label'>
          {isRtl ? labels[0] : labels[2] ?? labels[1]}
        </InputLabel>
        <Controller
          name={name}
          control={control}
          render={() => (
            <Select
              labelId='custom-select-outlined-label'
              id='custom-select-outlined'
              label={isRtl ? labels[0] : labels[2] ?? labels[1]}
              name={name}
              disabled={!disable}
            >
              <div style={{textAlign: 'center'}}>
                <CircularProgress size={35} />
              </div>
            </Select>
          )}
          rules={{ required }}
        />
        {fieldState?.invalid && (
          <Typography className={classes.error}>
            {fieldState?.invalid && `${isRtl ? labels[0] : labels[2] ?? labels[1]} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
          </Typography>
        )}
      </FormControl>
    </>
  );
};

const defaultProps = {
  fieldState: undefined,
  required: false,
};

SelectDisabled.defaultProps = defaultProps;

export default SelectDisabled;
