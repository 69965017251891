import React, { ReactElement } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormControl, Typography, FormLabel } from '@material-ui/core';
import { useController, useFormContext, Controller } from 'react-hook-form';
import { useStyles } from './styles';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { IntlContext } from '../../contexts/intlContext';

interface CheckBoxProps {
  name: string;
  label: MultiLangType;
  options: {
    id?: string;
    value: string;
    viewValue: MultiLangType;
  }[];
  disabled: boolean | undefined;
}

const CheckBoxGroup: React.FC<CheckBoxProps> = ({
  name,
  label,
  options,
  disabled,
}): ReactElement => {
  const classes = useStyles();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event: any): void => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };
  const { fieldState } = useController({ name });
  const { control } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <>
            <FormLabel className={classes.label}> {Translate(label)}</FormLabel>
            <FormControl variant='outlined' className={classes.formControl}>
              {React.Children.toArray(
                options.map((item) => (
                  <FormControlLabel
                    disabled={disabled}
                    value={value || selectedValue}
                    name={item.value}
                    label={
                      item.viewValue ? Translate(item.viewValue) : item.value
                    }
                    className={classes.field}
                    control={
                      <Checkbox
                        color='primary'
                        onChange={(e) => {
                          handleChange(e);
                          onChange(e);
                        }}
                        onBlur={onBlur}
                        name={item.value}
                        ref={ref}
                      />
                    }
                  />
                )),
              )}
              {fieldState.invalid && (
                <Typography className={classes.error}>
                  {fieldState.invalid && `${name} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`}
                </Typography>
              )}
            </FormControl>
          </>

        )}
        rules={{ required: true }}
      />
    </>
  );
};

export default CheckBoxGroup;
