import React, { ReactElement, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { MenuItem, Select } from '@material-ui/core';
import { BsFillArrowRightCircleFill, BsArrowRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import useStyles from './styles';
import logoDriver from '../../assets/logoBrand/logo_brand_web.svg';
import { IntlContext } from '../../contexts/intlContext';
import { useAuthData } from '../../contexts/authContext';
import { useLangage } from "../../hooks/useLangage";
import { ONBOARDING_API, listLang } from '../../utils/constants';
import logoMobile from '../../assets/logoBrand/logo_brand_mobile.svg';


const Header: React.FC = (): ReactElement => {
  const classes = useStyles();
  const { locales, swtichLang } = React.useContext(IntlContext);
  const { locale, messageLang } = React.useContext(IntlContext).locales;
  const defaultLang = locales.locale;

  const { selectedCountry, isTokenValid } = useAuthData();
  const [selectedLang, setSelectedLang] = useState(defaultLang);
  const [languages, setLanguages] = useState<any[]>([]);
  const [txtLang, setTxtLang] = useState('');
  const [sizer, setSizer] = useState(window.innerWidth * 0.77);

  const {
    isLoading: loadingData,
    isSuccess: successData,
    isError: errorData,
    mutate: mutateData,
    data: listLangages,
    error: errorLanagesData,
  } = useLangage({
    path: `${ONBOARDING_API}/basic-country-infos/${selectedCountry?.code}`,
    method: 'GET'
  });

  useEffect(() => {
    if (listLang[locale]) setTxtLang(listLang[locale]); else setTxtLang(locale)
  }, [locale])

  useEffect(() => {
    document.title = JSON.parse(JSON.stringify(messageLang))['title.document'];
    if(selectedCountry?.code) mutateData({});
  }, [selectedCountry?.code]);

  useEffect(() => {
    if (successData) {
      setLanguages(listLangages?.data?.config?.languages);
    }
  }, [listLangages?.data?.config?.languages, successData]);


  useEffect((): void => {
    if (selectedCountry && selectedCountry.languages.length) {
      if (!selectedCountry?.languages.includes(selectedLang)) {
        setSelectedLang(selectedCountry?.languages.includes('fr') ? 'fr' : selectedCountry?.languages[0]);
        swtichLang(selectedCountry?.languages.includes('fr') ? 'fr' : selectedCountry?.languages[0]);
      }
      // a decommenter apres avoir validé l'anglais
      // setLanguages([...selectedCountry.languages]);
      // a supprimer aprés avoir validé l'anglais
      setLanguages([...languages]);
    }
  }, [selectedCountry, selectedLang, swtichLang]);

  const handleLangChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    const selectedValue = event.target.value as string;
    setSelectedLang(selectedValue);
    swtichLang(selectedValue);
  };

  const handleLogout = (): void => {
    window.location.replace('/logout');
  };

  const handleResize = (): any => {
    setSizer(window.innerWidth * 0.77);
  }

  useEffect(() => {
    window.addEventListener('resize', (handleResize))
  }, [])

  return (
    <div className={classes.container} style={{width: window.innerWidth > 500 ? sizer : '90%' }}>
      <div className={classes.item}>
        <Link to={window.location.pathname === `/${locale}/notsupported` ? `/${locale}/` : `/${locale}/register`}>
          {' '}
          <img className={classes.logo} src={logoDriver} alt='' />
          <img style={{ transform: locale === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)' }} className={classes.logoMob} src={logoMobile} alt='' />
          {' '}
        </Link>
      </div>
      <div className={classes.item}>
        {isTokenValid && (
          <div className={classes.ctnLink}>
            <div role="presentation" className={classes.link} onClick={() => handleLogout()}>
              <span className={classes.iconlink} style={{ marginTop: locale === 'ar' ? '-7px' : '4px', transform: locale === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)' }}><BsFillArrowRightCircleFill /></span>
              <table className={classes.ctnLogout}>
                <tr>
                  <td><span className={classes.txtlink}><FormattedMessage id='header.logout' aria-label="Logout" /></span></td>
                  <td><BsArrowRight style={{ transform: locale === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)', color: 'white', fontSize: '22px' }} aria-label="Logout" /></td>
                </tr>
              </table>
            </div>
          </div>)}
        {listLang[locale] && <span className={classes.txtLang} style={locale === 'ar' ? { marginLeft: '-30px', } : { marginRight: '-30px', }}>{
          window.innerWidth < 620 ? (<div style={{ margin: '6px 6px 6px 6px', textTransform: 'uppercase', }}>{locale}</div>) : txtLang
        }</span>}
        <div>
          {languages?.length > 1 ? (
            <Select
              IconComponent={() => <IoIosArrowDown style={locale === 'ar' ?
                {
                  position: "absolute",
                  right: "25px",
                  cursor: 'pointer',
                  fontSize: window.innerWidth > 620 ? '22px' : '18px',
                  zIndex: -1,
                  marginTop: window.innerWidth > 620 ? '5px' : '3px'
                } :
                {
                  position: "absolute",
                  left: "25px",
                  cursor: 'pointer',
                  fontSize: window.innerWidth > 620 ? '22px' : '18px',
                  zIndex: -1,
                  marginTop: window.innerWidth > 620 ? '5px' : '3px'
                }} />}
              labelId='label'
              id='select'
              // defaultValue={defaultLang}
              onChange={handleLangChange}
              value=''
              disableUnderline
            >
              {' '}
              {React.Children.toArray(
                languages.map((item: any) => (
                  <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
                )),
              )}
            </Select>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
