import { makeStyles } from '@material-ui/core/styles';
import { FaWpbeginner } from 'react-icons/fa';

export default makeStyles(() => ({
    content:{
        width: '100%',
        borderTop: '1px solid #F1F1F1',
    },
    styleEmail:{
        fontSize: '14px',
        fontFamily: 'Gellix',
        fontWeight: 600,
        color: '#767676',
    },
    styleNumPhone:{
        fontSize: '14px',
        fontFamily: 'Gellix',
        fontWeight: 600,
        direction: 'rtl',
        color: '#767676',
    },
    styleEmailMobVer:{
        fontSize: '14px',
        fontFamily: 'Gellix',
        fontWeight: 600,
        color: '#767676',
    },
    styleNumPhoneMobVer:{
        fontSize: '14px',
        fontFamily: 'Gellix',
        fontWeight: 600,
        color: '#767676',
        direction: 'rtl',
    },
    ctnDataFooter:{
        margin: '0 auto',
        height: 'inherit',
        textAlign: 'center'
    },
    ctnLaw:{
        textAlign: 'center'
    },
    law:{
        height: '17px',
        fontFamily: 'Gellix',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: '-0.24px',
        color: '#767676',
        '@media (max-width:500px)': {
            fontWeight: 500,
        }
    },
    styleIcon:{
        fontSize: '16px',
        position:'absolute',
    },
    styleIconMobVer:{
        fontSize: '18px',
        position:'absolute',
        marginTop: '1px',
    },
    leftPart:{
        float: 'left',
        width: '50%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '55px',
    },
    rightPart:{
        float: 'right',
        width: '50%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: '55px',
    },
    ctnMobile:{
        color: '#767676',
        display:'none',
        lineHeight: '1',
        '@media (max-width:500px)': {
            display: 'block',
        }
    },
    ctnWeb:{
        color: '#767676',
        display:'block',
        '@media (max-width:500px)': {
            display: 'none',
        }
    }
}))