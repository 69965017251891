/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { TextField } from '@material-ui/core';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { MultiLangType } from '../../utils/types';
import Translate from '../../utils/Translate';
import { EMAIL_PATTERN } from '../../utils/constants';
import { IntlContext } from '../../contexts/intlContext';

interface TextFieldProps {
  name: string;
  type: string;
  label: MultiLangType;
  required: boolean | undefined;
  min: string | undefined;
  max: string | undefined;
  disabled: boolean | undefined;
}

const TextFieldCustom: React.FC<TextFieldProps> = ({
  name,
  type,
  label,
  required,
  min,
  max,
  disabled,
}): ReactElement => {
  const { field, fieldState } = useController({ name });
  const { control, register } = useFormContext();
  const { messageLang } = React.useContext(IntlContext)?.locales;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <TextField
          label={Translate(label)}
          type={type}
          InputProps={{
            inputProps: {
              ...(max && { max }),
              ...(min && { min })
            }
          }}
          InputLabelProps={type === 'date' || value || field.value ? { shrink: true } : {}}
          variant='outlined'
          disabled={disabled}
          value={value || field.value}
          {...register(name, {
            ...(type?.toLowerCase() === 'email' && {
              pattern: {
                value: EMAIL_PATTERN,
                message: Translate({ fr: `E-mail n'est pas valide`, en: `E-mail address is not valid`, ar: `E-mail address is not valid` }),
              }
            }),
          })}
          helperText={
            fieldState.invalid && fieldState?.error?.type === "min" ?
              `${Translate(label)} should be greater then ${min}` :
              fieldState.invalid && fieldState?.error?.type === "max" ?
                `${Translate(label)} should be less then ${max}` :
                fieldState.invalid && fieldState?.error?.type === "pattern" ?
                  `${fieldState?.error?.message}` :
                  fieldState.invalid && `${Translate(label)} ${JSON.parse(JSON.stringify(messageLang))['is.required']}`
          }
          error={fieldState.invalid}
        />
      )}
      rules={{
        required,
        ...(max && { max }),
        ...(min && { min })
      }}
    />
  );
};

export default TextFieldCustom;
