import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { keysToCamel } from '../utils/keysToCamel';
import { useAxios } from './useAxios';


export const useConfig = (): any => {
  const axios = useAxios();
  const history = useHistory();
  let response;

  // Create a query with the key `configs`
  return useQuery<Record<string, any>, Error>('configs', async () => {
    await axios.get<any>('/register', {})
      .then(res => {
        response = keysToCamel(res?.data?.data?.config || res?.data);
      })
      .catch(err => {
        if (err?.response?.data?.data)
          response = keysToCamel(err?.response?.data?.data);
        else if (err?.response?.data?.errorMessageKey === 'ONBORDING_VERSION_ERROR') {
          history.push('/notsupported')
        } else {
          response = err?.response
        }
      });
    return response;
  },
    {
      enabled: !!localStorage.getItem('token'),
    });
};
