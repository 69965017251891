import { add, eachYearOfInterval } from 'date-fns';
import { DateRangeResponseType, IDateRangeType } from './types';

export function getDateRanges({
  max,
  maxUnit,
  min,
  minUnit,
}: IDateRangeType): DateRangeResponseType {
  let maxDate: Date | string = '';
  let minDate: Date | string = '';
  if (max && maxUnit) {
    const maxConfig: Record<string, any> = {};
    maxConfig[`${maxUnit}s`] = Number(max);
    maxDate = add(new Date(), {
      ...maxConfig,
    });
  }
  if (min && minUnit) {
    const minConfig: Record<string, any> = {};
    minConfig[`${minUnit}s`] = Number(min);
    minDate = add(new Date(), {
      ...minConfig,
    });
  }

  return { maxDate, minDate };
}

export function getYearsList({ max, min }: IDateRangeType): any[] {
  let maxDate: Date = new Date();
  let minDate: Date = new Date();
  if (max) {
    maxDate = add(new Date(), {
      years: Number(max),
    });
  }
  if (min) {
    minDate = add(new Date(), {
      years: -Number(min),
    });
  }

  const years = eachYearOfInterval({
    start: minDate,
    end: maxDate,
  }).map((year) => year.getFullYear());

  return years;
}
