import React, { createContext, ReactElement, useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import { ONBOARDING_API } from '../utils/constants';

export const AxiosContext = createContext<AxiosInstance>(null!);

export interface AxiosProps {
  children: ReactElement;
}

// const baseURL = 'https://staging-api.yassir.io/public/api/v1';
// const hardCodedToken = 
//   'iOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIrMjEzNzc1OTg2MTAzIiwiaWF0IjoxNjM4NDU0OTUxLCJleHAiOjE2NDYyMzA5NTEsInR5cGUiOiJZQV9HT19DT01QQU5ZIn0.8tSon9W3sqHkawP1-9Xh7ZsX6hOFnxQSZBuEEvX_ivk';

const AxiosProvider: React.FC<AxiosProps> = ({
  children,
}): React.PropsWithChildren<any> => {
  const customAxios = useMemo(() => {
    const clientAxios: any = axios.create({
      baseURL: ONBOARDING_API,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    clientAxios.interceptors.request.use((config: { headers: any }) => {
      // const token = hardCodedToken;
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `${token}`;
      }
      // console.log({ config })
      return config;
    });

    return clientAxios;
  }, []);

  return (
    <AxiosContext.Provider value={customAxios}>
      {children}
    </AxiosContext.Provider>
  );
};

export default AxiosProvider;
