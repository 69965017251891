import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth:'100%',
    paddingLeft: '0px',
    background: 'white',
    '@media (max-width:500px)': {
      position: 'relative',
      zIndex: 3,
    }
  },
  container2: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    alignItems: 'flex-start',
    height: '60px',
    width: 'auto',
    float: 'right',
    marginRight: -40,
    '@media (max-width:500px)': {
      marginRight: 0,
      padding: '16px 0px 16px 16px',
    }
  },
  txtLang:{
    textTransform: 'capitalize',
    textDecoration: 'underline',
    fontFamily: 'Gellix',
    fontSize: 17,
    fontWeight: 500,
    color: '#1A0244',
  },
  transparent: {
    opacity: '0.2',
    position: 'absolute',
    background: '#FFFFFF',
    width: '100%',
    height: '92px',
    left: '0px',
    top: '0px',
    zIndex: -1,
  },
  ctnHeader: {
    '@media (max-width:978px)': {
      width:'90% !important',
      margin: '0 auto'
    },
    '@media (min-width:978px)': {
      maxWidth:'77%',
      margin: '0 auto'
    },
    '@media (max-width:600px)': {
      width:'auto !important',
      margin: '0px'
    }
  },
  logoMob: {
    display: 'none',
    marginLeft: '0px',
    '@media (max-width:600px)': {
      width: 'auto',
      display: 'block',
      marginLeft: '5px',
    }
  },
  btn: {    
    background: '#000000',    
    border: '1px solid black',
    fontFamily: 'Gellix !important',
    fontStyle: 'normal',
    fontSize: '17px !important',    
    "&:hover": {
      backgroundColor: 'black',
      borderColor: 'black'
    },  
    '@media (max-width:300px)': {
      width: 'auto', 
      height: 'auto',
      fontSize: '9px !important',
    },
    width:'max-content',
    backgroundColor: 'black', 
    textTransform: 'none', 
    borderRadius: '18px !important', 
    lineHeight: 1.2
  },
  centerRight: {
    padding: '22px 0',
    textAlign: 'right',
  },
  centerLeft: {
    textAlign: 'left',
    display:'block',
    marginLeft: -30,
    '@media (max-width:500px)': {
      display:'none',
      marginLeft: 0,
    }
  },
  centerLeftBlack: {
    textAlign: 'left',
    display:'none',
    marginLeft: '0px',
    '@media (max-width:500px)': {
      display:'block',
      marginLeft: '13px',
    }
  },
  img:{
    width: '6.8rem',
    marginLeft:'0px',
    '@media (min-width:500px)': {
      marginLeft:'30px',
    },
    '@media (max-width:500px)': {
      width:'90px',
      marginLeft:'0px'
    },
  },
  styleColHeader: {
    paddingLeft: '0px',
    paddingRight: '0px',
    margin: '0 auto !important',
    '@media (max-width:890px)': {
      flexBasis: '50%'
    }
  },
  item: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: '0 20px',
    },
    [theme.breakpoints.down("xs")]: {
      '& > *': {
        margin: '6px',
      },
    },
  },
  styleSelect: {
    color:'#1A0244',
    margin: '0px 0px 0px -5px',
    "&:after": {
      borderBottomColor: "white",
    },
    "& .MuiSvgIcon-root": {
      color: "#1A0244",
    },
  },
}));
