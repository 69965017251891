import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  styleScreenTitle:{
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '36px',    
    textAlign: 'center',
    color: '#1A0244'
  },
  content:{
    textAlign: 'center', 
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignSelf: 'center',
    justifySelf: 'center',
    maxWidth: 640, 
    margin: '0 auto',
    '@media (max-width:500px)': {
      maxWidth: '100%',
    }
  },
  ctnContent:{ 
    alignItems: 'center',
    justifyContent: 'center',
    margin: "0 auto",
    padding:'0px',
  },
  contentBody:{
    background: '#F7F7F7',
    borderRadius: '12px',
    margin: '25px auto 25px',
    padding: '40px 0px 20px 0px',
    width: 550,
    '@media (max-width:500px)': {
      background: 'transparent',
      margin: '25px auto 25px',
      padding: '0px',
      width: '100%',
    }
  },
  bgHeader: {
    boxShadow: '0px 2px 8px 4px rgba(27, 27, 27, 0.1)',
    display: 'grid',
    '@media (min-width:620px)': {
      height: 96,
    },
  },
  firstTxt:{ 
    textAlign: "center", 
    fontWeight: 'bold', 
    fontSize: 27 
  },
  secondeTxt:{
    color: "#545454", 
    textAlign: "center", 
    fontSize: 14 
  },
  ctnHomeLink:{
    border: '1px solid transparent',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkHome:{
    color: 'black',
    cursor:'pointer',
    border: '1px solid transparent',
    padding: '10px 15px 12px 12px',
    borderRadius: '18px',
    background: 'white',
    float: 'left',
  },
  styleLinkHome:{
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    fontSize:14,
    fontWeight: 500,
    fontFamily: 'Gellix',
    color: '#1A0244',
    '@media (max-width:350px)': {
      fontSize: 14,
    }
  },
  styleIconeHome:{
    margin: '2px 5px 0px 0px',
    float: 'left',
  },
  ctnBody:{ 
    width: 550,
    height: 125, 
    backgroundImage: 'linear-gradient(#000000, #494949)', 
    bottom: 0, 
    margin: '0 auto',
    borderRadius: 10, 
    display: 'flex',
    position: 'relative',
    marginBottom: 45,
    '@media (max-width:500px)': {
      width: '100%',
      height: '150px',
      borderRadius: '0px', 
    },
  },
  body:{
    padding: 20, 
    flex: 2.3,
    '@media (max-width:500px)': {
      width: '60%',
      padding: 15, 
    }
  },
  styleBody:{
    fontFamily: 'Gellix',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '24px',
    color: '#1A0244',
    textAlign: 'justify',
  },
  text: {
    marginTop: 5,
    fontFamily: 'Gellix',
    fontWeight: 600,
    color: 'white',
    fontSize: '18px',
    '@media (max-width:500px)': {
      fontSize: '18px',
    },
    '@media (max-width:400px)': {
      fontSize: '15px',
    },
    '@media (max-width:300px)': {
      fontSize: '13px',
    },
  },
  ctnStyleImg:{ 
    marginRight: 40,
    '@media (max-width:500px)': {
      marginRight: 8,
    },
  },
  styleImg:{
    width: '105px',
    height: '43px',
    marginTop: 10,
    '@media (max-width:500px)': {
      width: 'auto',
    },
    '@media (max-width:400px)': {
      width: 90,
    },
    '@media (max-width:300px)': {
      width: 80,
    },
  },
  bgImg:{ 
    flex: 0.7, 
    position: "relative",
    '@media (max-width:500px)': {
      width: '40%',
      flex: 'unset',
    }
  },
  ctnBgImg:{
    position: 'absolute',
    bottom: 0,
    right: '25px',
    width: '83px',
    flex: 'none',
    order: 1,
    flexGrow: 0,
  },
  bodyImg:{ 
    display: "flex", 
    alignItems: "center", 
    marginTop: 5, 
  }
}));
