import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  field: {
    background: '#F5F5F5',
    borderRadius: theme.shape.borderRadius,
    padding: '4px 8px',
    margin: '4px 0',
    color: '#1B1B1B',
    marginTop: '0px',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    lineHeight: '1.66',
  },
  formControl: {
    textAlign: 'left',
  },
  label: {
    marginBottom: '0px',
  },
}));
