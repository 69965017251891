import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import Translate from '../../utils/Translate';
import { MultiLangType } from '../../utils/types';
import { useStyles } from './styles';

interface TextContentProps {
  value: any;
  label: MultiLangType;
}

export const TextContent: React.FC<TextContentProps> = ({
  value,
  label,
}): ReactElement => {
  const classes = useStyles();
  // if (!value) return <></>;
  const imageValue = value?.name ? value : value;
  if (imageValue) {
    let Status;
    if (imageValue.status === 'APPROVED') {
      Status = (
        <small
          style={{
            color: 'green',
            fontSize: 12,
          }}
        >
          <FormattedMessage id='dropzone.accepted' />
        </small>
      );
    } else if (imageValue.status === 'REJECTED') {
      Status = (
        <small
          style={{
            color: 'red',
            fontSize: 12,
          }}
        >
          <FormattedMessage id='dropzone.rejected' />
        </small>
      );
    } else {
      Status = (
        <small
          style={{
            color: '#8c8c8c',
            fontSize: 12,
          }}
        >
          <FormattedMessage id='dropzone.saving-in-progress' />
        </small>
      );
    }

    return (
      <>
        <Typography style={{ fontSize: 15 }}>
          <FormattedMessage id='dropzone.doc-added' /> <br />
          {Status}
        </Typography>
        <Typography className={classes.modify}>
          {value ? <FormattedMessage id='dropzone.modify' /> : null}
        </Typography>
      </>
    );
  }
  return (
    <Typography style={{ fontSize: 15, margin: 0 }}>
      {label ? Translate(label) : <FormattedMessage id='dropzone.add-doc' />}
      <br />
    </Typography>
  );
};

export default TextContent;
