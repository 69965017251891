import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    margin: '16px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    justifySelf: 'center',
    background: theme.palette.background.paper,
    width: '450px',
    boxSizing: 'border-box',
    [theme.breakpoints.down("xs")]: {
      width: '300px',
    },
  },
  bgHeader: {
    background: '#F7F7F7',
  },
}));
