import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/core';
import { create } from "jss";
import rtl from "jss-rtl";
import { rtlTheme, ltrTheme } from './theme';
import './App.css';
import { ProviderWrapper } from './contexts';

import Loader from './components/YassirLoader';
import CarsList from './screens/CarsList';
import DriversList from './screens/DriversList';
import MultiStepsForm from './screens/MultiStepsForm';
import PageNotFound from './screens/ErrorPage';
import AlreadyRegistredPage from './screens/AlreadyRegistredPage';
import NotSupportedPage from './screens/NotSupportedPage';
import Screen from './screens/EspaceMsg/Screen';
import LoGin from './screens/EspaceMsg';
import Training from './screens/Training';
import LoginV2 from './screens/Auth-v2/Login';
import Logout from './screens/Logout';
import { useAuthData } from './contexts/authContext';
import { useIntlData } from './contexts/intlContext';

const Done = React.lazy(() => import('./screens/Done'));
// const GetTokenFromUrl = React.lazy(() => import('./screens/GetTokenFromUrl'));
const Registration = React.lazy(() => import('./screens/Registration'));
const Signup = React.lazy(() => import('./screens/Signup-v2/SignUp'));
const SimpleForm = React.lazy(() => import('./screens/SimpleForm'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = (): JSX.Element => {
  // const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const PrivateRoute = ({ component: Component, path, exact }): JSX.Element => {
    const { isTokenValid } = useAuthData();
    
    return (
      <Route
        path={path}
        exact={exact}
        render={props =>
          isTokenValid ? (
            <Suspense fallback={<Loader />}>
              <Component {...props} />
            </Suspense>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    )
  }


  const AppComponent = (): JSX.Element => {

    const { isRtl } = useIntlData();
    
    React.useLayoutEffect(() => {
      document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
    }, [isRtl]);

    return (
      <ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
        <div className='App'>
          <div>
            <Switch>
              <Route exact path='/:lang'>
                <Suspense fallback={<Loader />}>
                  <Signup />
                </Suspense>
              </Route>
              <Route exact path='/:lang/login'>
                <LoGin />
              </Route>
              <Route exact path='/logout'>
                <Logout />
              </Route>
              <Route exact path='/:lang/screen'>
                <Screen />
              </Route>
              {/* <Route exact path='/:lang/token=:token'>
                <Suspense fallback={<Loader />}>
                  <GetTokenFromUrl />
                </Suspense>
              </Route> */}
              <Route exact path='/:lang/signin'>
                <Suspense fallback={<Loader />}>
                  <LoginV2 />
                </Suspense>
              </Route>
              <Route exact path='/:lang/done'>
                <Suspense fallback={<Loader />}>
                  <Done />
                </Suspense>
              </Route>
              <Route exact path='/:lang/registred'>
                <Suspense fallback={<Loader />}>
                  <AlreadyRegistredPage />
                </Suspense>
              </Route>
              <Route exact path='/:lang/notsupported'>
                <Suspense fallback={<Loader />}>
                  <NotSupportedPage />
                </Suspense>
              </Route>
              <PrivateRoute
                component={Registration}
                path='/:lang/register'
                exact
              />
              <PrivateRoute
                component={Training}
                path='/:lang/training'
                exact
              />
              <PrivateRoute
                component={SimpleForm}
                path='/:lang/form/:groupStepId'
                exact={false}
              />
              <PrivateRoute
                component={CarsList}
                path='/:lang/form-cars/:groupStepId'
                exact={false}
              />
              <PrivateRoute
                component={DriversList}
                path='/:lang/form-drivers/:groupStepId'
                exact={false}
              />
              <PrivateRoute
                component={MultiStepsForm}
                path='/:lang/form-multi/:groupStepId'
                exact={false}
              />
              <Route path={encodeURI('*')} exact>
                <PageNotFound />
              </Route>
            </Switch>
          </div>
        </div>
      </ThemeProvider>

    )
  }

  return (
    <QueryClientProvider client={queryClient}>
      <StylesProvider jss={jss}>
        <Router>
          <ProviderWrapper>
            <AppComponent />
          </ProviderWrapper>
        </Router>
      </StylesProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;