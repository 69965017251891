const ar = {
    '67.years.old.retired.dza': '67 عامًا، متقاعد',
    '67.years.old.retired.mar': '67 عامًا، متقاعد',
    '67.years.old.retired.tun': '67 عامًا، متقاعد',
    '67.years.old.retired.sen': '67 عامًا، متقاعد',
    '67.years.old.retired.can': '67 عامًا، متقاعد',
    '38.ans.employe.dza': '38 سنة، موظف',
    '38.ans.employe.mar': '38 سنة، موظف',
    '38.ans.employe.tun': '38 سنة، موظف',
    '38.ans.employe.sen': '38 سنة، موظف',
    '38.ans.employe.can': '38 سنة، موظف',
    '25.ans.etudiant.dza': '25 سنة طالب جامعي',
    '25.ans.etudiant.mar': '25 سنة طالب جامعي',
    '25.ans.etudiant.tun': '25 سنة طالب جامعي',
    '25.ans.etudiant.sen': '25 سنة طالب جامعي',
    '25.ans.etudiant.can': '25 سنة طالب جامعي',
    '2nd.year.student.dza': 'أنا طالب في السنة الثانية في كلية الحقوق و مصاريفي اليومية كثيرة.اكتشفت من خلال مواقع التواصل الإجتماعي أن خدمة يسير تقوم بتوظيف السائقين',
    '2nd.year.student.mar': 'أنا طالب في السنة الثانية في كلية الحقوق و مصاريفي اليومية كثيرة.اكتشفت من خلال مواقع التواصل الإجتماعي أن خدمة يسير تقوم بتوظيف السائقين',
    '2nd.year.student.tun': 'أنا طالب في السنة الثانية في كلية الحقوق و مصاريفي اليومية كثيرة.اكتشفت من خلال مواقع التواصل الإجتماعي أن خدمة يسير تقوم بتوظيف السائقين',
    '2nd.year.student.sen': 'أنا طالب في السنة الثانية في كلية الحقوق و مصاريفي اليومية كثيرة.اكتشفت من خلال مواقع التواصل الإجتماعي أن خدمة يسير تقوم بتوظيف السائقين',
    '2nd.year.student.can': 'أنا طالب في السنة الثانية في كلية الحقوق و مصاريفي اليومية كثيرة.اكتشفت من خلال مواقع التواصل الإجتماعي أن خدمة يسير تقوم بتوظيف السائقين',
    'are.you.already.registered': 'تهانينا ، لقد أكملت تسجيلك',
    'text.congrat.one': 'شكرا لاختيارك ياسر ، حسابك مفعل الان.',
    'text.congrat.two': 'يمكنك الآن خوض سباقك الأول عن طريق تسجيل الدخول إلى تطبيق ياسر.',
    'text.congrat.three': 'إذا لم تقم بتنزيل التطبيق بعد ، فنحن ندعوك للقيام بذلك بالنقر أدناه.',
    'text.congrat.four': 'نتمنى لك تجربة ممتعة!',
    'after.my.retirement.dza': '"بعد التقاعد ،بدأت أفكر في عمل آخر يسمح لي بالمحافظة على نشاطي ويشغلني في وقت فراغي و هذا ما جعلني أكتشف خدمة يسير. عملي كسائق شريك ساعدني على إدارة وقتي بشكل أفضل و إضافة دخل شهري أخر."',
    'after.my.retirement.mar': '"بعد التقاعد ،بدأت أفكر في عمل آخر يسمح لي بالمحافظة على نشاطي ويشغلني في وقت فراغي و هذا ما جعلني أكتشف خدمة يسير. عملي كسائق شريك ساعدني على إدارة وقتي بشكل أفضل و إضافة دخل شهري أخر."',
    'after.my.retirement.tun': '"بعد التقاعد ،بدأت أفكر في عمل آخر يسمح لي بالمحافظة على نشاطي ويشغلني في وقت فراغي و هذا ما جعلني أكتشف خدمة يسير. عملي كسائق شريك ساعدني على إدارة وقتي بشكل أفضل و إضافة دخل شهري أخر."',
    'after.my.retirement.sen': '"بعد التقاعد ،بدأت أفكر في عمل آخر يسمح لي بالمحافظة على نشاطي ويشغلني في وقت فراغي و هذا ما جعلني أكتشف خدمة يسير. عملي كسائق شريك ساعدني على إدارة وقتي بشكل أفضل و إضافة دخل شهري أخر."',
    'after.my.retirement.can': '"بعد التقاعد ،بدأت أفكر في عمل آخر يسمح لي بالمحافظة على نشاطي ويشغلني في وقت فراغي و هذا ما جعلني أكتشف خدمة يسير. عملي كسائق شريك ساعدني على إدارة وقتي بشكل أفضل و إضافة دخل شهري أخر."',
    'are.there.races.to.other.wilayas': 'هل هناك رحلات خارج الولاية ؟',
    'i.heard.about.yassir.dza': 'تفاجئت بقبولي مع إتاحة الفرصة لي للعمل بسيارة والدي بالتوكيل الآن أصبح بإمكاني تفعيل التطبيق و القيام برحلات لتحسين دخلي خارج أوقات الدراسة',
    'i.heard.about.yassir.mar': 'تفاجئت بقبولي مع إتاحة الفرصة لي للعمل بسيارة والدي بالتوكيل الآن أصبح بإمكاني تفعيل التطبيق و القيام برحلات لتحسين دخلي خارج أوقات الدراسة',
    'i.heard.about.yassir.tun': 'تفاجئت بقبولي مع إتاحة الفرصة لي للعمل بسيارة والدي بالتوكيل الآن أصبح بإمكاني تفعيل التطبيق و القيام برحلات لتحسين دخلي خارج أوقات الدراسة',
    'i.heard.about.yassir.sen': 'تفاجئت بقبولي مع إتاحة الفرصة لي للعمل بسيارة والدي بالتوكيل الآن أصبح بإمكاني تفعيل التطبيق و القيام برحلات لتحسين دخلي خارج أوقات الدراسة',
    'i.heard.about.yassir.can': 'تفاجئت بقبولي مع إتاحة الفرصة لي للعمل بسيارة والدي بالتوكيل الآن أصبح بإمكاني تفعيل التطبيق و القيام برحلات لتحسين دخلي خارج أوقات الدراسة',
    'as.an.employee.dza': '"راتبي الشهري كموظف أصبح لا يلبي احتياجات أسرتي علما أني أب لثلاثة أطفال . إقترح عليا صديقي أن أصبح سائقا شريكا مع يسير ، <span id="again">و أصبحت أفعل التطبيق بعد أوقات عملي و أزود دخلي يوميا.</span>"',
    'as.an.employee.mar': '"راتبي الشهري كموظف أصبح لا يلبي احتياجات أسرتي علما أني أب لثلاثة أطفال . إقترح عليا صديقي أن أصبح سائقا شريكا مع يسير ، <span id="again">و أصبحت أفعل التطبيق بعد أوقات عملي و أزود دخلي يوميا.</span>"',
    'as.an.employee.tun': '"راتبي الشهري كموظف أصبح لا يلبي احتياجات أسرتي علما أني أب لثلاثة أطفال . إقترح عليا صديقي أن أصبح سائقا شريكا مع يسير ، <span id="again">و أصبحت أفعل التطبيق بعد أوقات عملي و أزود دخلي يوميا.</span>"',
    'as.an.employee.sen': '"راتبي الشهري كموظف أصبح لا يلبي احتياجات أسرتي علما أني أب لثلاثة أطفال . إقترح عليا صديقي أن أصبح سائقا شريكا مع يسير ، <span id="again">و أصبحت أفعل التطبيق بعد أوقات عملي و أزود دخلي يوميا.</span>"',
    'as.an.employee.can': '"راتبي الشهري كموظف أصبح لا يلبي احتياجات أسرتي علما أني أب لثلاثة أطفال . إقترح عليا صديقي أن أصبح سائقا شريكا مع يسير ، <span id="again">و أصبحت أفعل التطبيق بعد أوقات عملي و أزود دخلي يوميا.</span>"',
    'choose.a.schedule.dza': 'اختر أوقات العمل التي تتناسب مع التزاماتك الشخصية و العائلية لكن يمكنك العمل 24سا/24 و 7أيام/7 . كلما قمت بعدد رحلات أكبر كان دخلك أكثر .',
    'choose.a.schedule.mar': 'اختر أوقات العمل التي تتناسب مع التزاماتك الشخصية و العائلية لكن يمكنك العمل 24سا/24 و 7أيام/7 . كلما قمت بعدد رحلات أكبر كان دخلك أكثر .',
    'choose.a.schedule.tun': 'اختر أوقات العمل التي تتناسب مع التزاماتك الشخصية و العائلية لكن يمكنك العمل 24سا/24 و 7أيام/7 . كلما قمت بعدد رحلات أكبر كان دخلك أكثر .',
    'choose.a.schedule.sen': 'اختر أوقات العمل التي تتناسب مع التزاماتك الشخصية و العائلية لكن يمكنك العمل 24سا/24 و 7أيام/7 . كلما قمت بعدد رحلات أكبر كان دخلك أكثر .',
    'choose.a.schedule.can': 'اختر أوقات العمل التي تتناسب مع التزاماتك الشخصية و العائلية لكن يمكنك العمل 24سا/24 و 7أيام/7 . كلما قمت بعدد رحلات أكبر كان دخلك أكثر .',
    'driven.with.Yassir.since': '- سائق شريك منذ',
    'double.airbag': 'أن تكون سيارتك تحتوي على الوسائد الهوائية (airbag) من الجهتين : جهة السائق و جهة الراكب.',
    'Do.i.have.to.show.up.with.my.vehicle': 'هل يجب علي إحضار سيارتي ؟',
    'firstName': 'الاسم',
    'lastName': 'اللقب',
    'isCarOwner': 'مالك السيارة',
    'hasDrivingLicence': 'رخصة السياقة',
    "header.logout": "تسجيل الخروج",
    'firstName.is.required': 'الإسم الأول مطلوب',
    'lastName.is.required': 'إسم العائلة مطلوب',
    'driver.already.exist': 'سائق موجود بالفعل!',
    'click.here.to.login': 'انقر هنا لتسجيل الدخول',
    'click.here.to.login2': 'تسجيل الدخول',
    'bad.request': 'حاول مرة أخرى في وقت لاحق',
    'driver.not.found': 'السائق غير موجود!',
    'become.a.driver.vtc.yassir.dza': 'كن منشط (سائق) عبر منصة يسير',
    'become.a.driver.vtc.yassir.mar': 'كن منشط (سائق) عبر منصة يسير',
    'become.a.driver.vtc.yassir.tun': 'كن منشط (سائق) عبر منصة يسير',
    'become.a.driver.vtc.yassir.sen': 'كن منشط (سائق) عبر منصة يسير',
    'become.a.driver.vtc.yassir.can': 'كن منشط (سائق) عبر منصة يسير',
    'being.a.yassir.partner.driver.dza': 'كونك منشطا (سائقًا) مع "يسير" يعني إدارة عملك براحة البال وتحسين دخلك مع التطبيق رقم 1 في السوق',
    'being.a.yassir.partner.driver.tun': 'كونك منشطا (سائقًا) مع "يسير" يعني إدارة عملك براحة البال وتحسين دخلك مع التطبيق رقم 1 في السوق',
    'being.a.yassir.partner.driver.mar': 'كونك منشطا (سائقًا) مع "يسير" يعني إدارة عملك براحة البال وتحسين دخلك مع التطبيق رقم 1 في السوق',
    'being.a.yassir.partner.driver.sen': 'كونك منشطا (سائقًا) مع "يسير" يعني إدارة عملك براحة البال وتحسين دخلك مع التطبيق رقم 1 في السوق',
    'being.a.yassir.partner.driver.can': 'كونك منشطا (سائقًا) مع "يسير" يعني إدارة عملك براحة البال وتحسين دخلك مع التطبيق رقم 1 في السوق',
    'i.have.a.valid.driving.license': 'لدي رخصة سياقة سارية المفعول منذ أكثر من عامين (2).',
    'i.own.a.vehicle': 'أملك سيارة خاصة',
    'conditions.utilisations1': 'عند التسجيل مع يسير ، سيتم الموافقة على ',
    'conditions.utilisations2': 'شروط الإستعمال',
    'commenncez.des.maintenant.dza': 'سجل الأن و اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'commenncez.des.maintenant.mar': 'سجل الأن و اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'commenncez.des.maintenant.tun': 'سجل الأن و اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'commenncez.des.maintenant.sen': 'سجل الأن و اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'commenncez.des.maintenant.can': 'سجل الأن و اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'commenncez.des.maintenant2': 'اصبح سائقا شريكا مع الخدمة الأكثر طلبا في السوق',
    'Become.your.own.boss.can': 'كن رئيسك الخاص واتبع أرصدتك على الهواء مباشرة.',
    'Become.your.own.boss.dza': 'يكسب بعض المنشطين (السائقين) ما يصل إلى 150.000DZD شهريًا!',
    'increase.your.income.mar': 'قم بإدارة جدولك وعملك وفقًا لاحتياجاتك الشخصية والعائلية.',
    'increase.your.income.tun': 'قم بإدارة جدولك وعملك وفقًا لاحتياجاتك الشخصية والعائلية.',
    'invalid.phone.number': 'رقم الهاتف غير صحيح',
    'Become.your.own.boss.sen': 'كن رئيسك الخاص واتبع أرصدتك على الهواء مباشرة.',
    'Manage.your.schedule.can': 'قم بإدارة جدولك وعملك وفقًا لاحتياجاتك الشخصية والعائلية.',
    'Manage.your.schedule.dza': 'يمكنك العمل حسب رغبتك و احتياجاتك دون التقيد بمواعيد ثابتة.',
    'Manage.your.schedule.mar': 'كن رئيسك الخاص واتبع ارصدتك على الهواء مباشرة  و ستستفيد من ربح إضافي كل شهر.',
    'Manage.your.schedule.tun': 'كن رئيسك الخاص واتبع ارصدتك على الهواء مباشرة  و ستستفيد من ربح إضافي كل شهر.',
    'Manage.your.schedule.sen': 'كن رئيسك الخاص واتبع ارصدتك على الهواء مباشرة  و ستستفيد من ربح إضافي كل شهر.',
    'increase.your.income.can': 'زيادة دخلك وتغطية نفقاتك.',
    'increase.your.income.dza': 'يمكنك إدارة عملك و متابعة أرباحك.',
    'Become.your.own.boss.mar': 'عمولة 0٪ عن الشهر الأول لك كشريك يسير.',
    'Become.your.own.boss.tun': 'عمولة 0٪ عن الشهر الأول لك كشريك يسير.',
    'increase.your.income.sen': 'زيادة دخلك وتغطية نفقاتك.',
    'level.path': 'مدينة',
    'you.must.register.first': 'اضغط هنا للتسجيل',
    'you.must.register.first2': 'تسجيل',
    'the.phone.number.has.missing.digits': 'رقم الهاتف به أرقام مفقودة',
    'internal.error.server': 'عفوًا ، حدث خطأ ، يرجى المحاولة مرة أخرى لاحقًا!',
    'Phone.is.required': 'الهاتف مطلوب',
    'how.can.i.contact.you': 'كيف يمكنني الاتصال بكم ؟',
    "home.signup": "تسجيل",
    "home.welcome": "مرحبا بك في يسير",
    "home.description-line1": "انضم إلى شركاء يسير وزود مداخيلك",
    "home.description-line2": "بأقل عمولة في السوق!",
    "home.login": "تسجيل الدخول",
    'how.to.become.a.partner.driver.at.yassir.dza': 'كيف أصبح منشط (سائق) مع يسير؟',
    'how.to.become.a.partner.driver.at.yassir.mar': 'كيف أصبح منشط (سائق) مع يسير؟',
    'how.to.become.a.partner.driver.at.yassir.tun': 'كيف أصبح منشط (سائق) مع يسير؟',
    'how.to.become.a.partner.driver.at.yassir.sen': 'كيف أصبح منشط (سائق) مع يسير؟',
    'how.to.become.a.partner.driver.at.yassir.can': 'كيف أصبح منشط (سائق) مع يسير؟',
    'have.a.4G.Smartphone': ' لديك هاتف ذكي',
    'is.required': ' مطلوب',
    'License.of.2.years.or.more.and.be.25.years.or.more': 'رخصة سياقة لأكثر من عامين ',
    'Mohamed.G.can': 'محمد',
    'Mohamed.G.dza': 'محمد',
    'Mohamed.G.mar': 'محمد',
    'Mohamed.G.tun': 'محمد',
    'Mohamed.G.sen': 'Mbaye K',
    'none.trad': 'غير موجود',
    'formation.trad': 'الدورة التدريبية',
    "signin.phone-required": "يُرجى إدخال رقم هاتفك",
    "signin.code-required": "Veuillez entrer le code",
    "signin.sign-in": "سجل دخولك",
    "signin.use-phone": "استخدم رقم هاتفك لتسجيل الدخول",
    "signin.verify-number": "تحقق من رقمك",
    "signin.code-sent-text": "تم إرسال رمز مكون من 6 أرقام إلى رقمك",
    "signin.accept-conditions": "بتسجيل الدخول إلى يسير،  ",
    "signin.use-terms": "أنت توافق على شروط الاستخدام",
    "signin.code-sent": "رمز مُرسل ",
    "signin.send": "إرسـال ",
    "signin.confirm": "تأكيد ",
    "signin.invalid-code": "رمز خاطئ أو منتهي الصلاحية ",
    "signin.validated-code": "Code validé",
    "signin.code-expires-in": "تنتهي صلاحية الرمز بعد",
    "signin.resend-code": "أعد الكود",
    "signin.accept": "الموافقة ",
    "signin.use-terms-privacy-policy": " على شروط الاستخدام",
    'app.join-us': "!انضم إلينـا",
    'app.join-us-subtitle': ".سجل في خطوتين بسيطتين لتصبح شريكًا ليسير",
    "app.sign-up-text": ".سجل في خطوتين بسيطتين لتصبح شريكًا ليسير",
    "app.general-info": "معلومات شخصية     ",
    "app.general-info-description": "إضافة معلوماتك الشخصية",
    "app.company-docs": "Documents de l'entreprise",
    "app.add-docs-to-continue": "Ajouter les documents ci-dessous pour continuer l'inscription (Les photocopies et les scans ne sont pas acceptés)",
    "app.vehicles-list": " معلومات و وثائق المركبة    ",
    "app.add-manage-vehicles": "إضافة المعلومات و الوثائق الخاصة بهذه المركبة",
    "app.drivers-list": "الوثائق الشخصية    ",
    "app.add-manage-drivers": "إضافة الوثائق الشخصية",
    "app.bank": "Coordonnées bancaires",
    "app.bank-description": "Ajoutez vos coordonnées bancaires",
    'go.to.home': 'العودة إلى الصفحة الرئيسية',
    'registration.trad': 'للتسجيل',
    'register.on.our.webpage.dza': 'للتسجيل ، يرجى ملء الإستمارة بالمعلومات المطلوبة.',
    'register.on.our.webpage.mar': 'للتسجيل ، يرجى ملء الإستمارة بالمعلومات المطلوبة.',
    'register.on.our.webpage.tun': 'للتسجيل ، يرجى ملء الإستمارة بالمعلومات المطلوبة.',
    'register.on.our.webpage.sen': 'للتسجيل ، يرجى ملء الإستمارة بالمعلومات المطلوبة.',
    'register.on.our.webpage.can': 'للتسجيل ، يرجى ملء الإستمارة بالمعلومات المطلوبة.',
    "registration.status-invalid-docs": "تم رفض بعض مستنداتك ، يرجى إعادة إرسالها باتباع الإرشادات الواردة في تقرير البريد الإلكتروني أو الاتصال بنا للمزيد من المعلومات",
    "registration.status-valid-docs": "م التحقق من صحة المستندات والمعلومات التي قدمتها ، كجزء من تسجيلك لتصبح شريكًا لـ YASSIR ، وسيتم إرسال رسالة نصية قصيرة ورسالة بريد إلكتروني إليك تحتوي على تاريخ ووقت التدريب",
    "registration.status-approved": "سعدنا أن نعلن قبول طلبك لتصبح شريكًا لـ \"YASSIR\" ، ونرحب بكم في مجتمعنا من الشركاء السائقين. يمكنك الآن تسجيل الدخول والبدء في كسب المال ",
    "registration.status-approved-links": " ستجد أدناه رابط تحميل تطبيق YASSIR Chauffeurs:",
    "registration.status-rejected": "نأسف لإبلاغك أنه تم رفض طلبك لتصبح شريكًا ل YASSIR رجى الاتصال بنا لمزيد من المعلومات",
    "registration.status-training-planned": "تمت برمجة تدريبكم ليوم",
    "registration.status-training-planned-center": "العنوان",
    "registration.status-training-absent": "  لم يسجل حضوركم للتدريب المبرمج يوم",
    "registration.status-training-absent-center": "العنوان ",
    "registration.status-rejected-post-approval": " نأسف لإبلاغك أنه تم رفض طلبك لتصبح شريكًا لـ YASSIR بعد تحقق من قبل وكلائنا ",
    "registration.add-company-title": "Informations générales",
    "registration.add-company-sub-title": "الاسم، اللقب، البريد الالكتروني",
    "registration.edit-company-title": "Documents de l'entreprise",
    "registration.edit-company-sub-title": "Nom de l'enterprise, form juridique...",
    "registration.add-company-vehicles-title": "Informations et documents du véhicule",
    "registration.add-company-vehicles-sub-title": "Marque, Modèle...",
    "registration.add-company-driver-title": "Informations et documents personnels",
    "registration.add-company-driver-sub-title": "الاسم، اللقب، البريد الالكتروني...",
    "registration.file-being-processed": "Votre dossier est en cours de traitement",
    "registration.complete-profile": " :أتمم حسابك",
    "registration.add-documents": " :أتمم حسابك",
    "registration.vehicles": "véhicules",
    "registration.drivers": "chauffeurs",
    "registration.finalize-to-submit": "أتمم الاستمارة حتى تتمكن من إرساله    ",
    "registration.contact-us": "يمكنك الاتصال بنا على    ",
    "registration.finalize-registration": "Finaliser l'inscription",
    "registration.contact-email": "support@yassir.com",
    "registration.more-than-5": "Possédez-vous plus de 5 véhicules ?",
    "registration.more-than-5-text": "Notre équipe peut prendre en charge l’enregistrement de votre flotte et de vos chauffeurs si vous possédez plus de 5 véhicules.",
    "registration.i-have-more-than-5": "Je possède une flotte de plus de 5 véhicules",
    "registration.checking-this-end-registration": "En cochant cette option l’inscription est finalisée à cette étape",
    "registration.save-&-finish": "Enregistrer et finir l’inscription",
    "registration.i-add-myself": "J’ajoute mes véhicules moi-même",
    "registration.choice-of-training": "الدورة التدريبية",
    "registration.personal-information-title": "معلومات شخصية    ",
    "registration.personal-information-sub-title": "...الاسم، اللقب، البريد الالكتروني",
    "registration.personal-documents-title": "الوثائق الشخصية    ",
    "registration.personal-documents-sub-title": "الوثائق الشخصية    ",
    "registration.vehicles-information-title": " معلومات المركبة    ",
    "registration.vehicles-information-sub-title": "...العلامة و الصنف",
    "registration.bank": "Informations Bancaires",
    "registration.bank-sub-title": "Informations Bancaires",
    "registration.docs-upload-note": "Lorsque vous téléversez les documents demandés ,soyez sûrs que toutes les informations soient bien visibles. N’utilisez pas de flash. Vous serez notifiés de l’avancement du traitement de votre dossier.",
    "registration.submit-application": "إرسال الطلب",
    "registration.application-submitted-message-1": "نشكرك على الوقت الذي خصصته لملئ الاستمارة",
    "registration.application-submitted-message-2": "!سيتكفل فريقنا بطلبك, وسيتم الاتصال بك في أقرب وقت ممكن .  إلى لقاءٍ قريب     ",
    "registration.training-access-message": "Vous aurez accès à la formation une fois que tous vos documents sont vérifiés et validés.",
    "registration.training": "Formation",
    "registration.training-online-title": "Formation en ligne",
    "registration.training-online-start": "Votre dossier a été approuvé, vous pouvez maintenant commencer votre formation.",
    "registration.training-sub-title": "Passer votre formation en ligne",
    "registration.training-on-line": "Passez la formation et test en ligne",
    "registration.training-on-line-desc": "Dés que votre dossier sera approuvé, vous pourrez commencer votre formation.",
    "registration.training-at-office": "Formation au bureau",
    "registration.training-at-office-desc": "Passer la formation et test au bureau, vous recevrez un mail spécifiant la date et l’heure à laquelle il devra se présenter.",
    'validation.trad': 'تفعيل',
    "validation.required": "Requis",
    "validation.last-name-required": "الرجاء إدخال اللقب    ",
    "validation.first-name-required": "الرجاء إدخال الاسم    ",
    "validation.birth-date": "الرجاء إدخال تاريخ الولادة    ",
    "validation.email-required": "الرجاء إدخال البريد الالكتروني",
    "validation.email-not-valid": "Email n'est pas valide",
    "validation.email-not-match": "Email ne correspond pas",
    "validation.confirm-email": "الرجاء تأكيد البريد الالكتروني",
    "validation.phone-required": "Téléphone est requis",
    "validation.confirm-phone": "Veuillez confirmer le numéro de téléphone",
    "validation.phone-not-match": "Téléphone ne correspond pas",
    "validation.company-name-required": "Le nom de l'entreprise est",
    "validation.id-required": "Veuillez ajouter la pièce d'identité",
    "validation.subtype-required": "Veuillez choisir le type de la pièce d'identité",
    "validation.end-date-required": "يُرجى إضافة تاريخ إنتهاء الصلاحية    ",
    "validation.end-date": " تاريخ إنتهاء الصلاحية    ",
    "validation.vtc-recto-required": "Veuillez ajouter la carte VTC recto",
    "validation.vtc-verso-required": "Veuillez ajouter la carte VTC verso",
    "validation.driver-lic-recto-required": "يُرجى إضافة رخصة السياقة الوجه الأول    ",
    "validation.driver-lic-verso-required": "يُرجى إضافة رخصة السياقة الوجه الثاني    ",
    "validation.legal-form-required": "Form juridique est requis",
    "validation.city-required": "La ville est requis",
    "validation.siret-required": "N° siret est requis",
    "validation.siret-length": "N° siret doit avoir 14 chiffres",
    "validation.iban-required": "IBAN est requis",
    "validation.kbis-insee": "kbis ou insee est requis",
    "validation.subscription-certificate-required": "Certificat d'inscription est requise",
    "validation.rc-pro-required": "RC Pro est requis",
    "validation.rib-required": "يُرجى إضافة صك أو بيان هوية البنك",
    "validation.residency-required": "يُرجى إضافة شهادة الاقامة    ",
    "validation.medical-certificate-required": "يُرجى إضافة شهادة  الأهلية الصحية    ",
    "validation.car-brand": "يُرجى اختيار علامة المركبة    ",
    "validation.car-model": "يُرجى اختيار الصنف    ",
    "validation.car-color": "يُرجى اختيار لون المركبة    ",
    "validation.car-year": "يُرجى اختيار سنة المركبة    ",
    "validation.valid-number": "Veuillez choisir un nombre",
    "validation.is-number": "Veuiller entrer un nombre",
    "validation.car-seats": "يُرجى اختيار عدد المقاعد    ",
    "validation.car-seats-min": "le nombre de sièges doit être au moins 1",
    "validation.car-plate": "يُرجى كتابة ترقيم المركبة    ",
    "validation.wilaya-required": "الرجاء إدخال الولاية / المنطقة    ",
    "validation.daira-required": "الرجاء إدخال الدائرة    ",
    "validation.commune-required": "الرجاء إدخال  البلدية    ",
    "validation.profession-required": " الرجاء تحديد المهنة    ",
    "validation.gender-required": "الرجاء تحديد الجنس",
    "validation.grey-card-required": "يُرجى إضافة البطاقة الرمادية    ",
    "validation.grey-card-front-required": "Veuillez ajouter la carte grise recto",
    "validation.grey-card-back-required": "Veuillez ajouter la carte grise verso",
    "validation.green-card-required": "Veuillez ajouter la carte verte",
    "validation.insurance-required": "يُرجى إضافة شهادة التأمين    ",
    "validation.technical-control-required": "يُرجى إضافة المراقبة التقنية    ",
    "validation.macaron-required": "Veuillez ajouter le macaron",
    "validation.car-picture-required": "يُرجى إضافة صورة المركبة    ",
    "validation.lvc-thumbnail-required": "Veuillez ajouter la vignette LVC",
    "validation.bce": "Veuillez ajouter l'extrait de la BCE (Banque-Carrefour des Entreprises)",
    "validation.medical-visit-required": "Veuillez ajouter la visite médicale",
    "validation.criminal-record-required": "يُرجى إضافة السجل الجنائي    ",
    "validation.driving-record-required": "Veuillez ajouter le dossier de conduite",
    "validation.drivingRecord": "Veuillez ajouter le dossier de conduite",
    "validation.profile-pic-required": "La photo de profil est requise",
    "validation.agglomeration-required": "Veuillez choisir l'agglomération ",
    "validation.licence-type-required": "Veuillez choisir  le type de permis de conduire",
    "validation.kilometers-required": "kilométrage du véhicule est requis",
    "validation.lic-number-required": "Le numéro de permis de conduire est requis",
    "validation.birth-date-required": "الرجاء إدخال تاريخ الولادة     ",
    "validation.address-1-required": "الرجاء إدخال العنوان     ",
    "validation.postal-code-required": "Code postal est requis",
    "validation.fuel-type": "يُرجى اختيار طاقة السيارة    ",
    "validation.mechanical-control": "يُرجى إضافة المراقبة التقنية    ",
    "validation.procuration-required": "يُرجى إضافة التفويض    ",
    "registration.rejected-documents": "يوجد وثائق مرفوضة",
    "registration.accepted-documents": "تم قبول كل الوثائق",
    "registration.pending-documents": "الوثائق تنتظر معالجتها ",
    "general-info.server-error": "Une erreur s'est produite veuillez réessayer",
    "general-info.who-are-you": "Qui êtes-vous ?",
    "general-info.choose-suitable-option": "Veuillez choisir l'option qui vous correspond",
    "general-info.owner": "Gérant",
    "general-info.mandated-employee": "Employé mandaté pour inscrire la société",
    "general-info.mandated-info": "Informations de l’employé mandaté",
    "general-info.gender": "الجنس",
    "general-info.wilaya": "المنطقة / الولاية",
    "general-info.daira": "الدائرة",
    "general-info.commune": "البلدية",
    "general-info.profession": "المهنة",
    "general-info.sponsorship": "Parrainage",
    "general-info.last-name": "اللقب",
    "general-info.first-name": "الاسم",
    "general-info.email": "البريد الالكتروني",
    "general-info.email-confirmation": "تأكيد البريد الالكتروني",
    "general-info.phone-number": "رقم الهاتف",
    "general-info.owner-info": "Informations du gérant",
    "general-info.personal-info": "معلومات شخصية",
    "general-info.city": "Ville",
    "general-info.subscribe": "حفظ",
    "general-info.birth-date": "تاريخ الميلاد",
    "general-info.agglomeration": "Agglomération",
    "general-info.licence-type": "Type de permis",
    "general-info.class5-accepted": "Le permis classe 5 est accepté",
    "general-info.licence-over-1year": "Avez vous le permis depuis plus d’un an?",
    "general-info.yes": "نعم",
    "general-info.no": "لا",
    "general-info.driving-question": "Est ce que vous conduisez actuellement avec une autre plateforme de transport de personnes?",
    "general-info.taxi-question": "êtes vous un Taxi?",
    "general-info.address": "العنوان",
    "general-info.address2": "Ligne d'adresse 2",
    "general-info.postal-code": "Code postal",
    "drivers.add-driver": "Ajouter un chauffeur",
    "drivers.add-drivers-first": "Ajouter des chauffeurs d’abord puis enregistrer",
    "drivers.save-to-continue": "cette opération pour poursuivre.",
    "drivers.modify": "تعديل",
    "drivers.delete": "Supprimer",
    "drivers.driver-info": "Ajouter vos informations personelles",
    "drivers.driver-docs": "Documents relatives à ce chauffeur",
    "drivers.id": "Pièce d'identité",
    "drivers.passport": "Passeport",
    "drivers.residence-permit": "Titre de séjour",
    "drivers.server-error": "Une erreur s'est produite veuillez réessayer",
    "drivers.personal-info": "Informations personnelles",
    "drivers.first-name": "Prénom",
    "drivers.last-name": "Nom",
    "drivers.mail": "Adresse e-mail",
    "drivers.mail-confirmation": "Confirmation de l’adresse e-mail",
    "drivers.phone": "Numéro de téléphone",
    "drivers.phone-confirmation": "Confirmation du numéro de téléphone",
    "drivers.doc-type": "Type de document",
    "drivers.vtc-card": "Carte VTC",
    "drivers.only-accept-new-cards": "Nous n’acceptons que les nouvelles cartes plastifiées (Les récépissés ne sont pas acceptés).",
    "drivers.eu-driver-lic": "رخصة السياقة    ",
    "drivers.new-driver": "الوثائق الشخصية    ",
    "drivers.add-driver-info": "إضافة الوثائق الشخصية",
    "drivers.medical-visit": "Visite médicale",
    "drivers.criminal-record": "سجل جنائي    ",
    "drivers.criminal-record-note": "Une vérification d’antécédents auprès d’un corps de police du Québec est requise pour devenir chauffeur autorisé",
    "drivers.driving-record": "Téléversez le dossier de conduite",
    "drivers.driving-record-note": "Le dossier de conduite est à demander auprès de la SAAQ Le dossier de conduite d'un chauffeur autorisé ne doit pas faire l'objet de plus de trois infractions mineures survenues au cours des trois dernières années et ne doit faire l'objet d'aucune infraction majeure au cours des trois dernières années.",
    "drivers.profile-pic": "Ajoutez votre photo de profil",
    "drivers.profile-pic-note": "Afin d’aider vos membres passagers à vous identifier facilement et afin d’améliorer la sécurité sur l’application, tous les chauffeurs autorisés doivent avoir une photo de profil associée à leur compte.Assurez-vous que vous êtes habillés proprement et de façon professionnelle",
    "drivers.lic-number": "رقم رخصة السياقة",
    "drivers.licence-note": "Il faudra être titulaire d'un permis de conduire de la classe 5 (ou d’une classe supérieure) et avoir une expérience de conduite de 12 mois ou plus (excluant l’expérience de conduite du permis probatoire",
    "drivers.residency": "شهادة الاقامة    ",
    "drivers.medical-certificate": "شهادة الأهلية الصحية    ",
    "drivers.agglomeration": "Agglomération",
    "drivers.licence-type": "Type de permis",
    "drivers.class5-accepted": "Le permis classe 5 est accepté",
    "drivers.licence-over-1year": "Avez vous le permis depuis plus d’un an?",
    "drivers.yes": "Oui",
    "drivers.no": "Non",
    "drivers.driving-question": "Est ce que vous conduisez actuellement avec une autre plateforme de transport de personnes?",
    "drivers.taxi-question": "êtes vous un Taxi?",
    "dropzone.doc-added": "تمت إضافة الوثيقة",
    "dropzone.add-doc": "إضافة الوثيقة    ",
    "dropzone.add-doc-recto": "إضافة الوثيقة الوجه الأول    ",
    "dropzone.add-doc-verso": "إضافة الوثيقة الوجه الثاني    ",
    "dropzone.saving-in-progress": "في انتظار التسجيل    ",
    "dropzone.modify": "تعديل",
    "dropzone.rejected": "وثيقة مرفوضة    ",
    "dropzone.accepted": "تم التحقق من صحة هذا المستند    ",
    "done.thanks-for-submitting": "لقد تم إرسال الإستمارة بنجاح",
    "done.application-under-review": "لقد وصلنا طلب التسجيل الخاص بك، سوف نتواصل معك لتفعيل حسابك عن قريب.",
    'done.download-yassir-driver-app': " تحميل تطبيق يسير سائق",
    "company.server-error": "Une erreur s'est produite veuillez réessayer",
    "company.about": "À propos de l'entreprise",
    "company.name": "Nom de l'entreprise",
    "company.legal-form": "Forme juridique",
    "company.siret": "N° SIRET",
    "company.city": "Ville",
    "company.kbis-insee": "KBIS ou INSEE",
    "company.subscription-certificate": "Certificat d'inscription au Registre de VTC",
    "company.legit-docs-only": "Nous n'acceptons que les documents officiels du ministère.",
    "company.rc-pro": "Assurance responsabilité civile professionnelle (RC Pro)",
    "company.doc-requirement": "Le document doit contenir la mention 'responsabilité civile' en rapport avec l'activité de votre entreprise.",
    "company.rib": "Relevé d'Identité Bancaire (RIB)",
    "company.iban-or-rib": "Veuillez saisir votre IBAN et ajouter une copie de votre RIB.",
    "company.submit": "Enregistrer",
    "company.tva": "N° de TVA",
    "company.bce-certificate": "Extrait de la BCE (Banque-Carrefour des Entreprises)",
    "company.lvc-licence": "Licence LVC",
    "cars.add-vehicle": "Ajouter un véhicule",
    "cars.add-vehicles-first": "Ajouter des véhicules d’abord puis enregistrer",
    "cars.this-operation": "cette opération pour poursuivre.",
    "cars.submit": "حفظ",
    "cars.modify": "Modifier",
    "cars.delete": "Supprimer",
    "cars.general-info": "Informations générales",
    "cars.brand": "العلامة",
    "cars.model": "الصنف",
    "cars.color": "اللون",
    "cars.year": "السنة",
    "cars.seats": "عدد المقاعد    ",
    "cars.plate": "لوحة الترقيم     ",
    "cars.grey-card": "البطاقة الرمادية     ",
    "cars.grey-card-note": "Toutes les informations doivent être clairement visibles",
    "cars.green-card": "Carte verte",
    "cars.serive-available-onboard": "Services disponibles à bord du véhicule",
    "cars.check-available-services": "Veuillez cocher les services disponibles à bord du véhicule",
    "cars.pmr-accessibility": "Accessibilité PMR (Personne à mobilité réduite)",
    "cars.child-seat": "Siège enfant",
    "cars.usb-charger": "Chargeur d’appareil mobile micro-USB",
    "cars.wifi": "WIFI",
    "cars.trp-insurance": "شهادة تأمين  المركبة     ",
    "cars.insurance-note": "Le conducteur doit être le conducteur principal ou secondaire sur l’assurance, assurez-vous que votre nom, le numéro d'identification du véhicule, la compagnie d'assurance et la date d'expiration soient visibles.",
    "cars.technical-control": "المراقبة التقنية     ",
    "cars.macaron": "Macaron",
    "cars.picture": "صورة المركبة ",
    "cars.picture-description": "يرجى التقاط صورة أمامية، مع إظهار لوحة الترقيم    ",
    "cars.picture-example": "انظر الى المثال على الصورة    ",
    "cars.server-error": "Une erreur s'est produite veuillez réessayer",
    "cars.new": "معلومات المركبة",
    "cars.add-vehicle-info": "إضافة المعلومات الخاصة بهذه المركبة",
    "cars.new-doc": "وثائق المركبة",
    "cars.add-vehicle-doc": "إضافة وثائق المركبة",
    "cars.next": "التالي",
    "cars.grey-card-recto": "Carte grise",
    "cars.grey-card-vers": "Carte grise",
    "cars.kilometers": "Kilométrage",
    "lvc-thumbnail": "Vignette LVC",
    "cars.mechanical-control": "المراقبة التقنية     ",
    "cars.mechanical-control-note": "Vous devez faire inspecter votre véhicule par un mécanicien certifié à l’un des garages affiliés à la SAAQ .Les véhicules ayant parcouru moins de 80K Km ou âgés de moins de 4 ans ne nécessitent pas de vérification mécanique",
    "cars.fuel-type": "طاقة السيارة    ",
    "cars.is-owner": "هل انت صاحب السيارة؟    ",
    "cars.procuration": "تفويض    ",
    "cars.procuration-note": "'يجب أن يحتوي التفويض على عبارة  'إستعمال و إستغلال المركبة",
    'congratulations.trad': 'تهانينا',
    'cheer.you.are.now.a.vtc.yassir.partner.driver.dza': 'تهانينا ، أنت الأن سائق شريك مع يسير. يمكنكم تفعيل التطبيق و القيام برحلات لبداية تحقيق الأرباح.',
    'cheer.you.are.now.a.vtc.yassir.partner.driver.mar': 'تهانينا ، أنت الأن سائق شريك مع يسير. يمكنكم تفعيل التطبيق و القيام برحلات لبداية تحقيق الأرباح.',
    'cheer.you.are.now.a.vtc.yassir.partner.driver.tun': 'تهانينا ، أنت الأن سائق شريك مع يسير. يمكنكم تفعيل التطبيق و القيام برحلات لبداية تحقيق الأرباح.',
    'cheer.you.are.now.a.vtc.yassir.partner.driver.sen': 'تهانينا ، أنت الأن سائق شريك مع يسير. يمكنكم تفعيل التطبيق و القيام برحلات لبداية تحقيق الأرباح.',
    'cheer.you.are.now.a.vtc.yassir.partner.driver.can': 'تهانينا ، أنت الأن سائق شريك مع يسير. يمكنكم تفعيل التطبيق و القيام برحلات لبداية تحقيق الأرباح.',
    "bank-details.financial-institute": "Institution financière",
    "bank-details.financial-institute-number": "Numéro de l'institution financière",
    "bank-details.transit-number": "Numéro de transit",
    "bank-details.bank-account-number": "Numéro de compte bancaire",
    "bank-details.tps-doc": "TPS",
    "bank-details.tps-number": "Numéro TPS",
    "bank-details.tvq-doc": "TVQ",
    "bank-details.tvq-number": "Numéro TVQ",
    "bank-details.subscribe": "Enregistrer",
    'find.answers.to.your.questions': 'اعثر على إجابات لأسئلتك',
    'Hafid.Z.can': 'حفيظ',
    'Hafid.Z.dza': 'حفيظ',
    'Hafid.Z.mar': 'حفيظ',
    'Hafid.Z.tun': 'حفيظ',
    'Hafid.Z.sen': 'Mamadou D',
    'how.long.will.the.training.take': 'ما هي مدة الدورة التدريبية ؟',
    'not.supported.title': 'اتصل بفريق الدعم',
    'not.supported.body': 'للمزيد من المعلومات بخصوص طلب التسجيل الخاص بك ، يرجى الاتصال بفريق الدعم يسير.',
    'now.you.can.activate.dza': 'يمكنك الآن تنشيط تطبيقك والتسوق وكسب المال.',
    'now.you.can.activate.mar': 'يمكنك الآن تنشيط تطبيقك والتسوق وكسب المال.',
    'now.you.can.activate.tun': 'يمكنك الآن تنشيط تطبيقك والتسوق وكسب المال.',
    'now.you.can.activate.sen': 'يمكنك الآن تنشيط تطبيقك والتسوق وكسب المال.',
    'now.you.can.activate.can': 'يمكنك الآن تنشيط تطبيقك والتسوق وكسب المال.',
    'ready.for.a.new.adventure': 'جاهز لمغامرة جديدة؟',
    'submit.your.application.to.become.a.yassir.partner.driver.today.dza': 'قم بتقديم طلبك لتصبح سائق شريك يسير اليوم.',
    'submit.your.application.to.become.a.yassir.partner.driver.today.mar': 'قم بتقديم طلبك لتصبح سائق شريك يسير اليوم.',
    'submit.your.application.to.become.a.yassir.partner.driver.today.tun': 'قم بتقديم طلبك لتصبح سائق شريك يسير اليوم.',
    'submit.your.application.to.become.a.yassir.partner.driver.today.sen': 'قم بتقديم طلبك لتصبح سائق شريك يسير اليوم.',
    'submit.your.application.to.become.a.yassir.partner.driver.today.can': 'قم بتقديم طلبك لتصبح سائق شريك يسير اليوم.',
    "training.start": "Commencer la formation",
    "training.content-title": "Contenu",
    "training.next-course": "Prochain Cours",
    "training.previous-course": "Cours Précedent",
    "training.validate-answer": "Valider ma réponse",
    'to.become.a.partner.driver': 'لكي تصبح وسيطا  "سائق" ، يجب أن تستوفي الشروط التالية <span>:</span>',
    'to.have.a.car': 'لديك سيارة',
    'title.document': 'انضم لفريق يسير',
    "drivers.rib": "صك أو بيان هوية البنك",
    "drivers.rib-number": "رقم صك أو بيان هوية البنك",
    "validation.birth-certificate-required": "شهادة ميلاد    ",
    "drivers.birth-certificate": " يُرجى إضافة شهادة ميلاد ",
    'the.training.will.last.approximately.half.a.day.dza': 'تدوم الفترة التدريبية حوالي نصف يوم. الهدف منها معرفة كيفية استعمال تطبيق يسير للسائقين إضافة إلى الإجابة عن كل استفساراتكم بخصوص خدمة يسير',
    'the.training.will.last.approximately.half.a.day.mar': 'تدوم الفترة التدريبية حوالي نصف يوم. الهدف منها معرفة كيفية استعمال تطبيق يسير للسائقين إضافة إلى الإجابة عن كل استفساراتكم بخصوص خدمة يسير',
    'the.training.will.last.approximately.half.a.day.tun': 'تدوم الفترة التدريبية حوالي نصف يوم. الهدف منها معرفة كيفية استعمال تطبيق يسير للسائقين إضافة إلى الإجابة عن كل استفساراتكم بخصوص خدمة يسير',
    'the.training.will.last.approximately.half.a.day.sen': 'تدوم الفترة التدريبية حوالي نصف يوم. الهدف منها معرفة كيفية استعمال تطبيق يسير للسائقين إضافة إلى الإجابة عن كل استفساراتكم بخصوص خدمة يسير',
    'the.training.will.last.approximately.half.a.day.can': 'تدوم الفترة التدريبية حوالي نصف يوم. الهدف منها معرفة كيفية استعمال تطبيق يسير للسائقين إضافة إلى الإجابة عن كل استفساراتكم بخصوص خدمة يسير',
    'the.day.you.go.to.the.yassir.headquarters.dza': 'من المهم إحضار سيارتكم للمقر عند التقدم للموعد ، ليتم فحصها والتحقق من سلامتها من قبل المصلحة التقنية.',
    'the.day.you.go.to.the.yassir.headquarters.mar': 'من المهم إحضار سيارتكم للمقر عند التقدم للموعد ، ليتم فحصها والتحقق من سلامتها من قبل المصلحة التقنية.',
    'the.day.you.go.to.the.yassir.headquarters.tun': 'من المهم إحضار سيارتكم للمقر عند التقدم للموعد ، ليتم فحصها والتحقق من سلامتها من قبل المصلحة التقنية.',
    'the.day.you.go.to.the.yassir.headquarters.sen': 'من المهم إحضار سيارتكم للمقر عند التقدم للموعد ، ليتم فحصها والتحقق من سلامتها من قبل المصلحة التقنية.',
    'the.day.you.go.to.the.yassir.headquarters.can': 'من المهم إحضار سيارتكم للمقر عند التقدم للموعد ، ليتم فحصها والتحقق من سلامتها من قبل المصلحة التقنية.',
    'two.payment.methods.are.available': 'يسير توفر طريقتين للدفع : الدفع نقدا وهي طريقة مستعملة حتى الآن. الدفع عن طريق البطاقة البنكية. كما تم تفعيل عملية الدفع الإلكتروني(TPE) لبعض الشركاء.',
    'une.fois.selectionne.vous.serez.contacte.dza': 'بعد قبولك ، سيتم الإتصال بك لإجراء دورة تدريبية في مقرنا ',
    'une.fois.selectionne.vous.serez.contacte.mar': 'بعد قبولك ، سيتم الإتصال بك لإجراء دورة تدريبية في مقرنا ',
    'une.fois.selectionne.vous.serez.contacte.tun': 'بعد قبولك ، سيتم الإتصال بك لإجراء دورة تدريبية في مقرنا ',
    'une.fois.selectionne.vous.serez.contacte.sen': 'بعد قبولك ، سيتم الإتصال بك لإجراء دورة تدريبية في مقرنا ',
    'une.fois.selectionne.vous.serez.contacte.can': 'بعد قبولك ، سيتم الإتصال بك لإجراء دورة تدريبية في مقرنا ',
    'valide.ext': 'يجب أن يكون الامتداد: png أو jpg أو pdf',
    'what.our.drivers.say.about.us.dza': 'آراء السائقين .',
    'what.our.drivers.say.about.us.mar': 'آراء السائقين .',
    'what.our.drivers.say.about.us.tun': 'آراء السائقين .',
    'what.our.drivers.say.about.us.sen': 'آراء السائقين .',
    'what.our.drivers.say.about.us.can': 'آراء السائقين .',
    'what.are.the.high.demand.places.dza': 'ما هي الأماكن التي يكثر فيها الطلب ؟',
    'what.are.the.high.demand.places.mar': 'ما هي الأماكن التي يكثر فيها الطلب ؟',
    'what.are.the.high.demand.places.tun': 'ما هي الأماكن التي يكثر فيها الطلب ؟',
    'what.are.the.high.demand.places.sen': 'ما هي الأماكن التي يكثر فيها الطلب ؟',
    'what.are.the.high.demand.places.can': 'ما هي الأماكن التي يكثر فيها الطلب ؟',
    'where.there.are.yassir.customers.dza': 'أينما تواجد عملاء يسير، يكثر الطلب.الأمر يعود إلى رضى العميل و جودة الخدمة التي تقدمها.',
    'where.there.are.yassir.customers.mar': 'أينما تواجد عملاء يسير، يكثر الطلب.الأمر يعود إلى رضى العميل و جودة الخدمة التي تقدمها.',
    'where.there.are.yassir.customers.tun': 'أينما تواجد عملاء يسير، يكثر الطلب.الأمر يعود إلى رضى العميل و جودة الخدمة التي تقدمها.',
    'where.there.are.yassir.customers.sen': 'أينما تواجد عملاء يسير، يكثر الطلب.الأمر يعود إلى رضى العميل و جودة الخدمة التي تقدمها.',
    'where.there.are.yassir.customers.can': 'أينما تواجد عملاء يسير، يكثر الطلب.الأمر يعود إلى رضى العميل و جودة الخدمة التي تقدمها.',
    'what.time.of.day.can.i.work': 'ما هي أوقات العمل في اليوم ؟',
    'what.is.the.file.to.provide': 'ما هي الشروط الواجب توافرها ؟',
    'what.is.the.payment.method': 'ما هي طريقة الدفع ؟',
    'your.file.is.sent.to.our.teams': 'تم إرسال معلوماتك بنجاح.ستتم دراسة ملفك من قبل فريقنا',
    'yes.you.can.take.inter.wilaya.races': 'نعم ،يمكنكم القيام برحلات خارج الولاية',
    'you.can.reach.us': 'يمكنكم الإتصال بمصلحة  خدمة الزبائن 24/7 على الرقم 021999999 كما يمكنكم التواصل معنا عبر صفحاتنا على مواقع التواصل الإجتماعي.',
    'Zohir.H.can': 'زهير',
    'Zohir.H.dza': 'زهير',
    'Zohir.H.mar': 'زهير',
    'Zohir.H.tun': 'زهير',
    'Zohir.H.sen': 'Seydou S',
    'cancel.text': 'إلغاء',
    'apply.text': 'موافق',
    "exceed.25.characters": "يجب ألا يتجاوز الاسم و اللقب 25 حرفًا",
    "min.length.names": "يجب أن يحتوي الاسم الأول والأخير على أكثر من حرفين",
}
export default ar;