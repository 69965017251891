/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Link } from '@material-ui/core';
import React from 'react';
import PDFIcon from '../../assets/pdf.svg';

const PDFPreview = ({ ...props }) => (
  //   const classes = useStyles();
  <Link target='_blank' href={props.url} style={{ zIndex: 99 }}>
    <img
      src={PDFIcon}
      alt='pdf'
      // className={classes.}
      style={{ width: '100%', height: '100%' }}
    />
  </Link>
);
export default PDFPreview;
