import _ from 'lodash';

/**
   * Find difference between two objects
   * @param  {object} previousObject - Source object to compare newObject against
   * @param  {object} newObject  - New object with potential changes
   * @return {object} differences
   */
export function difference(previousObject: Record<string, any>, newObject: Record<string, any>): Record<string, any> {
    function changes(newObj: any, origObj: any): any {
        let arrayIndexCounter = 0;
        return _.transform(newObj, (result, value, key) => {
            if (
                key === "ext"
                || key === "type"
                || key === "subType"
            ) {
                result[key] = value;
            } else if (
                !_.isEqual(value, origObj[key])
            ) {
                
                const resultKey = _.isArray(origObj) ? arrayIndexCounter += 1 : key;
                result[resultKey] =
                    _.isObject(value) && _.isObject(origObj[key])
                        ? changes(value, origObj[key])
                        : value;
            }
        });
    }
    return changes(newObject, previousObject);
}