import React, { ReactElement } from 'react';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import Typography from '@material-ui/core/Typography';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { ButtonBase } from '@material-ui/core';
import useStyles from './styles';
import { useConfigData } from '../../contexts/configContext';
import { IntlContext, useIntlData } from '../../contexts/intlContext';

interface OptionalProps {
  subtitle?: string | React.ReactNode;
  icon?: string;
  isMultiSteps: boolean;
  activeStep?: number;
  handleBack?: () => void;
}
interface BreadProps extends OptionalProps {
  title: string | React.ReactNode;
}

const Breadcrumb = ({
  title,
  subtitle,
  icon,
  isMultiSteps,
  handleBack,
  activeStep,
}: BreadProps): ReactElement => {
  console.log('🚀 ~ handleBack', handleBack);
  const classes = useStyles();
  const { pathname } = useLocation();
  const { locale } = React.useContext(IntlContext).locales;
  const { getIcon } = useConfigData();
  const stepIcon = getIcon(icon)

  const { isRtl } = useIntlData();
  
  let previousButton = <></>;
  if (isMultiSteps && activeStep && activeStep !== 0) {
    previousButton = (
      <ButtonBase onClick={handleBack} className={classes.previousBtn}>
        {isRtl ? <FaArrowRight /> : <FaArrowLeft />}
      </ButtonBase>
    );
  } 
  else if (pathname !== `/${locale}/register`) {
    previousButton = (
      <Link to={`/${locale}/register`} className={classes.previousBtn}>
        {isRtl ? <FaArrowRight /> : <FaArrowLeft />}
      </Link>
    );
  }
  
  return (
    <div className={classes.container} style={{padding: window.innerWidth < 500 ? '24px 24px 24px 24px' : '24px 0 72px 0'}}>
      {previousButton}
      {icon ? (
        <div className={classes.icon}>
          {/* <CustomIcon iconName={icon} width={50} /> */}
          <img src={stepIcon} alt='ICO' width={50}/>
        </div>
      ) : (
        ''
      )}
      <DirectionProvider direction={locale !== 'ar' ? DIRECTIONS.LTR : DIRECTIONS.RTL} lang={locale}>
        <Typography className={classes.title}>{title}</Typography>
      </DirectionProvider>
      <Typography className={classes.subtitle} color='secondary'>
        {subtitle || ''}
      </Typography>
    </div>
  );
};

const defaultProps = {
  subtitle: undefined,
  icon: undefined,
  activeStep: undefined,
  handleBack: () => {
    console.log('Helloooo world');
  },
};

Breadcrumb.defaultProps = defaultProps;

export default Breadcrumb;
